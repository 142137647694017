import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { Button, Layout } from "antd";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { onMobileNavToggle, toggleCollapsedNav } from "redux/actions/Theme";
import utils from "utils";
import InboxLogo from "./InboxLogo";
import Logo from "./Logo";
import MenuContent from "./MenuContent";
import MobileLogo from "./MobileLogo.js";
import NavProfile from "./NavProfile";
import NavSearch from "./NavSearch";
import "./NavSearch/nav.css";
import SubscriptionMobileDesign from "./SubscriptionMobileDesign";
import Subscription from "./Subscription";
import "./css/Popus.css";
import useAnalyticsEventTracker from "useAnalyticsEventTracker";

const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    direction,
    topNavColor,
    sideBarShow,
  } = props;
  const [searchActive, setSearchActive] = useState(false);

  const history = useHistory();

  const mobileView = window.innerWidth < 1200;
  const tabView = window.innerWidth < 961;

  const storedValue = localStorage.getItem("isActiveSubscription");

  const isActiveSubscription = storedValue === "true";

  const onSearchActive = () => {
    setSearchActive(true);
  };

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };
  const gaEventTracker = useAnalyticsEventTracker("NavBar");

  const userInfoString = localStorage.getItem("userInfo");

  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      // console.error("Error parsing userInfo:", err);
    }
  }
  const fullName = userInfo.firstName?.toUpperCase() + " " + userInfo.lastName;

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "black" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  const gotoDashboard = () => {
    history.push("/auth/dashboard");
    // window.location.reload();
  };

  const [isMobileOpen, setMobileIsOpen] = useState(false);

  const toggleMobilePopup = () => {
    setMobileIsOpen(!isMobileOpen);
  };
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    if (isActiveSubscription) {
      history.push("/auth/dashboard/plan");
    } else {
      // setIsOpen(!isOpen);
      if (tabView) {
        setIsOpen(!isOpen);
      } else {
        history.push("/auth/dashboard/subscription-plan");
      }
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });

  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {(isNavTop && !isMobile) || sideBarShow ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
              {/* {
                // isMobile ?
                <li className="ant-menu-item ant-menu-item-only-child" onClick={() => {onSearchActive()}}>
                  <SearchOutlined />
                </li>
                // :
                // <li className="ant-menu-item ant-menu-item-only-child" style={{cursor: 'auto'}}>
                //   <SearchInput mode={mode} isMobile={isMobile} />
                // </li>
              } */}
            </ul>

            {isNavTop &&
              !isMobile(
                <MenuContent
                  type={NAV_TYPE_TOP}
                  topNavColor={topNavColor}
                  localization={true}
                />
              )}
            <MobileLogo />
          </div>
          <div className="nav-right">
            {/*
              <div
                className="ant-menu-item ant-menu-item-only-child"
                onClick={() => {
                  onSearchActive();
                }}
              >
                <SearchOutlined />
              </div>
            */}
            {history.location.pathname === "/auth/profile" && (
              <Button
                type="primary"
                onClick={gotoDashboard}
                style={{
                  background: "#F1F6FE",
                  borderRadius: "6px",
                  borderColor: "#F1F6FE",
                  height: "32px",
                  paddingTop: "5px",
                  alignSelf: "center",
                  width: "150px",
                }}
              >
                <span style={{ color: "#1778F2" }}>Go to Dashboard </span>
              </Button>
            )}

            <Button
              type="primary"
              onClick={() => {
                let label = {
                  buttonTitle: isActiveSubscription
                    ? "View Subscription Plan"
                    : "Get Full Access",
                };
                gaEventTracker(
                  "Subscription Button clicked ",
                  JSON.stringify(label)
                );

                togglePopup();
              }}
              style={{
                background: "rgb(237, 188, 1)",
                borderRadius: "8px",
                border: "none",
                alignSelf: "center",
                width: "auto",
                fontWeight: "600",
                margin: window.innerWidth < 550 ? "0 8px 0 0" : "0px 20px",
                height: "40px",
                fontSize: window.innerWidth < 550 ? "10px" : "15px",
              }}
            >
              <img
                src="/new/crown.png"
                alt=""
                style={{
                  width: window.innerWidth < 550 ? "14px" : "24px",
                  marginRight: window.innerWidth < 550 ? "8px" : "12px",
                }}
              />
              {/* <span style={{ color: "black" }}>Get Full Access</span> */}
              <span
                style={{ color: "black", fontSize: "14px", fontWeight: "500" }}
              >
                {isActiveSubscription === null
                  ? "Get Full Access"
                  : isActiveSubscription
                  ? "View Subscription Plan"
                  : "Get Full Access"}
              </span>
            </Button>
            {isOpen &&
              (tabView ? (
                <div
                  className="popup-overlay"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      color: "#FFF",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "24px",
                      padding: "0px 20px",
                    }}
                  >
                    Manav get a membership for free 7 days to activate automatic
                    application to your 234 scholarship matches. Lets us do the
                    hard work for you !
                  </p>

                  <SubscriptionMobileDesign
                    toggleMobilePopup={togglePopup}
                    redirect={true}
                    noThanksDisplay={false}
                  />
                </div>
              ) : (
                <div className="popup-overlay">
                  <div className="popup-content">
                    <div
                      onClick={togglePopup}
                      style={{
                        color: "rgb(113, 122, 245)",
                        fontSize: "24px",
                        cursor: "pointer",
                        float: "right",
                        lineHeight: "normal",
                      }}
                    >
                      {" "}
                      <CloseCircleFilled />
                    </div>
                    <Subscription
                      modalStyle={{
                        border: "none",
                        boxShadow: "none",
                        margin: "0px",
                      }}
                      redirect={true}
                    />
                  </div>
                </div>
              ))}
            <InboxLogo history={history} />
            {/*<NavNotification />*/}
            <span
              style={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "17px",
                letterSpacing: "0em",
                alignSelf: "center",
              }}
            >
              {/*!mobileView && fullName*/}
            </span>
            {/* <NavLanguage /> */}
            {/* <NavProfile /> */}
            {!mobileView && <NavProfile />}
            {/* <NavPanel direction={direction} /> */}
          </div>
          {/* <NavSearch active={searchActive} close={onSearchClose} /> */}
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme }) => {
  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
    topNavColor,
  } = theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
