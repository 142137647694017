import { message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Form } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import BouncingDotsLoader from "components/layout-components/BouncingDotsLoader";
import ChatStructure from "components/layout-components/ChatStructure";
import { useAssitanceChatBotContext } from "context/ChatbotContext";
import { API_CHAT_URL } from "configs/AppConfig";
// import './AssitanceChatBot.css'
const AssistanceChatBot = ({ dashbordHide = false }) => {
  const {
    chatType,
    messages,
    setMessages,
    render,
    setRender,
    setStartChat,
    hideIcons,
    setHideIcons,
    enableChat,
    setEnableChat,
    setMaximizeView,
    maximizeView,
  } = useAssitanceChatBotContext();

  const inputRef = useRef(null);

  const [chatBtn, setChatBtn] = useState(false);
  // const [enableChat, setEnableChat] = useState(false);
  // const [hideIcons, setHideIcons] = useState(false);
  const [applyScholarshipId, setApplyScholarshipId] = useState("");
  const [scholarshipTryBtn, setScholarshipTryBtn] = useState(false);
  const [first, setFirst] = useState(true);
  const [mobileView, setMobileView] = useState(window.innerWidth < 915);
  const messagesEndRef = useRef(null);
  const userInfo = localStorage.getItem("userInfo");
  const userData = JSON.parse(userInfo);
  const token = localStorage.getItem("token");
  const history = useHistory();
  const [profileUpdate, setProfileUpdate] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);

  const toggleMaximize = () => {
    setMaximizeView(!maximizeView);
    setIsMaximized(!isMaximized);
  };
  useEffect(() => {
    // console.log(chatType)
    if (render) {
      if (chatType == "recommedation") {
        applyScholarship(
          "recommendation",
          "Hi, can you please suggest some recommended scholarships for me? "
        );
        setRender(false);
      }
      if (chatType == "applyScholarship") {
        // writeEssay();
        applyScholarship(
          "easy_apply",
          "Hi, can you please suggest some easy apply scholarships for me? "
        );
        setRender(false);
      }
    }
  }, [chatType]);

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth < 915);
    };
    window.addEventListener("resize", handleResize);

    if (messages.length > 0) {
      setHideIcons(true);
      setEnableChat(true);
    }
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [messages]);

  const boxStyle = {
    border: "2px solid rgb(141, 150, 255)",
    borderRadius: "10px",
    width: "100%",
    alignItems: "center",
    flexShrink: "0",
    flexBasis: "25%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    padding: "5px",
    textAlign: "center",
    background: "transparent",
  };

  //chat structure function to show chat in chatbot
  const chatStructure = (text, sender, extra) => {
    if (text == "loading") {
      return <BouncingDotsLoader />;
    } else if (text?.[0]?.chat_type == "scholarshipList") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ChatStructure
            type="scholarshipList"
            scholarshipData={text[0]?.data}
            dashbordHide
          />
          <div>
            <div
              style={{
                display: scholarshipTryBtn
                  ? "none"
                  : text[0]?.data.length === 0
                  ? "none"
                  : "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  width: "auto",
                  background: "transparent",
                  border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={() => {
                  applyScholarship(
                    "easy_apply",
                    "Show me some easy apply scholarships based on my profile "
                  );
                }}
              >
                Easy Apply
              </button>
              <button
                style={{
                  width: "auto",
                  background: "transparent",
                  border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={async () => {
                  applyScholarship(
                    "essay_only",
                    "Suggest me some  scholarship with apply with cv only. "
                  );
                }}
              >
                Apply Scholarships with CV Only
              </button>
            </div>
          </div>
          <div
            style={{
              display: text[0]?.data.length === 0 ? "block" : "none",
            }}
          >
            <div
              style={{
                backgroundColor:
                  sender === "receiver"
                    ? text === "loading"
                      ? "transparent"
                      : "white"
                    : "#D3D6FF",
                padding: "10px",
                fontSize: mobileView ? "12px" : "12px",
                fontWeight: "500",
                maxWidth: sender === "receiver" ? "90%" : "90%",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                wordWrap: "break-word",
                borderRadius: "8px",
              }}
            >
              Oops!! No Scholarship Found. Select from the other options.
              {setChatBtn(true)}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  width: "auto",
                  background: "transparent",
                  border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={() => {
                  applyScholarship(
                    "easy_apply",
                    "Show me some easy apply scholarships based on my profile "
                  );
                }}
              >
                Easy Apply
              </button>
              <button
                style={{
                  width: "auto",
                  background: "transparent",
                  border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={async () => {
                  applyScholarship(
                    "recommendation",
                    "recommendeded scholarships "
                  );
                }}
              >
                Best Recommended Scholarships
              </button>
            </div>
          </div>
        </div>
      );
    } else if (text?.[0]?.chat_type == "userDetails") {
      return (
        <div
          style={{
            maxWidth: "97%",
          }}
        >
          <ChatStructure
            type="userDetails"
            dashbordHide
            setChatBtn={setChatBtn}
            applyScholarship={applyScholarship}
            chatThreeDotLoading
            setProfileUpdate={setProfileUpdate}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            backgroundColor:
              sender === "receiver"
                ? text === "loading"
                  ? "transparent"
                  : "white"
                : "#D3D6FF",
            padding: "10px",
            fontSize: mobileView ? "12px" : "12px",
            fontWeight: "500",
            maxWidth: sender === "receiver" ? "90%" : "90%",
            whiteSpace: "normal",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            borderRadius: "8px",
          }}
        >
          {text}
        </div>
      );
    }
  };

  //message component to render messages
  const Message = ({ sender, text, extra }) => (
    <div
      className={`message ${
        sender === "receiver" ? "message-left" : "message-right"
      }`}
      // ref={messagesEndRef}
      style={{
        width: "100%",
        justifyContent: sender === "receiver" ? "normal" : "flex-end",
      }}
      ref={messagesEndRef}
    >
      {false ? (
        <BouncingDotsLoader />
      ) : (
        <>{chatStructure(text, sender, extra)}</>
      )}
    </div>
  );
  //general function to append message in the messages array
  const updateMsg = (senderType, text) => {
    const updatedMessages = [...messages, { sender: senderType, text: text }];
    setMessages(() => {
      return updatedMessages;
    });

    return updatedMessages;
  };

  const chatThreeDotLoading = async (skipCall, apiCall, msg) => {
    // Display the loading message
    const loadingMessage = {
      sender: "receiver",
      text: "loading",
    };
    setMessages((prevMessages) => [...prevMessages, loadingMessage]);

    try {
      // Wait for the API call to complete
      let responseText;
      if (skipCall) {
        responseText = msg;
      } else {
        responseText = await apiCall();
      }
      // setTimeout(() => {
      // Update the message with the API response
      setMessages((prevMessages) => {
        // Remove the loading message
        const newMessages = prevMessages.filter((e) => e.text !== "loading");
        // Add the new message with the API response
        return [...newMessages, { sender: "receiver", text: responseText }];
      });
      // }, 500);

      setChatBtn(false);
    } catch (error) {
      // Handle any errors
      console.error("API call failed:", error);

      // Update the message with an error message
      setMessages((prevMessages) => {
        // Remove the loading message
        const newMessages = prevMessages.filter((e) => e.text !== "loading");
        // Add an error message
        return [
          ...newMessages,
          { sender: "receiver", text: "Error loading response" },
        ];
      });

      setChatBtn(false);
    }
  };

  const askUserDetails = async () => {
    const newText = {
      sender: "receiver",
      text: [
        {
          chat_type: "userDetails",
          data: "Nothing",
        },
      ],
    };
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];

      return [...newMessages, newText];
    });
  };

  const applyScholarship = async (type, qus) => {
    const userInfo = localStorage.getItem("userInfo");
    const userData = JSON.parse(userInfo);

    const userBody = {
      userID: userData.id,
      questionAnswer: {
        scholarship_type: type,
        question: qus,
      },
    };
    setHideIcons(true);
    setEnableChat(true);
    updateMsg("user", qus);

    if (!userData?.isProfileUpdate) {
      await chatThreeDotLoading(
        true,
        null,
        `Hi ${userData?.firstName}, to get your personalised scholarships, you need to complete your signup details first.`
      );
      setChatBtn(true);

      await askUserDetails();
      return;
    }

    await chatThreeDotLoading(
      true,
      null,
      `Hi ${userData?.firstName}, here are some scholarships according to your interest.`
    );

    first
      ? await chatThreeDotLoading(
          false,
          () => initiateSholarshipApplyId(userBody),
          ""
        )
      : await chatThreeDotLoading(
          false,
          () => scholarshipChatFlow(applyScholarshipId, userBody),
          ""
        );
    // first
    //   ? await initiateSholarshipApplyId(userBody)
    //   : await scholarshipChatFlow(applyScholarshipId, userBody);
  };
  const writeEssay = () => {
    setHideIcons(true);
    setEnableChat(true);
    setMessages(() => {
      return [];
    });
    updateMsg("user", "Write Essay");
    message.error("This feature not available yet!!");
  };
  if (profileUpdate) {
    applyScholarship(
      "recommendation",
      "Hi, can you please suggest some scholarships for me? "
    );
    setProfileUpdate(false);
  }
  const initiateSholarshipApplyId = async (bodyData) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${API_CHAT_URL}api/scholarships/chats`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (response.status === 201) {
        setFirst(false);
        setApplyScholarshipId(data?.chat_id);

        const text = [
          {
            chat_type: "scholarshipList",
            data: data?.data,
          },
        ];

        // await chatThreeDotLoading(true, null, text);
        return text;
      }
    } catch (error) {
      console.log(
        "error while fetching scholarship list in lenny's assistace",
        error
      );
      message.error("Some Error Occured!!!");
      clearChat();
      return "Some error occured!!!";
    }
  };

  const scholarshipChatFlow = async (chatId, bodyData) => {
    try {
      const response = await fetch(
        `${API_CHAT_URL}api/scholarships/chats?id=${chatId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (response.status === 201) {
        setApplyScholarshipId(data?.chat_id);

        const text = [
          {
            chat_type: "scholarshipList",
            data: data?.data,
          },
        ];
        // await chatThreeDotLoading(true, null, text);
        return text;
      }
    } catch (error) {
      console.log(
        "error while fetching scholarship list in lenny's assistace",
        error
      );
      message.error("Some Error Occured!!!");
      return "Some error occured";
    }
  };
  const clearChat = () => {
    setMaximizeView(false);
    setStartChat(false);
    setHideIcons(false);
    setEnableChat(false);
    setMessages([]);
    setRender(true);
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(240, 241, 255)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderTopLeftRadius: "17px",
        borderTopRightRadius: "17px",
        border: "2px solid rgb(141, 150, 255)",
        borderBottom: "0",
        // width: "97%",
        width: "-webkit-fill-available",
        height: mobileView ? "auto" : "97%",
        margin: mobileView ? "12px auto" : "auto",
        position: mobileView ? "static" : "absolute",
        bottom: "0",
        alignItems: "stretch",
        paddingBottom: "20px",
      }}
      className={`chatbot-container ${isMaximized ? "maximized" : "minimized"}`}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: "10px 20px",
          borderBottom: "2px solid rgb(141, 150, 255)",
        }}
      >
        <h3
          style={{
            margin: "0",
            fontFamily: "Poppins",
          }}
        >
          Lenny's AI
        </h3>

        <div>
          <DeleteOutlined
            style={{
              cursor: "pointer",
              fontSize: "20px",
              // display: history.location.pathname.includes("assistanceChat")
              //   ? "inline"
              //   : "none",
            }}
            onClick={clearChat}
          />
          <img
            src="/new/arr.png"
            style={{
              width: "20px",
              marginLeft: "20px",
              cursor: "pointer",
              display: history.location.pathname.includes("assistanceChat")
                ? "none"
                : "inline",
            }}
            alt="icon_image"
            title="Maximize"
            onClick={() => {
              // if (history.location.pathname.includes("assistanceChat")) {
              //   history.push("/auth/dashboard");
              // } else if (history.location.pathname.includes("dashboard")) {
              //   history.push("/auth/dashboard/assistanceChat");
              // } else {
              //   history.push("/auth/dashboard/assistanceChat");
              // }
              toggleMaximize();
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            display: hideIcons ? "none" : "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: mobileView ? "90%" : "50%",
            margin: "auto",
            height: "40%",
          }}
        >
          <h1
            style={{
              fontFamily: "Poppins",
              textAlign: "center",
            }}
          >
            Welcome to Lenny's Assistant
          </h1>
          <p
            style={{
              textAlign: "center",
              color: "black",
              fontFamily: "Poppins",
              fontWeight: "500",
            }}
          >
            I'm Lenny, your friendly AI assistant here to help. Whether you have
            questions, need assistance, or just want to chat, I'm here
            24/7.Let's get started - feel free to ask me anything!
          </p>
        </div>
        <div
          style={{
            height: "50%",
            display: hideIcons ? "none" : "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              width: mobileView ? "50%" : "25%",
              margin: mobileView ? "auto" : "0 75px",
            }}
          >
            <img
              src="/new/chatrobo1.png"
              alt=""
              style={{
                width: "100%",
              }}
            />
          </div>
          <div
            style={{
              display: hideIcons ? "none" : "block",
              width: mobileView ? "95%" : "60%",
              margin: "auto",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: mobileView
                  ? "auto auto"
                  : "auto auto auto",
                gap: "10px",
              }}
            >
              <div>
                <button
                  onClick={() => {
                    applyScholarship(
                      "recommendation",
                      "Show me some recommended scholarships bases on my profile."
                    );
                  }}
                  style={{
                    ...boxStyle,
                    color: "black",
                    margin: "0",
                  }}
                >
                  Best Recommended Scholarships
                </button>
              </div>
              <div>
                <button
                  onClick={writeEssay}
                  style={{
                    ...boxStyle,
                    color: "black",
                    margin: "0",
                  }}
                >
                  Essay writing
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    applyScholarship(
                      "easy_apply",
                      "Show me some easy apply scholarships bases on my profile "
                    );
                  }}
                  style={{
                    ...boxStyle,
                    color: "black",
                    margin: "0",
                  }}
                >
                  Easy Apply
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    message.error("This feature not available yet.");
                  }}
                  style={{
                    ...boxStyle,
                    color: "black",
                    margin: "0",
                  }}
                >
                  {" "}
                  Applying for scholarship
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    applyScholarship(
                      "essay_only",
                      "Suggest me some  scholarship with apply with cv only. "
                    );
                  }}
                  style={{
                    ...boxStyle,
                    color: "black",
                    margin: "0",
                  }}
                >
                  Apply Scholarships with CV Only
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    message.error("This feature not available yet.");
                  }}
                  style={{
                    ...boxStyle,
                    color: "black",
                    margin: "0",
                  }}
                >
                  Finding Student Loan
                </button>
              </div>
            </div>
          </div>
        </div>

        {enableChat && (
          <Scrollbars
            style={{
              width: "100%",
              height: maximizeView
                ? "540px"
                : dashbordHide
                ? "350px"
                : mobileView
                ? "600px"
                : "550px",
              marginBottom: "10px",
              display: "flex",
            }}
          >
            {enableChat &&
              messages.map((message, index) => {
                return (
                  <Message
                    key={index}
                    sender={message.sender}
                    text={message.text}
                    extra={message.extra}
                  />
                );
              })}
          </Scrollbars>
        )}

        <Form>
          <div
            style={{
              display: "none",
              justifyContent: "space-between",
              marginTop: "10px",
              width: "100%",
              alignItems: "center",
              marginBottom: "0px !important",
            }}
          >
            <input
              ref={inputRef}
              type="text"
              style={{
                flex: "1",
                margin: mobileView ? "10px" : "0 10px",
                padding: "8px",
                fontSize: "14px",
                flexBasis: "90%",
              }}
              className="chatInp"
              placeholder={"Type here..."}
            />

            <button
              style={{
                flex: "1 1 4%",
                cursor: "pointer",
                border: "none",
                padding: "0",
                margin: "0",
              }}
              disabled={chatBtn}
              type="submit"
            >
              <img
                src="/new/forward.png"
                alt="Button Image"
                style={{
                  width: "35px",
                  opacity: chatBtn ? "0.5" : "1",
                  cursor: chatBtn ? "not-allowed" : "pointer",
                }}
              />
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AssistanceChatBot;
