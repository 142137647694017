import React from "react";
import { Menu } from "antd";
import { useHistory } from "react-router-dom";
import "./Navbar.css";

const LeftMenu = () => {
  const mobileView = window.innerWidth < 960;
  const history = useHistory();
  const pathName = history.location.pathname.includes("lennyAssistance");

  return (
    <Menu
      mode={mobileView ? "vertical" : "horizontal"}
      style={{
        background: pathName ? "transparent" : "#FFFFFF",
        placeContent: "center",
        border: "none",
      }}
    >
      <Menu.Item key="chatbot">
        <a href="/lennyAssistance">Chatbot</a>
      </Menu.Item>
      <Menu.Item key="winners">
        <a href="/partners">Partners</a>
      </Menu.Item>
      <Menu.Item key="pricing">
        <a href="/pricing">Pricing</a>
      </Menu.Item>
      <Menu.Item key="aboutUs">
        <a href="/about-us">About Us</a>
      </Menu.Item>
      <Menu.Item key="scholarships">
        <a href="http://business.lenny.ai">Business</a>
      </Menu.Item>
      <Menu.Item key="about">
        <a href="/faq">FAQ</a>
      </Menu.Item>
      <Menu.Item key="connect">
        <a href="/contact">Contact</a>
      </Menu.Item>
      {/* <Menu.Item key="blog">
        <a href="https://lenny.ai/blog">Blog</a>
      </Menu.Item> */}
    </Menu>
  );
};

export default LeftMenu;
