import { message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BouncingDotsLoader from "./BouncingDotsLoader";
import { Form, Col, Alert, Button, Input, Menu, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import { useGoogleLogin } from "@react-oauth/google";
import { motion } from "framer-motion";
import en from "world_countries_lists/data/countries/en/world.json";
import { UserAuthService } from "services/user-auth.service";
import { constant } from "lodash";
import Text from "antd/lib/typography/Text";
// import fetch_ from "interceptors/FetchInterceptor";
import { API_BASE_URL, API_CHAT_URL } from "configs/AppConfig";
import "./css/chatbot.css";

import useAnalyticsEventTracker from "useAnalyticsEventTracker";

const ChatBot = ({ chatnav1Ref }) => {
  const gaEventTracker = useAnalyticsEventTracker("Chatbot");

  const mobileView = window.innerWidth < 915;
  const history = useHistory();

  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  const [disableLoginBtn, setDisableLoginButton] = useState(false);
  const [modal1Open, setModal1Open] = useState(false);
  const [alertShow, setAlertShow] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [alertType, setAlertType] = useState(true);
  const [phnNo, setPhnNo] = useState("");
  const [roboDisplay, setRoboDisplay] = useState(true);
  const [loginBtn, setLoginBtn] = useState(true);
  const [signUpBtn, setSignUpBtn] = useState(false);
  const [enableChat, setEnableChat] = useState(false);
  const [chatLoader, setChatLoader] = useState(false);
  const [userText, setUserText] = useState("");
  const [questions, setQuestions] = useState([]);
  const [chatBtn, setChatBtn] = useState(false);
  const [qusCnt, setQusCnt] = useState(0);
  const [chatType, setChatType] = useState("login");
  const [visible, setVisible] = useState(true);
  const [signUpBody, setSignUpBody] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [coutryShortCode, setCountryShortCode] = useState("");
  const [saveLoader, setSaveLoader] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [tableData, setTableData] = useState({});

  const [loginQusArr, setLoginQusArr] = useState([]);
  const [messages, setMessages] = useState([]);
  const [qusHint, setQusHint] = useState("Type here...");

  const [editBtns, setEditBtns] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [loginPass, setLoginPass] = useState(false);

  const [enableLogin, setEnableLogin] = useState(false);

  const [inpType, setInpType] = useState("text");
  const [loginEmailBody, setLoginEmailBody] = useState({
    answer: "",
    answer_key: "",
    question_id: "",
    question: "",
  });
  const [country, setCountry] = useState("");
  const [loginChatId, setLoginChatId] = useState(null);
  const [forgotFlow, setForgotFlow] = useState(false);
  const [resetPasword, setResetPasword] = useState(false);
  const [tryAgainBtsn, setTryAgainBtn] = useState(true);
  const [newAcc, setNewAcc] = useState(true);
  const [sameEmail, setSameEmail] = useState(true);
  const [loginGoogl, setLoginGoogle] = useState(true);

  //base style for login and signup buttons

  const [signUpType, setSignUpType] = useState(
    localStorage.getItem("signUp") === "true"
  );
  const [loginType, setLoginType] = useState(
    localStorage.getItem("login") === "true"
  );
  const registerConversion = (msg) => {
    if (window.trackdesk) {
      window.trackdesk("lennyai", "conversion", {
        conversionType: "lead",
        amount: {
          value: "0",
        },
        customParams: {
          advS1: msg,
        },
      });
    }
  };

  useEffect(() => {
    const signUpValue = localStorage.getItem("signUp");
    const loginValue = localStorage.getItem("login");
    setSignUpType(signUpValue);
    setLoginType(loginValue);

    let label = {
      screenName: "Assitance Screen ",
    };
    gaEventTracker("Chatbot Screen Visited ", JSON.stringify(label));
  }, []);

  const baseStyle = {
    marginRight: "15px",
    width: "auto",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "0.5px",
    padding: "5px 20px",
    cursor: "pointer",
  };

  const loginStyle = {
    ...baseStyle,
    backgroundColor:
      chatType === "login" || loginType == "true" ? "#8d96ff" : "transparent",
    color: chatType === "login" || loginType == "true" ? "white" : "black",
    border: "2px solid #8d96ff",
  };

  const signupStyle = {
    ...baseStyle,
    backgroundColor:
      chatType === "signup" || signUpType == "true" ? "#8d96ff" : "transparent",
    color: chatType === "signup" || signUpType == "true" ? "white" : "black",
    border: "2px solid #8d96ff",
  };

  //this is the table view component function for showing data in tabular manner
  const TableView = () => {
    const [editedData, setEditedData] = useState({ ...tableData });

    if (!tableData || Object.keys(tableData).length === 0) {
      return <p>No data available.</p>;
    }

    const handleInputChange = (key, value) => {
      console.log("value change", key, value);
      setEditedData((prevData) => ({ ...prevData, [key]: value }));
    };
    const handleSaveAll = async (phn = null) => {
      setEditMode(false);
      console.log(phn);
      console.log("mobile number ", editedData["Mobile Number"]);
      setTableData({
        ["First Name"]: editedData["First Name"],
        ["Last Name"]: editedData["Last Name"],

        Email: editedData["Email"],
        ["Mobile Number"]: phn ?? editedData["Mobile Number"],
        Password: editedData["Password"],
      });

      const finalChatBody = {
        firstName: editedData["First Name"],
        lastName: editedData["Last Name"],
        email: editedData["Email"],
        phone: phn ?? editedData["Mobile Number"],
        password: editedData["Password"],
        applicantType: "STUDENT",
        isProfileUpdate: "false",
        isScholarshipApplied: "false",
      };

      const result = await sendFinalChat(finalChatBody);

      if (result === false) {
        setEnableLogin(true);
        setEditBtns(() => {
          return false;
        });

        setEditMode(() => {
          return true;
        });
        return;
      } else {
        const uMsg = [
          ...messages,
          {
            sender: "user",
            text: "Save and Proceed",
          },
          {
            sender: "receiver",
            text: "Your account is successfully created and you are logged in.",
          },
        ];
        setMessages(uMsg);
      }

      setChatBtn(() => {
        return true;
      });
      setQusHint("Chat Ended..");

      setEditBtns(true);
      setEditMode(false);
    };

    const handleEditAll = () => {
      console.log("table data", tableData);
      if (editMode) {
        setTableData({ ...tableData });

        setEditMode(false);
      } else {
        setEditMode(true);
      }
    };

    return (
      <>
        <div className="message-left ">
          {/* <span
            style={{
              display: "inline-block",
              width: "30px",
              height: "37px",
              marginRight: "5px",
              backgroundImage: `url('/new/chatrob.png')`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          ></span> */}
          <p
            style={{
              backgroundColor: "white",
              padding: "10px",
              fontSize: mobileView ? "12px" : "16px",
              color: "black",
              fontWeight: "400",
            }}
          >
            Are the following details correct ?
          </p>
        </div>
        <Form
          onFinish={async (values) => {
            if (values.contactNo) {
              // console.log(
              //   "country code",
              //   editedData["Mobile Number"]?.split(" ")[0]
              // );
              // console.log("country code c", values);

              let phn =
                "+" + values.contactNo.code + " " + values.contactNo.phone;
              if (values.contactNo.code == null) {
                phn = editedData["Mobile Number"];
              }
              setCountryShortCode(values.contactNo.short);
              setPhnNo(() => {
                return phn;
              });
              await handleSaveAll(phn);
            } else {
              handleSaveAll();
            }
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <table>
              <tbody>
                {Object.entries(tableData).map(([key, value]) => (
                  <tr key={value}>
                    <td style={{ paddingRight: "25px", fontWeight: "600" }}>
                      {key}:
                    </td>
                    <td>
                      {editMode ? (
                        <>
                          {!key.toLowerCase().includes("number") ? (
                            <input
                              type={
                                key.toLowerCase().includes("email")
                                  ? "email"
                                  : key.toLowerCase().includes("number")
                                  ? "number"
                                  : "text"
                              }
                              value={editedData[key]}
                              onChange={(e) =>
                                handleInputChange(key, e.target.value)
                              }
                              style={{
                                border: key.toLowerCase().includes("email")
                                  ? enableLogin
                                    ? "2px solid red"
                                    : " "
                                  : "",
                              }}
                            />
                          ) : (
                            <ConfigProvider
                              locale={en}
                              areaMapper={(area) => {
                                return {
                                  ...area,
                                  emoji: (
                                    <img
                                      alt="flag"
                                      style={{
                                        width: 18,
                                        height: 18,
                                        verticalAlign: "sub",
                                      }}
                                      src={getFlag(area.short)}
                                    />
                                  ),
                                };
                              }}
                            >
                              <Form.Item
                                name="contactNo"
                                initialValue={{
                                  short: coutryShortCode,
                                  phone: editedData[key]?.split(" ")[1],
                                }}
                                onChange={(e) => {
                                  handleInputChange(key, e.target.value);
                                }}
                                style={{
                                  flex: "1",
                                  marginRight: "10px",
                                  padding: "8px",
                                  fontSize: "14px",
                                  flexBasis: "90%",
                                  marginBottom: "0px !important",
                                }}
                                hasFeedback
                                rules={rule.contact}
                              >
                                <CountryPhoneInput
                                  className="chatInp"
                                  inputProps={{ type: "number" }}
                                />
                              </Form.Item>
                            </ConfigProvider>
                          )}

                          <p
                            style={{
                              fontSize: "12px",

                              fontWeight: "500",
                            }}
                          >
                            {key.toLowerCase().includes("email")
                              ? enableLogin
                                ? `This email already exists`
                                : " "
                              : ""}

                            <span
                              style={{
                                fontSize: "12px",
                                color: "rgb(141, 150, 255)",
                                fontWeight: "500",
                                cursor: "pointer",
                                marginLeft: "5px",
                              }}
                              onClick={() => {
                                setEditBtns(true);
                                const newEmail = editedData["Email"];
                                yesLogin(newEmail);
                              }}
                            >
                              {key.toLowerCase().includes("email")
                                ? enableLogin
                                  ? ` Login`
                                  : " "
                                : ""}
                            </span>
                          </p>
                        </>
                      ) : (
                        <span>{value}</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: editBtns ? "none" : "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <button
              style={{
                width: "auto",
                background: "transparent",
                border: "2px solid #8d96ff",
                padding: "8px 20px",
                fontSize: "13px",
                fontWeight: "600",
                cursor: "pointer",
                marginRight: "20px",
              }}
              onClick={() => {
                handleEditAll();
              }}
              type="button"
              disabled={editBtns}
            >
              {editMode ? "Cancel" : "Edit"}
            </button>
            <button
              style={{
                width: "auto",
                backgroundColor: "#8d96ff",
                color: "white",
                fontSize: "13px",
                fontWeight: "600",
                letterSpacing: "0.5px",
                cursor: "pointer",
              }}
              type="submit"
              disabled={editBtns}
            >
              Save and Proceed
              {saveLoader ? (
                <LoadingOutlined style={{ marginRight: 10 }} />
              ) : null}
            </button>
          </div>
        </Form>
      </>
    );
  };

  //chat structure function to show chat in chatbot
  const chatStructure = (text, sender, extra) => {
    // { console.log(text) }
    if (text == "loading") {
      return <BouncingDotsLoader />;
    } else if (text?.[0]?.chat_type === "table") {
      return (
        <div>
          <TableView />
        </div>
      );
    } else if (text?.[0]?.chat_type === "login_btn") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
              fontSize: mobileView ? "12px" : "16px",
              fontWeight: "500",
            }}
          >
            {text[0]?.data}
          </div>
          <div>
            <div
              style={{
                display: loginGoogl ? "flex" : "none",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  width: "auto",
                  background: "transparent",
                  border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={() => {
                  // message.error("This feature is not available yet!!");
                  forgotPassword();
                  setLoginGoogle(false);
                }}
              >
                Forgot Password
              </button>
              <button
                style={{
                  width: "auto",
                  background: "transparent",
                  border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={() => {
                  login();
                  setLoginGoogle(false);
                  let label = {
                    screenName: "Assistance Login Options",
                  };
                  gaEventTracker(
                    "Login with google Button Clicked",
                    JSON.stringify(label)
                  );
                }}
              >
                Login With Google
              </button>
            </div>
          </div>
        </div>
      );
    } else if (text?.[0]?.chat_type === "login_incorrect_pass") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
              fontSize: mobileView ? "12px" : "16px",
              fontWeight: "500",
            }}
          >
            {text[0]?.data}
          </div>
          <div>
            <div
              style={{
                display: tryAgainBtsn ? "flex" : "none",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  width: "auto",
                  background: "transparent",
                  border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={() => {
                  // message.error("This feature is not available yet!!");
                  forgotPassword();
                  setTryAgainBtn(false);
                }}
              >
                Forgot Password
              </button>
              <button
                style={{
                  width: "auto",
                  background: "transparent",
                  border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={async () => {
                  // login();
                  setChatType(() => {
                    return "login";
                  });
                  await setQusCnt(() => {
                    return qusCnt - 1;
                  });
                  setChatBtn(false);
                  const newMsg = [
                    ...messages,
                    {
                      type: "user",
                      text: "Try again",
                    },
                    {
                      sender: "receiver",
                      text: loginQusArr.find((e) => {
                        return e.answer_key === "password";
                      })?.question,
                      // text: loginQusArr[1]?.question,
                    },
                  ];
                  console.log(newMsg, qusCnt);
                  setMessages(newMsg);
                  setTryAgainBtn(false);
                }}
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      );
    } else if (text?.[0]?.chat_type === "wrong_otp") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
              fontSize: mobileView ? "12px" : "16px",
              fontWeight: "500",
            }}
          >
            {text[0]?.data}
          </div>
          <div>
            <div
              style={{
                display: newAcc ? "flex" : "none",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  width: "auto",
                  background: "transparent",
                  border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={() => {
                  // message.error("This feature is not available yet!!");
                  // forgotPassword();
                  signUpFn();
                  setNewAcc(false);
                }}
              >
                Create new account
              </button>
              <button
                style={{
                  width: "auto",
                  background: "transparent",
                  border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={async () => {
                  forgotPassword();
                  setNewAcc(false);
                }}
              >
                Resend OTP
              </button>
            </div>
          </div>
        </div>
      );
    } else if (text?.[0]?.chat_type === "login_email_not_found") {
      return (
        <div
          style={{
            display: sameEmail ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
              fontSize: mobileView ? "12px" : "16px",
              fontWeight: "500",
            }}
          >
            {text[0]?.data}
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  width: "auto",
                  background: "transparent",
                  border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={() => {
                  // message.error("This feature is not available yet!!");
                  signUpFn();
                  setSameEmail(false);
                }}
              >
                Signup
              </button>
              <button
                style={{
                  width: "auto",
                  background: "transparent",
                  border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={async () => {
                  loginAssFn();
                  setSameEmail(false);
                }}
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      );
    } else if (text?.[0]?.chat_type === "select_login_yes") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            maxWidth: "60%",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
              fontSize: mobileView ? "12px" : "16px",
              fontWeight: "500",
            }}
          >
            {text[0]?.data}
          </div>
          <div>
            <div
              style={{
                display: visible ? "flex" : "none",
                justifyContent: "space-evenly",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  width: "auto",
                  background: "transparent",
                  border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={async () => {
                  noLogin();
                }}
              >
                No
              </button>
              <button
                style={{
                  width: "auto",
                  background: "transparent",
                  border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={() => {
                  setVisible(false);
                  yesLogin();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            backgroundColor:
              sender === "receiver"
                ? text === "loading"
                  ? "transparent"
                  : "white"
                : "#D3D6FF",
            padding: "10px",
            fontSize: mobileView ? "12px" : "16px",
            fontWeight: "500",
            maxWidth: sender === "receiver" ? "100%" : "100%",
            whiteSpace: "normal",
            overflowWrap: "break-word",
            wordWrap: "break-word",
          }}
        >
          {text}
        </div>
      );
    }
  };

  //message component to render messages
  const Message = ({ sender, text, extra }) => (
    <div
      className={`message ${
        sender === "receiver" ? "message-left" : "message-right"
      }`}
      ref={messagesEndRef}
    >
      {sender === "receiver" && (
        <span
          style={{
            display: "inline-block",
            width: "30px",
            height: "37px",
            marginRight: "5px",
            backgroundImage: `url('/new/chatrob.png')`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        ></span>
      )}

      {chatLoader ? (
        <BouncingDotsLoader />
      ) : (
        <>{chatStructure(text, sender, extra)}</>
      )}
      {sender === "user" && (
        <span
          style={{
            display: "inline-block",
            width: "25px",
            height: "37px",
            marginLeft: "5px",
            backgroundImage: `url('/new/peroson.png')`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        ></span>
      )}
    </div>
  );
  //api to connect google login with our api
  const postGoogleLogin = async (tokenResponse) => {
    try {
      //https://api-scholarship.lenlen.ai/api/v1/users/auth/user-google
      const response = await fetch(
        `${API_BASE_URL}api/v1/users/auth/user-google`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, /",
            "Accept-Language": "en-GB,en-US;q=0.9,en;q=0.8,hi;q=0.7",
            Connection: "keep-alive",
            "Content-Type": "application/json",
            Origin: "https://business.lenny.ai",
            Referer: "https://business.lenny.ai/",
          },
          body: JSON.stringify(tokenResponse),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.status === 404) {
        message.error("Some internal error occured please try again later!!!");
      }
      const data = await response.json();
      // console.log("🚀 ~ postGoogleLogin ~ data:", data?.data?.user);
      localStorage.setItem(
        "userInfo",
        typeof data?.data?.user === "string"
          ? data?.data?.user
          : JSON.stringify(data?.data?.user)
      );
      localStorage.setItem(
        "refresh_token",
        typeof data?.data?.refreshToken === "string"
          ? data?.data?.refreshToken
          : JSON.stringify(data?.data?.refreshToken)
      );
      localStorage.setItem(
        "token",
        typeof data?.data?.accessToken === "string"
          ? data?.data?.accessToken
          : JSON.stringify(data?.data?.accessToken)
      );

      const isProfileUpdate = data?.data?.user?.isProfileUpdate;
      message.success("User Logged In Successfully!!");
      setTimeout(() => {
        isProfileUpdate
          ? history.push("/auth/dashboard")
          : history.push("/auth/userdetails");
      }, 500);
      let label = {
        screenName: "Assistance Login Options",
      };
      gaEventTracker("Login with google successful", JSON.stringify(label));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //function to use google oauth
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await postGoogleLogin(tokenResponse);
    },
    onError: () => {
      console.log("error");
      message.error("Error in Google Login");
    },
  });

  //general login function
  const onLogin = (values) => {
    const login_info = { ...values, loginType: "register_user" };
    setAlertShow(false);
    UserAuthService.login(login_info)
      .then(({ data }) => {
        localStorage.setItem("clientIp", data?.clientIp);

        localStorage.removeItem("register1");
        localStorage.removeItem("register2");
        localStorage.setItem(
          "userInfo",
          typeof data.user === "string" ? data.user : JSON.stringify(data.user)
        );
        localStorage.setItem(
          "refresh_token",
          typeof data.refreshToken === "string"
            ? data.refreshToken
            : JSON.stringify(data.refreshToken)
        );
        localStorage.setItem(
          "token",
          typeof data.accessToken === "string"
            ? data.accessToken
            : JSON.stringify(data.accessToken)
        );
        localStorage.setItem(
          "isActiveSubscription",
          data.user.isActiveSubscription
        );
        const isProfileUpdate = data?.user?.isProfileUpdate;
        message.success("User Logged In Successfully!!");
        setTimeout(() => {
          isProfileUpdate
            ? history.push("/auth/dashboard")
            : history.push("/auth/userdetails");
        }, 500);
        let label = {
          screenName: "Assistance Login Options",
        };
        gaEventTracker("Login Manually Successful", JSON.stringify(label));
      })
      .catch((info) => {
        setAlertShow(true);
        setAlertMessage(info.description || "Server Error");
        setAlertType(false);
      })
      .finally(() => {});
  };

  //get flag function to show flag in std code section
  const getFlag = (short) => {
    const data = require(`world_countries_lists/data/flags/24x24/${short.toLowerCase()}.png`);
    if (typeof data === "string") {
      return data;
    }

    return data.default;
  };

  //getting ip to show flag on the basis of user's country
  function getIp(callback) {
    fetch("https://ipinfo.io/json?token=d580b9741ba3c2")
      .then((resp) => {
        return resp.json();
      })
      .catch((err) => {
        return {
          country: "us",
        };
      })
      .then((resp) => callback(resp.country));
  }

  //rule for mobile number field
  const rule = {
    contact: [
      {
        required: true,
        message: "Please input your phone number!",
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (
            !value ||
            getFieldValue("contactNo").phone === undefined ||
            getFieldValue("contactNo").phone === ""
          ) {
            setChatBtn(true);
            return Promise.reject("Please input your phone number!");
          } else if (
            getFieldValue("contactNo").phone.match(/^[0-9]+$/) != null
          ) {
            setChatBtn(false);
            return Promise.resolve();
          }
          setChatBtn(true);
          return Promise.reject("Please enter only digits!");
        },
      }),
    ],
  };
  //intiate login chats with chat id
  const intitiateLoginId = async (emailChatBody) => {
    setUserText("");
    try {
      const response = await fetch(
        `${API_CHAT_URL}api/login/chats`,
        // `https://chat-api.lenlen.ai/api/login/chats`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailChatBody),
        }
      );
      if (response.status === 404) {
        message.error("Some internal error occured please try again later!!!");
      }
      const data = await response.json();

      if (data?.statusCode === 400) {
        message.error("Internal Error! Please try again later.");
        setChatBtn(true);
        signUpFn();
        throw new Error();
      }

      await setLoginChatId(data?._id);
      return data?._id;
    } catch (error) {
      console.log("Error in sending Chat! ", error);
    }
  };

  //furtur logic for signup chat
  const sendLoginChat = async (loginChatId, body) => {
    setUserText("");
    try {
      if (!loginChatId) {
        message.error("Internal Error! Please try again later.");
        setChatBtn(false);
        throw new Error("Chat Id not found");
      }
      const response = await fetch(
        `${API_CHAT_URL}api/login/chats?id=${loginChatId}`,
        // `https://chat-api.lenlen.ai/api/login/chats?id=${loginChatId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();

      if (response.status === 201) {
        //check for the user login
        if (data?.data?.user) {
          const newChatMessage = {
            sender: "receiver",
            text: "You are successfully logged in.",
          };

          setMessages((prevMessages) => [...prevMessages, newChatMessage]);
          setChatBtn(true);
          // message.success("User Logged In Successfully!!");
          localStorage.setItem(
            "userInfo",
            typeof data?.data?.user === "string"
              ? data?.data?.user
              : JSON.stringify(data?.data?.user)
          );
          localStorage.setItem(
            "refresh_token",
            typeof data?.data?.refreshToken === "string"
              ? data?.data?.refreshToken
              : JSON.stringify(data?.data?.refreshToken)
          );
          localStorage.setItem(
            "token",
            typeof data?.data?.accessToken === "string"
              ? data?.data?.accessToken
              : JSON.stringify(data?.data?.accessToken)
          );
          localStorage.setItem(
            "isActiveSubscription",
            data?.data?.user.isActiveSubscription
          );
          const isProfileUpdate = data?.data?.user?.isProfileUpdate;
          message.success("User Logged In Successfully!!");
          setTimeout(() => {
            isProfileUpdate
              ? history.push("/auth/dashboard")
              : history.push("/auth/userdetails");
          }, 500);
          let label = {
            screenName: "Assistance Login Options",
          };
          gaEventTracker("Assisstance Login successful", JSON.stringify(label));
        } else if (data?.data?.message?.includes("OTP")) {
          setForgotFlow(true);
          message.success(data?.data?.message);
        } else if (data?.otp) {
          setResetPasword(true);
          chatThreeDotLoading(true, null, "Enter a new password");
          setChatBtn(false);

          // message.success("inprocess")
        }
      }
      // else if (data?.error?.errorCode === "WRONG_PASSWORD") {
      else if (response.status === 400) {
        message.error(data?.error?.message);
        if (data?.error?.errorCode === "WRONG_PASSWORD") {
          setTryAgainBtn(true);
          const newChatMessage = {
            sender: "receiver",
            text: [
              {
                chat_type: "login_incorrect_pass",
                data: "Your password is incorrect. Select from the options below to proceed further",
              },
            ],
          };

          setMessages((prevMessages) => [...prevMessages, newChatMessage]);
        }
        if (data?.error?.errorCode === "EMAIL_NOT_FOUND") {
          setSameEmail(true);

          const newChatMessage = {
            sender: "receiver",
            text: [
              {
                chat_type: "login_email_not_found",
                data: "This email is not registered. Select from the options below to proceed further.",
              },
            ],
          };

          setMessages((prevMessages) => [...prevMessages, newChatMessage]);
        }
        if (data?.error?.message?.includes("OTP")) {
          setNewAcc(true);
          const newChatMessage = {
            sender: "receiver",
            text: [
              {
                chat_type: "wrong_otp",
                data: "Entered OTP is incorrect. Select from the options below to proceed further.",
              },
            ],
          };

          setMessages((prevMessages) => [...prevMessages, newChatMessage]);
        }
      } else if (response.status === 404) {
        message.error("Some internal error occured please try again later!!!");
      } else {
        message.error("Some error occured try again later!!");
      }
    } catch (error) {
      console.log("Error in sending Chat! ", error);
    }
  };

  //forgot password flow need to set
  const forgotPassword = () => {
    // message.error("Forgot Password!! Development in process");
    // setForgotFlow(true)
    setQusHint("Type here...");
    const genOtpBody = {
      answer_key: "generate_otp",
    };
    setTryAgainBtn(true);
    setLoginGoogle(true);

    sendLoginChat(loginChatId, genOtpBody);
    updateMsg("user", "Forgot Password");
    chatThreeDotLoading(
      true,
      null,
      "Please share your otp sent to your email ID"
    );
  };
  //login flow when same email encounters
  const yesLogin = async (newEmail) => {
    setChatBtn(false);
    const updatedMessages = [
      ...messages,
      {
        sender: "user",
        text: "Yes",
      },
    ];

    setMessages(updatedMessages);

    const askEmailQusBody = loginQusArr.find((e) => {
      return e.answer_key === "email";
    });
    if (newEmail) {
      const createEmailChatBody = {
        question_id: askEmailQusBody?._id,
        question: askEmailQusBody?.question,
        answer: newEmail,
        answer_key: askEmailQusBody?.answer_key,
      };
      await intitiateLoginId(createEmailChatBody);
    } else if (loginEmailBody.answer && loginEmailBody.question_id) {
      const createEmailChatBody = {
        question_id: askEmailQusBody?._id,
        question: askEmailQusBody?.question,
        answer: loginEmailBody?.answer,
        answer_key: askEmailQusBody?.answer_key,
      };
      await intitiateLoginId(createEmailChatBody);
    }

    const askPasswordQus = loginQusArr.find((e) => {
      return e.answer_key === "password";
    });
    const passwordBody = {
      question_id: askPasswordQus?._id,
      question: askPasswordQus?.question,
      answer: userText,
      answer_key: askPasswordQus?.answer_key,
    };

    const chat = [
      {
        chat_type: "login_btn",
        data: "Enter your password or choose one of the options below.",
      },
    ];
    chatThreeDotLoading(true, null, chat);
    setLoginPass(true);
    setUserText("");
  };

  //no login flow when user denies with same email what to do next
  const noLogin = async () => {
    setVisible(false);
    setChatBtn(false);
    const uMsg = [
      ...messages,
      {
        sender: "user",
        text: "No",
      },
    ];
    await setMessages(() => {
      return uMsg;
    });

    await signUpChatLogic(true);
  };

  //top login button
  const logINBtn = async () => {
    setChatType("login");

    if (mobileView) {
      chatnav1Ref.current.style.display = "none";
    }

    setSignUpBtn(false);
    setMessages([]);
    setQusCnt(0);
    setChatBtn(true);

    setRoboDisplay(false);

    setLoginBtn(true);

    localStorage.setItem("login", false);
    localStorage.setItem("signUp", false);
    let label = {
      screenName: "Assistance Login Options",
    };
    gaEventTracker("Login Button Clicked", JSON.stringify(label));
    //await loginQuestions();
  };
  //top signup btn
  const signUpFn = () => {
    setChatType("signup");

    if (mobileView) {
      chatnav1Ref.current.style.display = "none";
    }
    setLoginBtn(false);
    setMessages([]);
    setQusCnt(0);
    setChatBtn(true);
    setRoboDisplay(false);
    setVisible(true);
    setLoginPass(false);
    // setInpType("text");
    // setEnableLogin(false);

    setSignUpBtn(true);
    localStorage.setItem("login", false);
    let label = {
      screenName: "Assistance Signup Options",
    };
    gaEventTracker("Signup Button Clicked", JSON.stringify(label));
  };

  //initiate first conversion when clicked on chat with assistant in both login and signup
  const triggerConversion = async (btnType, qus) => {
    // console.log("🚀 ~ triggerConversion ~ qus:", qus);

    if (questions?.length === 0) {
      await getQuestions();
    }
    if (loginQusArr?.length === 0) {
      await loginQuestions();
    }

    console.log(qusCnt);
    setChatBtn(true);
    const newChatMessage = {
      sender: "receiver",
      text: "loading",
    };

    setMessages((prevMessages) => [...prevMessages, newChatMessage]);
    // if ((btnType === "signup" && questions.length > 0) || (btnType !== "signup" && loginQusArr.length > 0)) {
    setTimeout(() => {
      const qusCnt = 0;
      const newChatMessage = {
        sender: "receiver",
        text: btnType === "signup" ? qus?.[0]?.question : qus?.[0]?.question,
        extra:
          btnType === "signup"
            ? {
                question_id: qus?.[0]?._id,
                question: qus?.[0]?.question,
                answer: qus?.[0]?.answer,
                answer_key: qus?.[0]?.answer_key,
              }
            : {
                question_id: qus?.[0]?._id,
                question: qus?.[0]?.question,
                answer: qus?.[0]?.answer,
                answer_key: qus?.[0]?.answer_key,
              },
      };
      console.log(
        "🚀 ~ setTimeout ~ newChatMessage:",
        newChatMessage,
        qus,
        loginQusArr
      );
      setQusHint(() => {
        return btnType === "signup" ? qus?.[qusCnt]?.hint : qus?.[qusCnt]?.hint;
      });
      if (newChatMessage.extra.answer_key === "email") {
        setInpType("email");
      } else {
        setInpType("text");
      }

      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        newMessages.filter((e) => {
          if (e.text !== "loading") {
            return e;
          }
        });
        newMessages.pop();
        setChatBtn(false);
        return [...newMessages, newChatMessage];
      });
      setUserText("");
    }, 500);
    // }
    setQusCnt((prev) => {
      return prev + 1;
    });
  };

  //these function will be called when user clicks on signup with assistant after trigger conversion
  const signUpAssFn = async () => {
    if (questions.length === 0) {
      await getQuestions();
    }
    if (loginQusArr.length === 0) {
      await loginQuestions();
    }
    setMessages([]);
    setSignUpBtn(false);
    setEnableChat(true);
    setQusCnt(0);
    setChatBtn(false);
    setVisible(true);

    updateMsg("user", "Signup with assistant");

    if (questions.length > 0) {
      triggerConversion("signup", questions);
    } else {
      const qusData = await getQuestions();
      await triggerConversion("signup", qusData);
    }
    setDisableLoginButton(true);
    let label = {
      screenName: "Assistance Signup Options",
    };
    gaEventTracker("SignUp Assistant Button Clicked", JSON.stringify(label));
    registerConversion("Signup Lead via Chatbot");
  };

  const loginAssFn = async () => {
    if (mobileView) {
      chatnav1Ref.current.style.display = "none";
    }

    if (loginQusArr.length === 0) {
      await loginQuestions();
    }
    setMessages([]);

    setLoginBtn(false);
    setEnableChat(true);
    updateMsg("user", "Login with Assistant");
    setQusCnt(() => {
      return 0;
    });
    setDisableLoginButton(true);
    // triggerConversion("login");

    if (questions.length > 0) {
      triggerConversion("login", loginQusArr);
    } else {
      const qusData = await loginQuestions();
      await triggerConversion("login", qusData);
    }
    let label = {
      screenName: "Assistance Login Options",
    };
    gaEventTracker("Login Assistant Button Clicked", JSON.stringify(label));
  };

  //signup body to generate extra parameter like question id to append in the text flow
  const signUPBody = (uText, skip) => {
    const index = skip ? qusCnt : qusCnt - 1;
    const update = {
      ...signUpBody,
      [questions[index]?.answer_key]: uText,
    };
    setSignUpBody(update);
    return update;
  };

  //general function to append message in the messages array
  const updateMsg = (senderType, text) => {
    const updatedMessages = [...messages, { sender: senderType, text: text }];
    setMessages(() => {
      return updatedMessages;
    });

    return updatedMessages;
  };

  //signup function to initiate chat id
  const initiateChatId = async (chatInfo) => {
    setUserText("");
    try {
      const response = await fetch(
        `${API_CHAT_URL}api/signup/chats`,
        // "https://chat-api.lenlen.ai/api/signup/chats",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(chatInfo),
        }
      );
      if (response.status === 404) {
        message.error("Some internal error occured please try again later!!!");
      }
      const data = await response.json();
      if (data.statusCode === 400) {
        message.error("Internal Error! Please try again later.");
        setChatBtn(true);
        signUpFn();
        throw new Error();
      }
      await setChatId(() => {
        return data._id;
      });
      return data._id;
    } catch (error) {
      console.log("Error in sending Chat! ", error);
    }
  };

  //signup function to chat further
  const sendChat = async (chatInfo, updatedMessages) => {
    try {
      if (!chatId) {
        message.error("Internal Error! Please try again later.");
        setChatBtn(true);
        throw new Error("Chat Id not found");
      }
      const response = await fetch(
        `${API_CHAT_URL}api/signup/chats?id=${chatId}`,
        // `https://chat-api.lenlen.ai/api/signup/chats?id=${chatId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(chatInfo),
        }
      );
      const data = await response.json();
      if (response.status === 404) {
        message.error("Some internal error occured please try again later!!!");
      }
      if (data?.data?.available === false) {
        setChatBtn(true);
        const updatedMessage = [
          ...updatedMessages,
          {
            sender: "receiver",
            text: [
              {
                chat_type: "select_login_yes",
                data: "This email is already registered. Do you want to login with this email.",
              },
            ],
          },
        ];
        setMessages(updatedMessage);
        setVisible(true);
        // console.log("🚀 ~ signUpChatLogic ~ sameEmailErr:", sameEmailErr);
        setUserText("");

        setQusCnt(() => {
          return qusCnt - 1;
        });
        return false;
      }
      return true;
    } catch (error) {
      console.log("Error in sending Chat! ", error);
    }
  };

  //final signup funciton to register
  const sendFinalChat = async (chatBody) => {
    try {
      setSaveLoader(true);
      const response = await fetch(
        `${API_CHAT_URL}api/signup/chats?id=${chatId}`,
        // `https://chat-api.lenlen.ai/api/signup/chats?id=${chatId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(chatBody),
        }
      );
      if (response.status === 404 || response.status === 500) {
        message.error("Some internal error occured please try again later!!!");
        throw new Error("Internal server error");
      }
      const data = await response.json();
      if (data?.success === true) {
        localStorage.setItem(
          "userInfo",
          typeof data.user === "string" ? data.user : JSON.stringify(data.user)
        );
        localStorage.setItem(
          "refresh_token",
          typeof data.refreshToken === "string"
            ? data.refreshToken
            : JSON.stringify(data.refreshToken)
        );
        localStorage.setItem(
          "token",
          typeof data.accessToken === "string"
            ? data.accessToken
            : JSON.stringify(data.accessToken)
        );
        localStorage.setItem(
          "isActiveSubscription",
          data.user.isActiveSubscription
        );
        message.success("User Signed Up Successfully!! [Chat Ended]");
        registerConversion("Account Created via chatbot");
        const isProfileUpdate = data?.user?.isProfileUpdate;
        // message.success("User Logged In Successfully!!");
        setTimeout(() => {
          isProfileUpdate
            ? history.push("/auth/dashboard")
            : history.push("/auth/userdetails");
        }, 1500);
        let label = {
          screenName: "Assistance Signup Options",
        };
        gaEventTracker("Assisstance SignUp successful", JSON.stringify(label));
      }

      if (data?.data?.available === false) {
        setChatBtn(true);

        message.error(
          "This email is already registered. Do you want to login with this email."
        );
        return false;
      }
      setSaveLoader(true);
      setChatBtn(true);

      return true;
    } catch (error) {
      setSaveLoader(false);
    } finally {
      setSaveLoader(false);
    }
  };

  const chatThreeDotLoading = async (skipCall, apiCall, msg) => {
    // Display the loading message
    const loadingMessage = {
      sender: "receiver",
      text: "loading",
    };
    setMessages((prevMessages) => [...prevMessages, loadingMessage]);

    try {
      // Wait for the API call to complete
      let responseText;
      if (skipCall) {
        responseText = msg;
      } else {
        responseText = await apiCall();
      }
      // setTimeout(() => {
      // Update the message with the API response
      setMessages((prevMessages) => {
        // Remove the loading message
        const newMessages = prevMessages.filter((e) => e.text !== "loading");
        // Add the new message with the API response
        return [...newMessages, { sender: "receiver", text: responseText }];
      });
      // }, 500);

      setChatBtn(false);
    } catch (error) {
      // Handle any errors
      console.error("API call failed:", error);

      // Update the message with an error message
      setMessages((prevMessages) => {
        // Remove the loading message
        const newMessages = prevMessages.filter((e) => e.text !== "loading");
        // Add an error message
        return [
          ...newMessages,
          { sender: "receiver", text: "Error loading response" },
        ];
      });

      setChatBtn(false);
    }
  };
  //signup chat logic to handle signup flow
  const signUpChatLogic = async (skip = false, phn) => {
    setChatBtn(true);
    setRoboDisplay(false);
    setLoginBtn(false);
    setSignUpBtn(false);
    setEnableChat(true);

    const updatedMessages =
      !skip && (await updateMsg("user", phn ? phn : userText));

    if (loginPass) {
      console.log("herer", forgotFlow);
      const updatedMessages = [...messages, { sender: "user", text: userText }];
      setMessages(updatedMessages);

      const askPasswordQus = loginQusArr.find((e) => {
        return e.answer_key === "password";
      });
      if (forgotFlow) {
        const resetPassBody = {
          answer_key: "otp",
          answer: userText,
        };
        await sendLoginChat(loginChatId, resetPassBody);

        setForgotFlow(false);
        return;
      } else if (resetPasword) {
        const resetPassBody = {
          answer_key: "newPassword",
          answer: userText,
        };
        await sendLoginChat(loginChatId, resetPassBody);
      } else {
        const passwordBody = {
          question_id: askPasswordQus?._id,
          question: askPasswordQus?.question,
          answer: userText,
          answer_key: askPasswordQus?.answer_key,
        };
        await sendLoginChat(loginChatId, passwordBody);
      }
      // setLoginPass(false);
      setChatBtn(true);
      setUserText("");

      return;
    }
    if (questions[qusCnt]?.answer_key === "email") {
      setInpType(() => {
        return "email";
      });
    } else if (questions[qusCnt]?.answer_key === "phone") {
      setInpType("number");
    } else {
      setInpType("text");
    }

    const extra = {
      question_id: questions[qusCnt - 1]?._id,
      question: questions[qusCnt - 1]?.question,
      answer: userText,
      answer_key: questions[qusCnt - 1]?.answer_key,
    };

    if (extra.answer_key === "email") {
      setLoginEmailBody(extra);
    }

    if (qusCnt - 1 === 0) {
      const id = await initiateChatId(extra);
    } else if (qusCnt <= questions.length) {
      if (extra.answer_key === "phone") {
        extra.answer = phn;
        sendChat(extra, updatedMessages);
      } else {
        const data = await sendChat(extra, updatedMessages);
        if (!data) {
          message.error(
            "Email already exist!! Please login or try with another email"
          );
          return;
        }
        // console.log('running')?

        console.log(data);
      }
    } else {
    }

    const sBody = await signUPBody(userText, skip);
    // console.log("🚀 ~ signUpChatLogic ~ sBody:", sBody);

    if (!skip && questions.length === qusCnt) {
      setEditBtns(false);
      setQusHint("Type here...");
      const checkData = {
        sender: "receiver",
        text: [
          {
            chat_type: "table",
            data: {
              ["First Name"]: sBody?.firstName,
              ["Last Name"]: sBody?.lastName,

              Email: sBody?.email,
              ["Mobile Number"]: phnNo ?? sBody?.phone,
              Password: sBody?.password,
            },
          },
        ],
      };

      setTableData(checkData.text[0]?.data);
      const tMsg = [...updatedMessages, checkData];
      setMessages(tMsg);
      setUserText("");

      return;
    }

    const rText = sBody?.firstName
      ? `Hi ${sBody?.firstName}, ${questions[qusCnt]?.question}`
      : questions[qusCnt]?.question;
    await chatThreeDotLoading(true, null, rText);
    setQusCnt((prev) => {
      return prev + 1;
    });
    setUserText("");
  };

  //login chat logic to handle login flow
  const loginChatLogic = async () => {
    setQusCnt((prev) => {
      return prev + 1;
    });

    setChatBtn(true);
    setRoboDisplay(false);
    setLoginBtn(false);
    setSignUpBtn(false);
    setEnableChat(true);

    updateMsg("user", userText);

    console.log(forgotFlow, resetPasword);
    if (forgotFlow) {
      const resetPassBody = {
        answer_key: "otp",
        answer: userText,
      };
      await sendLoginChat(loginChatId, resetPassBody);

      setForgotFlow(false);
      return;
    } else if (resetPasword) {
      const resetPassBody = {
        answer_key: "newPassword",
        answer: userText,
      };
      await sendLoginChat(loginChatId, resetPassBody);
    } else {
      const extra = {
        question_id: loginQusArr[qusCnt - 1]?._id,
        question: loginQusArr[qusCnt - 1]?.question,
        answer: userText,
        answer_key: loginQusArr[qusCnt - 1]?.answer_key,
      };
      console.log("🚀 ~ loginChatLogic ~ extra:", extra);

      if (qusCnt - 1 == 0) {
        await intitiateLoginId(extra);
        setInpType(() => {
          return "text";
        });
      } else if (qusCnt === loginQusArr.length) {
        await sendLoginChat(loginChatId, extra);
        return;
      }

      const newChatMessage = {
        sender: "receiver",
        text: "loading",
      };

      setMessages((prevMessages) => [...prevMessages, newChatMessage]);

      setTimeout(() => {
        const newChatMessage = {
          sender: "receiver",
          text: loginQusArr[qusCnt]?.question,
        };
        setQusHint(loginQusArr[qusCnt]?.hint);

        setMessages((prevMessages) => {
          const newMessages = [...prevMessages];
          newMessages.filter((e) => {
            if (e.text !== "loading") {
              return e;
            }
          });
          newMessages.pop();
          return [...newMessages, newChatMessage];
        });
        console.log(messages);

        setChatBtn(false);
      }, 200);

      setUserText("");
    }
  };

  //submit chat function to handle chat flow when user clicks on send button
  const submitChat = async (btnType, phn) => {
    const login = localStorage.getItem("login");
    const signUp = localStorage.getItem("signUp");

    if (userText === "") {
      message.error("Please enter a message");
    } else if (btnType === "signup" || signUp == "true") {
      await signUpChatLogic(false, phn);
    } else if (btnType === "login" || login == "true") {
      await loginChatLogic();
    } else {
      message.error("Please select a chat type either login or signup");
      setUserText("");
    }
  };

  //function to get the signup questions
  const getQuestions = async () => {
    try {
      const response = await fetch(
        `${API_CHAT_URL}api/questions?questionType=signUp`
        // "https://chat-api.lenlen.ai/api/questions?questionType=signUp"
      );
      const data = await response.json();
      if (response.status === 404) {
        message.error("Some internal error occured please try again later!!!");
      }
      setQuestions(data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //function to get the login question
  const loginQuestions = async () => {
    try {
      const response = await fetch(
        `${API_CHAT_URL}api/questions?questionType=login`
        // "https://chat-api.lenlen.ai/api/questions?questionType=login"
      );
      const data = await response.json();
      if (response.status === 404) {
        message.error("Some internal error occured please try again later!!!");
      }
      setLoginQusArr(data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //form hander function when user clicks on send button
  const onFinish = async (values) => {
    if (inpType === "number") {
      setCountryShortCode(() => {
        return values.contactNo.short;
      });
      console.log("here onFinish", values);
      let phn = "+" + values.contactNo.code + " " + values.contactNo.phone;
      setPhnNo(() => {
        return phn;
      });
      await submitChat(chatType, phn);
    } else {
      submitChat(chatType);
    }
  };

  //function to check the type of chat like login or sign up on basis of local storage
  const checkType = () => {
    const signUp = localStorage.getItem("signUp");

    if (signUp == "true") {
      signUpFn();
    }

    const login = localStorage.getItem("login");

    if (login == "true") {
      logINBtn();
    }
  };

  useEffect(() => {
    checkType();
    getQuestions();
    loginQuestions();
    getIp((country) => {
      setCountry(country);
    });
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [messages]);

  return (
    <div
      className="chatnav2Inner"
      style={{
        padding: mobileView ? "0 10px" : "0 25px",
        backgroundColor: "#f0f1ff",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "95%",
        borderTopLeftRadius: "28px",
        borderTopRightRadius: "28px",
        justifyContent: "space-between",
        paddingBottom: "25px",
        margin: mobileView ? "10px auto" : "35px 0 0 0 ",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h3
          style={{
            fontSize: mobileView ? "18px" : "20px",
            marginTop: "25px",
            fontWeight: "600",
          }}
        >
          Welcome to Lenny’s Assistant
        </h3>
        <p
          style={{
            color: "#756f6f",
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "500",
            display: disableLoginBtn ? "none" : "block",
          }}
        >
          I'm Lenny, your friendly AI assistant here to help.
          {/* Whether you have
          questions, need assistance, or just want to chat, I'm here 24/7. Let's
          get started - feel free to ask me anything! */}
        </p>
        <div
          style={{
            display: disableLoginBtn ? "none" : "flex",
          }}
        >
          <button
            style={loginStyle}
            onClick={() => {
              setChatType("login");
              setSignUpType("false");
              logINBtn();
            }}
          >
            Login
          </button>

          <button
            style={signupStyle}
            onClick={() => {
              setChatType("signup");
              setLoginType("false");
              signUpFn();
            }}
          >
            Signup
          </button>
        </div>
      </div>
      <div
        style={{
          width: "100%",
        }}
      >
        <Scrollbars
          style={{
            width: "100%",
            height: roboDisplay ? "410px" : "445px",
          }}
        >
          <div
            className="chat-screen"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* {roboDisplay && (
              <div
                style={{
                  width: "50%",
                  position: "absolute",
                  bottom: "0",
                }}
              >
                <img
                  src="/new/chatrobo1.png"
                  alt=""
                  style={{
                    width: "100%",
                  }}
                />
              </div>
            )} */}

            {loginBtn && (
              <div
                className="loginBtns"
                style={{
                  marginTop: "10px",
                  position: "absolute",
                  bottom: "0",
                }}
              >
                <div className="message-left ">
                  <span
                    style={{
                      display: "inline-block",
                      width: "30px",
                      height: "37px",
                      marginRight: "5px",
                      backgroundImage: `url('/new/chatrob.png')`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></span>
                  <p
                    style={{
                      backgroundColor: "white",
                      padding: "10px",
                      fontSize: mobileView ? "12px" : "16px",
                      color: "black",
                      fontWeight: "400",
                    }}
                  >
                    How do you want to login?
                  </p>
                </div>
                <div
                  className="chatLogin assistant"
                  style={{
                    border: "2px solid #8D96FF",

                    cursor: "pointer",
                    padding: "6px",
                    backgroundColor: "#E4E7FF",
                    borderRadius: "9px",
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0",
                  }}
                  onClick={loginAssFn}
                >
                  <img
                    src="/new/chatrob.png"
                    alt=""
                    style={{
                      width: "9%",
                      margin: "0 5px",
                    }}
                  />
                  <span>Login with Assistant</span>
                </div>
                <div
                  className="chatLogin google"
                  style={{
                    border: "2px solid #8D96FF",

                    cursor: "pointer",
                    padding: "6px",
                    backgroundColor: "#E4E7FF",
                    borderRadius: "9px",
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0",
                  }}
                  onClick={() => {
                    login();
                  }}
                >
                  <img
                    src="/new/googleIcon.png"
                    alt=""
                    style={{
                      width: "9%",
                      margin: "0 5px",
                    }}
                  />
                  <span>Login with Google</span>
                </div>
                <div
                  className="chatLogin manually"
                  style={{
                    border: "2px solid #8D96FF",

                    cursor: "pointer",
                    padding: "6px",
                    backgroundColor: "#E4E7FF",
                    borderRadius: "9px",
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0",
                  }}
                  onClick={() => {
                    setModal1Open(() => {
                      return true;
                    });
                    let label = {
                      screenName: "Assistance Login Options",
                    };
                    gaEventTracker(
                      "Login manually Button Clicked",
                      JSON.stringify(label)
                    );
                  }}
                >
                  <img
                    src="/new/loginManualicon.png"
                    alt=""
                    style={{
                      width: "9%",
                      margin: "0 5px",
                    }}
                  />
                  <span>Login Manually</span>
                </div>
                {/* <div
                  className="chatLogin google"
                  style={{
                    border: "2px solid #8D96FF",

                    cursor: "pointer",
                    padding: "6px",
                    backgroundColor: "#E4E7FF",
                    borderRadius: "9px",
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0",
                  }}
                  onClick={() => {
                    message.error("This feature is not available yet!!");
                  }}
                >
                  <img
                    src="/new/fbIcon.png"
                    alt=""
                    style={{
                      width: "9%",
                      margin: "0 5px",
                    }}
                  />
                  <span>Login with Facebook</span>
                </div> */}
                <div
                  className="chatLogin google"
                  style={{
                    border: "2px solid #8D96FF",

                    cursor: "pointer",
                    padding: "6px",
                    backgroundColor: "#E4E7FF",
                    borderRadius: "9px",
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0",
                    display: "none",
                  }}
                  onClick={() => {
                    message.error("This feature is not available yet!!");
                  }}
                >
                  <img
                    src="/new/linkedInIcon.png"
                    alt=""
                    style={{
                      width: "9%",
                      margin: "0 5px",
                    }}
                  />
                  <span>Login with LinkedIn</span>
                </div>
              </div>
            )}
            {signUpBtn && (
              <div
                className="signUpBtn"
                style={{
                  marginTop: "10px",
                  position: "absolute",
                  bottom: "0",
                }}
              >
                <div className="message-left ">
                  <span
                    style={{
                      display: "inline-block",
                      width: "30px",
                      height: "37px",
                      marginRight: "5px",
                      backgroundImage: `url('/new/chatrob.png')`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></span>
                  <p
                    style={{
                      backgroundColor: "white",
                      padding: "10px",
                      fontSize: mobileView ? "12px" : "16px",
                      color: "black",
                      fontWeight: "400",
                    }}
                  >
                    How do you want to signup?
                  </p>
                </div>
                <div
                  className="chatLogin assistant"
                  style={{
                    border: "2px solid #8D96FF",

                    cursor: "pointer",
                    padding: "6px",
                    backgroundColor: "#E4E7FF",
                    borderRadius: "9px",
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0",
                  }}
                  onClick={signUpAssFn}
                >
                  <img
                    src="/new/chatrob.png"
                    alt=""
                    style={{
                      width: "9%",
                      margin: "0 5px",
                    }}
                  />
                  <span>SignUp with Assistant</span>
                </div>
                <div
                  className="chatLogin google"
                  style={{
                    border: "2px solid #8D96FF",

                    cursor: "pointer",
                    padding: "6px",
                    backgroundColor: "#E4E7FF",
                    borderRadius: "9px",
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0",
                  }}
                  onClick={() => {
                    login();
                    let label = {
                      screenName: "Assistance Signup Options",
                    };
                    gaEventTracker(
                      "Singup with google Button Clicked",
                      JSON.stringify(label)
                    );
                  }}
                >
                  <img
                    src="/new/googleIcon.png"
                    alt=""
                    style={{
                      width: "9%",
                      margin: "0 5px",
                    }}
                  />
                  <span>SignUp with Google</span>
                </div>
                <div
                  className="chatLogin manually"
                  style={{
                    border: "2px solid #8D96FF",

                    cursor: "pointer",
                    padding: "6px",
                    backgroundColor: "#E4E7FF",
                    borderRadius: "9px",
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0",
                  }}
                  onClick={() => {
                    history.push("/signup");
                    let label = {
                      screenName: "Assistance Signup Options",
                    };
                    gaEventTracker(
                      "Signup manually Button Clicked",
                      JSON.stringify(label)
                    );
                  }}
                >
                  <img
                    src="/new/loginManualicon.png"
                    alt=""
                    style={{
                      width: "9%",
                      margin: "0 5px",
                    }}
                  />
                  <span>SignUp Manually</span>
                </div>
                {/* <div
                  className="chatLogin google"
                  style={{
                    border: "2px solid #8D96FF",

                    cursor: "pointer",
                    padding: "6px",
                    backgroundColor: "#E4E7FF",
                    borderRadius: "9px",
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0",
                  }}
                  onClick={() => {
                    message.error("This feature is not available yet!!");
                  }}
                >
                  <img
                    src="/new/fbIcon.png"
                    alt=""
                    style={{
                      width: "9%",
                      margin: "0 5px",
                    }}
                  />
                  <span>SignUp with Facebook</span>
                </div> */}
                <div
                  className="chatLogin google"
                  style={{
                    border: "2px solid #8D96FF",

                    cursor: "pointer",
                    padding: "6px",
                    backgroundColor: "#E4E7FF",
                    borderRadius: "9px",
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0",
                    display: "none",
                  }}
                  onClick={() => {
                    message.error("This feature is not available yet!!");
                  }}
                >
                  <img
                    src="/new/linkedInIcon.png"
                    alt=""
                    style={{
                      width: "9%",
                      margin: "0 5px",
                    }}
                  />
                  <span>SignUp with LinkedIn</span>
                </div>
              </div>
            )}
            {enableChat &&
              messages.map((message, index) => {
                return (
                  <Message
                    key={index}
                    sender={message.sender}
                    text={message.text}
                    extra={message.extra}
                  />
                );
              })}
          </div>
        </Scrollbars>

        <Form onFinish={onFinish}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              width: "100%",
              alignItems: "center",
              marginBottom: "0px !important",
            }}
          >
            {!(inpType === "number") ? (
              <input
                ref={inputRef}
                type={inpType}
                style={{
                  flex: "1",
                  marginRight: "10px",
                  padding: "8px",
                  fontSize: "14px",
                  flexBasis: "90%",
                }}
                className="chatInp"
                onChange={(e) => {
                  setUserText(e.target.value);
                }}
                value={userText}
                placeholder={qusHint ? qusHint : "Type here..."}
              />
            ) : (
              <ConfigProvider
                locale={en}
                areaMapper={(area) => {
                  return {
                    ...area,
                    emoji: (
                      <img
                        alt="flag"
                        style={{
                          width: 18,
                          height: 18,
                          verticalAlign: "sub",
                        }}
                        src={getFlag(area.short)}
                      />
                    ),
                  };
                }}
              >
                <Form.Item
                  name="contactNo"
                  initialValue={{
                    short: country,
                  }}
                  onChange={(e) => {
                    setUserText(e.target.value);
                  }}
                  style={{
                    flex: "1",
                    marginRight: "10px",
                    padding: "8px",
                    fontSize: "14px",
                    flexBasis: "90%",
                    marginBottom: "0px !important",
                  }}
                  hasFeedback
                  rules={rule.contact}
                  ref={inputRef}
                >
                  <CountryPhoneInput
                    className="chatInp"
                    inputProps={{ type: "number", ref: inputRef }}
                  />
                </Form.Item>
              </ConfigProvider>
            )}

            <button
              style={{
                flex: "1 1 4%",
                cursor: "pointer",
                border: "none",
                padding: "0",
                margin: "0",
              }}
              disabled={chatBtn}
              type="submit"
            >
              <img
                src="/new/forward.png"
                alt="Button Image"
                style={{
                  width: "35px",
                  opacity: chatBtn ? "0.5" : "1",
                  cursor: chatBtn ? "not-allowed" : "pointer",
                }}
              />
            </button>
          </div>
        </Form>
        <Modal
          title=""
          centered
          visible={modal1Open}
          onOk={() => setModal1Open(false)}
          footer={null}
          onCancel={() => setModal1Open(false)}
        >
          <div
            style={{
              paddingTop: "10px",
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          >
            <h2
              style={{
                textAlign: "center",
                fontWeight: "820",
                fontSize: "42px",
              }}
            >
              LOGIN
            </h2>
            <h3
              style={{
                textAlign: "center",
                color: "#323232",
                fontWeight: "350",
                marginTop: "-10px",
              }}
            >
              Welcome Back!
            </h3>

            <div style={{ marginTop: "40px", marginBottom: "20px" }}>
              {
                <motion.div
                  initial={{ opacity: 0, marginBottom: 0 }}
                  animate={{
                    opacity: alertShow ? 1 : 0,
                    marginBottom: alertShow ? 20 : 0,
                  }}
                >
                  <Alert
                    type={alertType ? "success" : "error"}
                    showIcon
                    message={alertMessage}
                  ></Alert>
                </motion.div>
              }
              <Form layout="vertical" name="login-form" onFinish={onLogin}>
                <Form.Item
                  name="userName"
                  placeholder="Email"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please input your username",
                    },
                  ]}
                >
                  <Input bordered={{ radious: "0px" }} placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please input your password",
                    },
                  ]}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item>
                  <Text
                    type="primary"
                    htmlType="submit"
                    style={{
                      float: "right",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.push("/forgot-password");
                    }}
                  >
                    Forgot Passsword?
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "100%",
                      marginRight: "10px",
                      backgroundColor: "#8d96ff",
                      marginTop: "10px",
                      border: "none",
                    }}
                  >
                    LOGIN
                  </Button>
                </Form.Item>
                {
                  <div>
                    <div className="d-flex justify-content-center mt-4">
                      <a className="mr-2">
                        {false && "Forget / Reset Password?"}
                      </a>
                    </div>
                  </div>
                }
              </Form>
            </div>
          </div>
          <div
            style={{
              background: "#F5F8FF",
              marginLeft: "-24px",
              marginBottom: "-24px",
              marginRight: "-24px",
              paddingBottom: "20px",
              paddingTop: "20px",
              textAlign: "center",
              borderBottomRightRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}
          >
            DON'T HAVE AN ACCOUNT ?{" "}
            <a
              onClick={() => {
                setModal1Open(false);
                history.push("/signup");
              }}
              style={{
                color: "#8d96ff",
              }}
            >
              SIGN UP
            </a>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ChatBot;
