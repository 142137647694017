import React, { useEffect, useState, useMemo } from "react";
import "./css/Popus.css";
import { Carousel, Spin } from "antd";
import fetch_ from "interceptors/FetchInterceptor";
import { CloseCircleFilled, LoadingOutlined } from "@ant-design/icons";
import PayPalSubscription from "./PayPalSubscription";
import "./css/mobileDesign.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PaddleIntegration from "./PaddleIntegration";
import countryList from "../../constants/country-code.json";
import { initializePaddle } from "@paddle/paddle-js";

const SubscriptionMobileDesing = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const [paddle, setPaddle] = useState();

  const [carouselKey, setCarouselKey] = useState(0);
  const [popupKey, setPopUpKey] = useState(0);
  const [fontColor, setFontColor] = useState("white");
  const [btnColor, setBtnColor] = useState("#FEC12D");
  const [priceContBorderClr, setPriceContBorderClr] =
    useState("1px solid #8D96FF");
  const [activeBox, setActiveBox] = useState(null);
  const [border, setBorder] = useState(false);

  const [yearlyPlan, setYearlyPlan] = useState({});
  const [quarterlyPlan, setQuarterlyPlan] = useState({});
  const [monthlyPlan, setMonthlyPlan] = useState({});

  const [productId, setProductId] = useState("");
  const [paddlePriceId, setPaddlePriceId] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [activateBtnLoader, setActivateBtnLoader] = useState(false);

  const [userObj, setUserObj] = useState({
    email: "",
    country: "",
    paddleUserId: "",
    userId: "",
  });
  //console.log(isOpen)

  const togglePopup = async (redirectValue) => {
    if (redirectValue) {
      setIsOpen(false);
      await fetchData();
      setIsOpen(true);

      setTimeout(() => {
        setIsOpen(false);
      }, 2000);
    } else {
      history.push("/signup");
    }
  };

  const boxStyle = (boxNumber) => ({
    border: activeBox === boxNumber ? "1px solid #8D96FF" : "none",
    boxShadow:
      activeBox === boxNumber ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
    cursor: "pointer",
  });

  const [popUpBgColor, setPopUpBgColor] = useState({
    borderRadius: "24px",
    background: "linear-gradient(180deg, #8D96FF 0%, #D5D8FF 90.2%)",
  });
  const changeBgColor = (e) => {
    setPopUpBgColor({
      borderRadius: "24px",
      background: "linear-gradient(180deg, #D5D8FF 0%, #FFF 100%)",
    });
    setPopUpKey((prevKey) => prevKey + 1);
    setFontColor("black");
    setBtnColor("#666DD3");
    setBorder(true);
    // console.log(e);
  };
  const changeMainBgColor = () => {
    setPopUpBgColor({
      borderRadius: "24px",
      background: "linear-gradient(180deg, #8D96FF 0%, #D5D8FF 90.2%)",
    });
    setPopUpKey((prevKey) => prevKey + 1);
    setFontColor("white");
    setBtnColor("#FEC12D");
  };

  const fetchData = useMemo(() => {
    const fetchDataMemoized = async () => {
      try {
        setActivateBtnLoader(true);
        const [
          // data,
          // profileData,
          newApi,
        ] = await Promise.all([
          // fetch_("/register-applicants/form-fields"),
          // fetch_("/register-applicants/profile"),
          fetch_("/register-applicants/profile/minimal"),
        ]);
        let countryName = newApi?.data?.country;
        // let countryName = countryObj[0].options[countryNumber];

        let countryCode = countryList.find((e) => {
          return e.country === countryName;
        })?.code;

        const defaultValues = {
          id: newApi?.data?.id,
          // id: userInfo?.id,
          firstName: newApi?.data?.firstName,
          lastName: newApi?.data?.lastName,
          email: newApi?.data?.email,
          country: countryName,
          countryCode: countryCode,
          paddleUserId: newApi?.data?.paddleUserId,
          paddleAddressId: newApi?.data?.paddleAddressId,
          zipCode: newApi?.data?.zipcode,
          // zipCode: userInfo?.applicantFields?.filter(
          //   (e) => e.fieldId === zipCodeId[0]?.id
          // )[0]?.fieldValue,
        };

        setUserObj({
          email: defaultValues.email,
          country: defaultValues.country,
          paddleUserId: defaultValues.paddleUserId,
          userId: defaultValues.id,
          paddleAddressId: defaultValues.paddleAddressId,
          zipCode: defaultValues.zipCode,
          countryCode: countryCode,
        });
        setActivateBtnLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setActivateBtnLoader(false);
      } finally {
        setActivateBtnLoader(false);
      }
    };

    return fetchDataMemoized;
  }, []);

  const loadData = async () => {
    try {
      //await fetchData();

      setCarouselKey((prevKey) => prevKey + 1);
      let data = await fetch_("/subscription-plans", {
        method: "GET",
      });

      let monthlyPrice, quarterlyPrice, yearlyPrice;
      data.data.forEach((plan) => {
        const { title, price, productId, description, paddlePriceId } = plan;

        const parsedPrice = parseFloat(price.substring(1));
        if (title === "Yearly Plan") {
          yearlyPrice = parsedPrice;
          setYearlyPlan({
            price,
            title,
            productId,
            description,
            paddlePriceId,
          });
        } else if (title === "Quarterly Plan") {
          quarterlyPrice = parsedPrice;

          setQuarterlyPlan({
            price,
            title,
            productId,
            description,
            paddlePriceId,
          });
          setPaddlePriceId(paddlePriceId);
          setProductId(productId);
          setPrice(price);
        } else if (title === "Monthly Plan") {
          monthlyPrice = parsedPrice;
          setMonthlyPlan({
            price,
            title,
            productId,
            description,
            paddlePriceId,
          });
        }
        if (monthlyPrice && quarterlyPrice && yearlyPrice) {
          // Calculate total prices for a year
          const totalMonthly = monthlyPrice * 12;
          const totalQuarterly = quarterlyPrice * 4 * 3; // Quarterly x 4 quarters
          const totalYearly = yearlyPrice * 12;

          // Calculate savings
          const savingsQuarterly = totalMonthly - totalQuarterly;
          const savingsYearly = totalMonthly - totalYearly;

          // Update state with calculated discounts
          setYearlyPlan((prevState) => ({
            ...prevState,
            savings: savingsYearly.toFixed(2),
          }));
          setQuarterlyPlan((prevState) => ({
            ...prevState,
            savings: savingsQuarterly.toFixed(2),
          }));
        }
      });
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };
  useEffect(async () => {
    let isMounted = true;
    if (isMounted) {
      loadData();
      // await fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const initializeAndOpenCheckout = async () => {
      try {
        const paddleInstance = await initializePaddle({
          environment: "sandbox",
          token: "test_5542685d1fea3460323c1a07221",
          //token: "live_2acf6093a3b4baf59f5ac9e1bbb",
          eventCallback: (data) => {
            // console.log(data);
            if (data.name === "checkout.completed") {
              // closeCheckout();
              localStorage.setItem(
                "addressId",
                data?.data?.customer?.address?.id
              );
              localStorage.setItem("isActiveSubscription", true);
              // history.push("/auth/dashboard/success");
              if (window.trackdesk) {
                window.trackdesk("lennyai", "conversion", {
                  conversionType: "sale",
                  amount: {
                    value: price?.substring(1),
                  },
                  customParams: {
                    advS1: "Trial successful",
                  },
                });
              }
            }
          },
        });
        if (paddleInstance) {
          setPaddle(paddleInstance);
          // setLoading(false);
        }
        // console.log(paddleInstance)
      } catch (error) {
        console.error("Error initializing Paddle:", error);
      }
    };

    initializeAndOpenCheckout();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "white",
            fontFamily: "Poppins",
            margin: "5px",
            display: "inline",
          }}
        >
          {" "}
          Please wait...
          <Spin
            size="small"
            style={{ marginLeft: "5px" }}
            className="custom-spinner"
          />
        </p>
      </div>
    );
  }
  return (
    <div
      className="popup-content set-width"
      style={popUpBgColor}
      key={popupKey}
    >
      <div className="mobileSubscriptionContainer">
        <h1
          style={{
            fontSize: "20px",
            fontWeight: "700",
            color: `${fontColor}`,
          }}
        >
          Membership Options
        </h1>

        <p
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: `${fontColor}`,
          }}
        >
          AI powered Application Engine. Lenny does it for you
        </p>

        <p
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: `${fontColor}`,
          }}
        >
          7 Days free, cancel anytime
        </p>
        <hr
          style={{
            margin: "5px 0",
            height: "1px",
            border: "none",
            background:
              "-webkit-gradient(linear,0 0,100% 0,from(transparent),to(transparent),color-stop(50%, #e1dfdf))",
            width: "50%",
          }}
        />

        <Carousel
          effect="fade"
          key={carouselKey}
          className="subscription_caraousel"
          autoplay
          dotPosition="bottom"
          pauseOnHover={true}
          pauseOnDotsHover={true}
        >
          {monthlyPlan && monthlyPlan.description
            ? monthlyPlan.description.split(",").flatMap((item, index) => [
                <div>
                  {" "}
                  <p
                    key={index}
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: `${fontColor}`,
                    }}
                  >
                    {item}
                  </p>
                </div>,
              ])
            : null}
        </Carousel>
        <div className="priceContainer">
          <div
            className="priceContainerBox1 mobileContainer"
            onClick={() => {
              changeBgColor();
              setActiveBox(1);
              setPrice(monthlyPlan.price);
              setProductId(monthlyPlan.productId);
              setPaddlePriceId(monthlyPlan.paddlePriceId);
              setDescription(monthlyPlan.description);
            }}
            style={boxStyle(1)}
          >
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              <h2
                style={{
                  color: "black",
                  fontFamily: "DM Sans",
                  fontSize: "32px",
                  fontWeight: "700",
                  lineHeight: "20px",
                  margin: "0",
                }}
              >
                ${" "}
                {monthlyPlan.price
                  ? monthlyPlan.price.substring(1)
                  : monthlyPlan.price}
              </h2>
              <p
                style={{
                  color: "black",
                  margin: "0px ",
                }}
              >
                per month
              </p>
            </div>
            <p
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "normal",
              }}
            >
              Monthly Plan
            </p>
          </div>
          <div
            className="priceContainerBox2 "
            onClick={() => {
              changeMainBgColor();
              setActiveBox(2);
              setPrice(quarterlyPlan.price);
              setProductId(quarterlyPlan.productId);
              setPaddlePriceId(quarterlyPlan.paddlePriceId);
              setDescription(quarterlyPlan.description);
            }}
            style={boxStyle(2)}
          >
            <p
              style={{
                backgroundColor: "red",
                // width: '140%',
                borderRadius: "12px 12px 0px 0px",
                background: "#666DD3",
                position: "relative",
                top: "-21px",
                color: "#FFF",
                padding: "5px",
                fontFamily: "DM Sans",
                fontStyle: "normal",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              Most Popular
            </p>
            <div
              style={{
                // padding: '0px 30px',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  marginBottom: "10px",
                }}
              >
                <h2
                  style={{
                    color: "black",
                    fontFamily: "DM Sans",
                    fontSize: "32px",
                    fontWeight: "700",
                    lineHeight: "20px",
                    margin: "0",
                  }}
                >
                  ${" "}
                  {quarterlyPlan.price
                    ? quarterlyPlan.price.substring(1)
                    : quarterlyPlan.price}
                </h2>
                <p
                  style={{
                    color: "black",
                    margin: "0px ",
                  }}
                >
                  per month
                </p>
              </div>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "normal",
                  color: "black",
                }}
              >
                Quarterly Plan
              </p>
              <div
                style={{
                  fontFamily: "Poppins",
                  fontSize: "6px",
                  fontWeight: "500",
                  lineHeight: "normal",
                  color: "black",
                  padding: "5px",
                }}
              >
                <p>Savings: ${quarterlyPlan.savings} annually!</p>
              </div>
            </div>
          </div>
          <div
            className="priceContainerBox1 mobileContainer"
            onClick={() => {
              changeBgColor();
              setActiveBox(3);
              setPrice(yearlyPlan.price);
              setProductId(yearlyPlan.productId);
              setPaddlePriceId(yearlyPlan.paddlePriceId);
              setDescription(yearlyPlan.description);
            }}
            style={boxStyle(3)}
          >
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              <h2
                style={{
                  color: "black",
                  fontFamily: "DM Sans",
                  fontSize: "32px",
                  fontWeight: "700",
                  lineHeight: "20px",
                  margin: "0",
                }}
              >
                ${" "}
                {yearlyPlan.price
                  ? yearlyPlan.price.substring(1)
                  : yearlyPlan.price}
              </h2>
              <p
                style={{
                  color: "black",
                  margin: "0px ",
                }}
              >
                per month
              </p>
            </div>
            <p
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "normal",
                color: "black",
              }}
            >
              Yearly Plan
            </p>
            <p
              style={{
                fontFamily: "Poppins",
                fontSize: "6px",
                fontWeight: "500",
                lineHeight: "normal",
                color: "black",
              }}
            >
              Savings: ${yearlyPlan.savings} annually!
            </p>
          </div>
        </div>
        <button
          style={{
            borderRadius: "34px",
            background: `${btnColor}`,
            marginTop: "35px",
            lineHeight: "normal",
            color: "black",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "normal",
            cursor: "pointer",
            width: "90%",
          }}
          onClick={() => {
            togglePopup(props.redirect);
          }}
        >
          Activate 7 Days
          {activateBtnLoader ? (
            <LoadingOutlined style={{ marginRight: 10 }} />
          ) : null}
        </button>
        <p
          style={{
            color: "#646464",
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "20px",
            marginTop: "10px",
            cursor: "pointer",
            display: props.noThanksDisplay ? "none" : "block",
            // display: location.pathname.includes('/subscription-plan') ? 'none' : 'block'
          }}
          onClick={() => {
            props.toggleMobilePopup();
          }}
        >
          No Thanks
        </p>
      </div>
      {isOpen && (
        // <div className="popup-overlay">
        //   <div
        //     className="popup-content"
        //     style={{
        //       minWidth: "30%",
        //       padding: "35px",
        //     }}
        //   >
        //     <div
        //       onClick={togglePopup}
        //       style={{
        //         float: "right",
        //         lineHeight: "normal",
        //         cursor: "pointer",
        //       }}
        //     >
        //       {" "}
        //       <CloseCircleFilled />
        //     </div>
        //     <h3>Choose Payment Method</h3>
        //     {/* <PayPalSubscription productId={productId} /> */}
        //     <PaddleIntegration paddlePriceId={productId} />
        //   </div>
        // </div>
        <PaddleIntegration
          paddlePriceId={paddlePriceId}
          productId={productId}
          togglePopup={togglePopup}
          userDetail={userObj}
          price={price}
          paddle={paddle}
        />
      )}
    </div>
  );
};

export default SubscriptionMobileDesing;
