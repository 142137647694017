import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import useAxios from "axios-hooks";
import { motion } from "framer-motion";
import { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SelectDatepicker } from "react-select-datepicker";
import moment from "moment";
import { showAuthMessage, showLoading } from "redux/actions/Auth";
import { UserAuthService } from "services/user-auth.service";
import useAnalyticsEventTracker from "../../../../useAnalyticsEventTracker";
import "../register.scss";
import rules from "../rules.js";

const BasicDetail = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [{ data: userInfo, loading: profileLoading }] = useAxios(
    { url: `register-applicants/profile` },
    { useCache: false, manual: false }
  );
  const userInfoString = localStorage.getItem("userInfo");
  const gaEventTracker = useAnalyticsEventTracker("Profile");
  const [disableButton, setDisableButton] = useState(false);
  // let userInfo = {};
  // if (userInfoString) {
  //   try {
  //     userInfo = JSON.parse(userInfoString);
  //   } catch (err) {
  //     console.error("Error parsing userInfo:", err);
  //   }
  // }

  const [alertShow, setAlertShow] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [alertType, setAlertType] = useState(true);
  //const formValues = JSON.parse(localStorage.getItem('register3'));
  const mobileView = window.innerWidth < 1200;
  const [{ data = [], loading }] = useAxios(`/register-applicants/form-fields`);

  const citizenship = data?.filter((e) => e.key === "citizenship")[0]?.options;
  const ethnicity = data?.filter((e) => e.key === "ethnicity")[0]?.options;
  const country = data?.filter((e) => e.key === "country")[0]?.options;
  const countryId = data?.filter((e) => e.key === "country");
  const militaryAffiliation = data?.filter(
    (e) => e.key === "militaryAffiliation"
  )[0]?.options;
  const citizenshipId = data?.filter((e) => e.key === "citizenship");
  const ethnicityId = data?.filter((e) => e.key === "ethnicity");
  const militaryAffiliationId = data?.filter(
    (e) => e.key === "militaryAffiliation"
  );
  const genderId = data?.filter((e) => e.key === "gender");
  const zipCodeId = data?.filter((e) => e.key === "zipCode");

  const [dob, setDob] = useState(null);

  useEffect(() => {
    if (dob === null || dob == "Invalid Date") {
      setDob(new Date(userInfo?.dob));
    }
  }, [userInfo]);

  function getAge(DOB) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const dateRule = {
    dob: [
      {
        required: true,
        message: "Please select your date of birth!",
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (dob === null && getFieldValue("dob") > 0) {
            return Promise.reject("Please select your date of birth!");
          }
          return Promise.resolve();
        },
      }),
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (dob === null && getFieldValue("dob") > 0) {
            return Promise.reject("Please select your date of birth!");
          } else if (getAge(getFieldValue("dob")) < 16) {
            return Promise.reject("User should not be less than 16 year!");
          }
          return Promise.resolve();
        },
      }),
    ],
  };

  const onSignUp = () => {
    setDisableButton(true);
    let label = {
      screenName: "Profile Basic Detial",
    };
    gaEventTracker("User Update clicked", JSON.stringify(label));
    form
      .validateFields()
      .then((values) => {
        let prefilledData = [];
        userInfo?.applicantFields.map((e) => {
          if (
            e.field.key === "ethnicity" ||
            e.field.key === "citizenship" ||
            e.field.key === "gender" ||
            e.field.key === "country" ||
            e.field.key === "zipCode"
          ) {
          } else {
            prefilledData = [
              ...prefilledData,
              { fieldId: e.fieldId, fieldValue: e.fieldValue },
            ];
          }
        });
        let postData = {
          firstName: values.firstName ?? "",
          lastName: values.lastName ?? "",
          email: userInfo?.email ?? "",
          // phone: userInfo?.phone ?? "",
          dob: moment(dob).format("YYYY-MM-DD") ?? "",
          applicantType: userInfo?.applicantType ?? "",
          applicantFields: [
            ...prefilledData,
            {
              fieldId: citizenshipId[0]?.id ?? "",
              fieldValue: values.citizenship ?? "",
            },
            {
              fieldId: ethnicityId[0]?.id ?? "",
              fieldValue: values.ethnicity ?? "",
            },
            {
              fieldId: countryId[0]?.id ?? "",
              fieldValue: values.country ?? "",
            },
            {
              fieldId: genderId[0]?.id ?? "",
              fieldValue: values.gender + "" ?? "",
            },
            {
              fieldId: zipCodeId[0]?.id ?? "",
              fieldValue: values.zipCode ?? "",
            },
            // { fieldId: addressId[0]?.id ?? "", fieldValue: values.address ?? "" },
            // { fieldId: cityId[0]?.id ?? "", fieldValue: values.city ?? "" },
            // { fieldId: stateId[0]?.id ?? "", fieldValue: values.state ?? "" },
          ],
        };

        UserAuthService.updateUserApplicant(userInfo?.id, postData)
          .then((data) => {
            if (data.statusCode == 200) {
              localStorage.setItem(
                "userInfo",
                typeof data.data.applicant === "string"
                  ? data.data.applicant
                  : JSON.stringify(data.data.applicant)
              );
            }
            setAlertShow(true);
            setAlertType(true);
            setAlertMessage("User updated successfully");
            showAuthMessage("Your account has been successfully registered.");
          })
          .catch((info) => {
            setAlertShow(true);
            setAlertMessage(info.description || "Server Error");
            setAlertType(false);
            // console.log("Validate Failed:", info);
          })
          .finally(() => {
            showLoading(false);
            setDisableButton(false);
          });
      })
      .catch((info) => {
        setDisableButton(false);
      });
  };

  const onDateChange = useCallback((dob) => {
    if (!(dob === null || dob == "Invalid Date")) {
      setDob(dob);
    }
  }, []);

  const registerStyle = {
    r3Column1: {
      width: mobileView ? "80%" : "40%",
      paddingLeft: mobileView ? "" : "60px",
      // marginTop: "15px",
      margin: "15px 0 0 35px",
    },
    label: {
      marginLeft: "4px",
      fontSize: "14px",
      fontFamily: "Roboto",
      lineHeight: "1",
      fontWeight: "500",
      marginLeft: "4px",
    },
  };

  const defaultValues = {
    firstName: userInfo?.firstName,
    lastName: userInfo?.lastName,
    dob: dob,
    gender:
      userInfo?.applicantFields?.filter((e) => e.fieldId === genderId[0]?.id)[0]
        ?.fieldValue * 1,
    citizenship: userInfo?.applicantFields?.filter(
      (e) => e.fieldId === citizenshipId[0]?.id
    )[0]?.fieldValue,
    ethnicity: userInfo?.applicantFields?.filter(
      (e) => e.fieldId === ethnicityId[0]?.id
    )[0]?.fieldValue,
    country: userInfo?.applicantFields?.filter(
      (e) => e.fieldId === countryId[0]?.id
    )[0]?.fieldValue,
    zipCode: userInfo?.applicantFields?.filter(
      (e) => e.fieldId === zipCodeId[0]?.id
    )[0]?.fieldValue,
  };

  // console.log(defaultValues, userInfo);

  if (loading || profileLoading)
    return (
      <div
        style={{
          position: "absolute",
          left: mobileView ? window.innerWidth / 2 : "520px",
          top: "241px",
        }}
      >
        <Spin size={"large"} />
      </div>
    );

  return (
    <div>
      {
        <div>
          <motion.div
            initial={{ opacity: 0, marginBottom: 0 }}
            animate={{
              opacity: alertShow ? 1 : 0,
              marginBottom: alertShow ? 20 : 0,
            }}
            style={{ padding: mobileView ? "0" : "0 25%" }}
          >
            <Alert
              type={alertType ? "success" : "error"}
              showIcon
              message={alertMessage}
            ></Alert>
          </motion.div>
        </div>
      }
      <Form
        form={form}
        style={{}}
        layout="vertical"
        initialValues={defaultValues}
        name="register-form-step3"
        onFinish={onSignUp}
      >
        <Row>
          <Col style={registerStyle.r3Column1}>
            <Form.Item name="firstName" label="First Name" hasFeedback>
              <Input
                placeholder=" First Name"
                style={{
                  height: "40px",
                  borderRadius: "5px",
                  color: "#7D7B7D",
                }}
              />
            </Form.Item>
          </Col>

          <Col style={registerStyle.r3Column1}>
            <Form.Item name="lastName" label="Last Name" hasFeedback>
              <Input
                placeholder=" Last Name"
                style={{
                  height: "40px",
                  borderRadius: "5px",
                  color: "#7D7B7D",
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}>Your birthday</h2>
            <Form.Item
              name="dob"
              rules={dateRule.dob}
              style={{ color: "#7D7B7D" }}
            >
              <SelectDatepicker
                selectedDate={dob}
                maxDate={
                  new Date(new Date().setFullYear(new Date().getFullYear()))
                }
                labels={{
                  dayPlaceholder: "Day",
                  monthPlaceholder: "Month",
                  yearPlaceholder: "year",
                }}
                order="day/month/year"
                hideLabels={true}
                onDateChange={onDateChange}
              />
            </Form.Item>
          </Col>

          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}>Citizenship</h2>
            <Form.Item name="citizenship" rules={rules.citizenship}>
              <Select
                size="large"
                style={{ color: "#7D7B7D" }}
                mode="single"
                placeholder={"Citizenship"}
              >
                {!loading &&
                  citizenship &&
                  Object.keys(citizenship).map((option) => (
                    <Select.Option value={option} key={option}>
                      {citizenship[option]}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}>Zip Code</h2>
            <Form.Item name="zipCode" rules={rules.zipCode}>
              <Input
                placeholder="Zip Code"
                style={{
                  height: "40px",
                  borderRadius: "5px",
                  color: "#7D7B7D",
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}>Ethnicity</h2>
            <Form.Item name="ethnicity" rules={rules.ethnicity}>
              <Select
                size="large"
                style={{ color: "#7D7B7D" }}
                mode="single"
                placeholder={"Ethnicity"}
              >
                {!loading &&
                  ethnicity &&
                  Object.keys(ethnicity).map((option) => (
                    <Select.Option value={option} key={option}>
                      {ethnicity[option]}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}> Gender</h2>
            <Form.Item name="gender">
              <Radio.Group>
                <Radio value={1}>Female</Radio>
                <Radio value={2}>Male</Radio>
                <Radio value={3}>Other</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}>Country</h2>
            <Form.Item name="country" rules={rules.country}>
              <Select
                size="large"
                style={{ color: "#7D7B7D" }}
                mode="single"
                placeholder={"Country"}
              >
                {!loading &&
                  country &&
                  Object.keys(country).map((option) => (
                    <Select.Option value={option} key={option}>
                      {country[option]}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col style={registerStyle.r3Column1}></Col>
        </Row>

        <Form.Item style={{ textAlignLast: "center" }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "160px",
              backgroundColor: "#8D96FF",
              color: "#FFFFFF",
              border: "none",
              borderRadius: "6px",
              marginBottom: "15px",
            }}
            block
            disabled={disableButton}
          >
            <span>Save Changes</span>
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BasicDetail;
