import { useHistory } from "react-router-dom";
import React, { useState, useCallback } from "react";
import { Row, Col, Button, Form, Input, Modal, Typography } from "antd";
import fetch_ from "../../../../interceptors/FetchInterceptor";
import Footer from "../footer.js";
import Subscription_modal from "components/layout-components/Subscription";

const { Title, Paragraph } = Typography;

const Contact = () => {
  const [selected, setSelected] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const mobileView = window.innerWidth < 481;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const rules = {
    email: [
      {
        required: true,
        message: "Please input your email address!",
      },
      {
        type: "email",
        message: "Please enter a validate email!",
      },
    ],
    phone: [
      {
        required: true,
        message: "Please input your phone",
      },
    ],
    description: [
      {
        required: true,
        message: "Please input your message",
      },
    ],
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        let postData = {
          email: values.email,
          name: "Guest",
          phone: values.phone,
          description: values.description,
          //applicantId: userInfo.id,
        };
        fetch_({
          url: `/scholarships/add-support`,
          method: "POST",
          data: postData,
        })
          .then((data) => {
            form.resetFields();
            setModalVisible(true);
          })
          .catch((err) => {
            console.log("info", err);
          })
          .finally(() => {});
      })
      .catch((info) => {});
  };

  const formView = () => {
    return (
      <div
        style={{
          background: "#ffffff",
          padding: mobileView ? "10px" : "40px",
          paddingRight: !mobileView && "80px",
          //width: mobileView && window.innerWidth,
        }}
      >
        <Modal
          visible={modalVisible}
          destroyOnClose={true}
          closable={false}
          footer={null}
          width="300px"
          bodyStyle={{
            padding: "20px",
            borderRadius: "10px",
            height: "250px",
          }}
        >
          <div style={{ float: "right" }}>
            <img
              onClick={() => {
                setModalVisible(false);
              }}
              src="/applicantImg/Close.png"
              style={{ height: "18px", marginRight: "4px" }}
            />
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            <img
              src="/applicantImg/thankYou.png"
              style={{ height: "80px", marginRight: "4px" }}
            />
            <h2
              style={{
                marginTop: "10px",
                fontFamily: "'Poppins'",
              }}
            >
              Thank You!
            </h2>
            <div>
              We have received your message, we will get back to you soon.
            </div>
          </div>
        </Modal>

        <Form
          form={form}
          layout="vertical"
          name="register-form-step1"
          initialValues={{
            name: "Guest",
          }}
          onFinish={onSubmit}
          style={{
            width: !mobileView && "auto",
          }}
        >
          <Form.Item name="name" label="Name" rules={rules.name} hasFeedback>
            <Input
              disabled={true}
              placeholder="Name"
              style={{ height: "40px", borderRadius: "5px" }}
            />
          </Form.Item>
          <Row>
            <Col style={{ width: "50%", paddingRight: "10px" }}>
              <Form.Item
                name="email"
                label="Email"
                rules={rules.email}
                hasFeedback
              >
                <Input
                  placeholder="Email"
                  style={{ height: "40px", borderRadius: "5px" }}
                />
              </Form.Item>
            </Col>
            <Col style={{ width: "50%", paddingLeft: "10px" }}>
              <Form.Item
                name="phone"
                label="Phone"
                rules={rules.phone}
                hasFeedback
              >
                <Input
                  placeholder="Phone"
                  style={{ height: "40px", borderRadius: "5px" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="description"
            label="Messgae"
            rules={rules.description}
            hasFeedback
          >
            <TextArea
              placeholder="Write your message..."
              style={{ borderRadius: "5px" }}
            />
          </Form.Item>
          <Form.Item style={{}}>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                background: "rgb(141, 150, 255)",
                height: "auto",
                border: "none",
                width: "auto",
                borderRadius: "10px",
                fontSize: "18px",
                padding: "10px",
                fontWeight: "500",
                letterSpacing: "0.5px",
              }}
            >
              {/* <span style={{ fontSize: "20px", fontWeight: "600" }}> */}
              Send Message
              {/* </span> */}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#F9FAFC",
          padding: mobileView ? "15px" : "40px",
        }}
      >
        <Row style={{ backgroundColor: "#FFFFFF" }}>
          <Col
            style={{
              width: mobileView ? "100%" : "50%",
              padding: mobileView ? "10px" : "40px",
            }}
          >
            <h2
              style={{
                letterSpacing: "-0.02em",
                // fontWeight: '700',
                fontSize: "33px",
                fontFamily: "'Poppins'",
              }}
            >
              Lenny.ai wants to hear from you!
            </h2>
            <div
              style={{
                maxWidth: "450px",
                fontWeight: "600",
                fontSize: "18px",
                marginTop: "40px",
              }}
            >
              <div>
                <Title level={4}>Address:</Title>
                <Paragraph
                  style={{
                    margin: 5,
                    fontFamily: "'Poppins'",
                    fontWeight: 400,
                  }}
                >
                  Better Online Ltd
                </Paragraph>
                <Paragraph
                  style={{
                    margin: 5,
                    fontFamily: "'Poppins'",
                    fontWeight: 400,
                  }}
                >
                  75 Shalva Street
                </Paragraph>
                <Paragraph
                  style={{
                    margin: 5,
                    fontFamily: "'Poppins'",
                    fontWeight: 400,
                  }}
                >
                  Herzliya 46662
                </Paragraph>
                <Paragraph
                  style={{
                    margin: 5,
                    fontFamily: "'Poppins'",
                    fontWeight: 400,
                  }}
                >
                  Israel
                </Paragraph>
              </div>
              <div
                style={{
                  paddingTop: "20px",
                  fontFamily: "'Poppins'",
                  fontWeight: 400,
                }}
              >
                If you have a comment, question, or issue related to our
                services, fill out the form on this page to reach our customer
                service team. We typically respond to emails within 24 hours
              </div>
              <div
                style={{
                  paddingTop: "20px",
                  fontFamily: "'Poppins'",
                  fontWeight: 400,
                }}
              >
                You can also call us toll-free at 00972 99561694. We are
                available Monday through Friday, 11 a.m. to 6 p.m. EST. Address
                Better Online Ltd Shalva street 75 Herzliya 46662 Israel
              </div>
            </div>
          </Col>
          <Col
            style={{ width: mobileView ? "100%" : "50%", marginTop: "20px" }}
          >
            {formView()}
          </Col>
        </Row>
      </div>
      {/* <Subscription_modal /> */}
      <Footer />
    </>
  );
};

export default Contact;
