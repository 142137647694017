import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  Upload,
} from "antd";
import { API_UPLOAD_URL } from "configs/AppConfig";
import React from "react";
import TextEditor from "./TextEditor";

const Requirement = ({ details, formRef, scholarshipId }) => {
  const { field, title, description, optional } = details;

  const renderField = ({ key, name, dataType, optional }) => {
    // console.log(optional);
    switch (dataType) {
      case "file":
        return (
          <Form.Item
            rules={[
              {
                required: optional,
                message: "Please upload!",
              },
            ]}
            name={key}
            getValueFromEvent={(val) => {
              return val?.file?.response?.data?.publicUrl;
            }}
            showUploadList={{
              showRemoveIcon: false,
            }}
          >
            <Upload
              action={API_UPLOAD_URL}
              accept=".doc,.docx,.pdf"
              maxCount={1}
            >
              <Button
                type="primary"
                style={{
                  backgroundColor: "rgb(141, 150, 255)",
                  border: "none",
                }}
              >
                Upload {name}
              </Button>
            </Upload>
          </Form.Item>
        );

      case "link":
        return (
          <Form.Item
            rules={[
              {
                required: optional,
                message: "Please provide!",
              },
            ]}
            name={key}
          >
            <Input placeholder={name} />
          </Form.Item>
        );

      case "checkbox":
        return (
          <Form.Item
            rules={[
              {
                required: optional,
                message: "Please provide!",
              },
            ]}
            name={key}
            valuePropName="checked"
          >
            <Checkbox>{title}</Checkbox>
          </Form.Item>
        );

      case "text":
      case "editor":
        return (
          <Form.Item
            rules={[
              {
                required: optional,
                validator: (rule, value) => {
                  if (!value) {
                    return Promise.reject("Please add essay!");
                  }
                  return Promise.resolve();
                },
                validateTrigger: "onsubmit",
              },
            ]}
            name={key}
            getValueFromEvent={(val) => {
              if (val?.essay)
                return {
                  text: val?.essay,
                };
              return {
                url: val?.file?.response?.data,
              };
            }}
          >
            <TextEditor scholarshipId={scholarshipId} details={details} />
          </Form.Item>
        );

      default:
        return null;
    }
  };

  return (
    <Row>
      <Col span={12}>
        <h3 style={{ fontWeight: "600" }}>
          {title}{" "}
          {!optional ? "(optional)" : <span style={{ color: "red" }}>*</span>}
        </h3>
        <Typography.Paragraph>{description}</Typography.Paragraph>
        {renderField({
          key: details?.field?.key,
          name: details?.field?.name,
          dataType: details?.field?.dataType,
          optional,
          title,
        })}
      </Col>
    </Row>
  );
};

export default Requirement;
