import { Button, Drawer, Dropdown, Menu, Space, Steps } from "antd";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useHistory } from "react-router-dom";
import LeftMenu from "./LeftMenu";
import "./Navbar.css";
import { RightMenu } from "./RightMenu.js";

const Navbar = ({ props }) => {
  const { Step } = Steps;
  const [visible, setVisible] = useState(false);
  const mobileView = window.innerWidth < 961;
  const history = useHistory();
  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }
  const fullName = userInfo.firstName?.toUpperCase() + " " + userInfo.lastName;

  const stepper = ["/signup", "/auth/userdetails"].includes(props.pathname);

  const login = ["/auth/apply", "/auth/profile", "/auth/dashboard"].includes(
    props.pathname
  );

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  //const background = login ? "#ffffff" : "#E6F3F9";
  const background = login ? "#ffffff" : "#ffffff";
  const stepperView = ({ current }) => {
    return <div />;
    return (
      <div
        style={{
          margin: mobileView ? "20px 0" : "20px 20px 0px 20px",
          padding: mobileView ? "0 10px" : "0",
          width: mobileView ? "100%" : "80%",
          float: "right",
        }}
      >
        <Steps
          current={current}
          style={{ width: "auto" }}
          size="small"
          responsive={false}
        >
          {mobileView ? (
            <>
              <Step title="Scholarship " description="Eligibility" />
              <Step title="Signup " description="Details" />
              <Step title="Additional " description="Details" />
            </>
          ) : (
            <>
              <Step title="Scholarship Eligibility" />
              <Step title="Signup Details" />
              <Step title="Additional Details" />
            </>
          )}
        </Steps>
      </div>
    );
  };

  const logout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("isActiveSubscription");
    localStorage.removeItem("profileData");
    localStorage.removeItem("zipCodeId");
    localStorage.removeItem("countryObj");
    localStorage.removeItem("formData");
    localStorage.removeItem("dates");

    history.push("/");
    window.location.reload();
  };

  const gotoDashboard = () => {
    history.push("/auth/dashboard");
    window.location.reload();
  };

  const profileMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          history.push("/auth/profile");
        }}
      >
        My Profile
      </Menu.Item>
      <Menu.Item onClick={logout}>Logout</Menu.Item>
    </Menu>
  );

  const loginNavView = () => {
    return (
      <div
        style={{ marginTop: mobileView ? "20px" : "15px", textAlign: "right" }}
      >
        {false && (
          <Button
            type="primary"
            onClick={gotoDashboard}
            style={{
              background: "#F1F6FE",
              borderRadius: "6px",
              borderColor: "#F1F6FE",
              height: "32px",
              paddingTop: "5px",
              width: "150px",
              margin: "0 5%",
            }}
          >
            <span style={{ color: "#1778F2" }}>Go to Dashboard </span>
          </Button>
        )}
        <span
          style={{
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <img
            src="/applicantImg/notification.png"
            style={{ height: "25px" }}
          />
        </span>

        <span
          style={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "17px",
            letterSpacing: "0em",
          }}
        >
          {!mobileView && fullName}
        </span>

        <span
          style={{
            width: "fit-content",
            borderRadius: "15px",
            marginLeft: "2px",
            marginRight: "15px",
            textAlign: "right",
          }}
        >
          <Dropdown overlay={profileMenu}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <div style={{ borderRadius: "15px" }}>
                  <Avatar round={true} size="35" name={fullName} />
                </div>
              </Space>
            </a>
          </Dropdown>
        </span>
      </div>
    );
  };

  const menu = () => {
    if (login) return loginNavView();
    return (
      <div className="menuCon" style={{ paddingTop: "15px", height: "25px" }}>
        <div className="leftMenu menuWidth">
          <LeftMenu />
        </div>
        <div className="rightMenu">
          <RightMenu />
        </div>
        {mobileView && (
          <Button
            className="barsMenu"
            style={{
              width: "auto",
              background: "#FFFCF2",
              padding: "0 3% 12%",
              marginRight: "3%",
              border: "none",
            }}
            type="primary"
            onClick={showDrawer}
          >
            <span className="barsBtn"></span>
          </Button>
        )}
        <Drawer
          style={{}}
          title="Lenny"
          // placement="right"
          // closable={true}
          onClose={onClose}
          visible={visible}
        >
          <LeftMenu />
          <RightMenu />
        </Drawer>
      </div>
    );
  };

  const viewSelector = [
    "/",
    "/home",
    "/b2b",
    "/auth/",
    "/auth/login",
    "/auth/register",
    "/auth/register1",
    "/signup",
    "/auth/register3",
    "/auth/apply",
    "/privacy",
    "/terms",
    "/faq",
    "/contact",
    "/partners",
    "/pricing",
    "/about-us",
    //"/chatbot"
  ].includes(history.location.pathname);

  const checkToken = localStorage.getItem("token");

  return !viewSelector ? (
    <div />
  ) : (
    <nav
      className="menuBar"
      style={{
        background: "rgb(255, 255, 255)",
        borderBottom: "1px double lightgray",
        borderTopColor: "lightgray",
        borderRightColor: "lightgray",
        borderLeftColor: "lightgray",
        paddingLeft: mobileView ? "0" : "40px",
        paddingRight: "40px",
        position: "sticky",
        top: "0",
        width: "100%",
        zIndex: "1",
        overflow: "hidden",
      }}
    >
      <div
        className="logo"
        onClick={() => {
          if (checkToken) {
            history.push("/auth/dashboard");
          } else {
            history.push("/");
          }
        }}
      >
        <img
          src="/new/oldLogo-removebg-1.png"
          // src="/new/oldLogo.webp"
          alt="Lenny.AI"
          style={{
            width: window.innerWidth < 600 ? "195px" : "235px",
            cursor: "pointer",
          }}
        />
      </div>

      {stepper
        ? stepperView({ current: props.pathname === "/signup" ? 1 : 2 })
        : menu()}
    </nav>
  );
};

export default Navbar;
