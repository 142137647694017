import React, { useEffect, useState, useCallback } from "react";
import { CalendarOutlined, CloseCircleFilled } from "@ant-design/icons";
import Utils from "utils/common";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import fetch_ from "interceptors/FetchInterceptor";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { Col } from "antd";
import ChatUserDetails from "./ChatUserDetails.js";
import Subscription from "components/layout-components/Subscription";
import SubscriptionMobileDesign from "components/layout-components/SubscriptionMobileDesign";
import "./css/Popus.css";
import { useAssitanceChatBotContext } from "context/ChatbotContext.js";

const ChatStructure = ({
  type,
  scholarshipData,
  dashbordHide = false,
  setChatBtn,
  applyScholarship,
  chatThreeDotLoading,
  setProfileUpdate,
}) => {
  const { maximizeView } = useAssitanceChatBotContext();
  const history = useHistory();
  const mobileView = window.innerWidth < 915;
  const phoneView = window.innerWidth < 962;
  const storedValue = localStorage.getItem("isActiveSubscription");
  const isActiveSubscription = storedValue === "true";
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    if (isActiveSubscription) {
      history.push("/auth/dashboard/plan");
    } else {
      // setIsOpen(!isOpen);
      console.log("a");
      if (phoneView) {
        setIsOpen(!isOpen);
        console.log(isOpen, phoneView);
        console.log("b");
      } else {
        history.push("/auth/dashboard/subscription-plan");
        console.log("c");
      }
    }
  };
  const [dashboardView, setDashboardView] = useState(dashbordHide);
  useEffect(() => {
    if (history.location.pathname?.includes("assistanceChat")) {
      setDashboardView(false);
    }
  }, [history.location.pathname]);

  const viewDetail = (data) => {
    history.push({
      pathname: "/auth/scholarshipDetail",
      search: data.scholarshipId || data.id || data.dataValues.id,
      // params: props.type,
    });
    // props.setSideBars(true);
  };

  const amountView = (data) => {
    // console.log(data);
    const totalAmount =
      (data?.amounts && data.amounts.reduce((acc, curr) => acc + curr, 0)) ||
      (data?.scholarship?.amounts &&
        data?.scholarship?.amounts.reduce((acc, curr) => acc + curr, 0)) ||
      (data?.dataValues?.amounts &&
        data.dataValues.amounts.reduce((acc, curr) => acc + curr, 0)) ||
      data?.scholarship?.awards ||
      data?.awards ||
      data?.dataValues?.awards;
    return (
      <Col style={{ width: "11%" }}>
        <h2 style={{ margin: "0" }}>{Utils.formatAsUSD(totalAmount)}</h2>
      </Col>
    );
  };

  function extractTextFromHTML(html) {
    // Create a temporary element
    const tempElement = document.createElement("div");
    // Set the HTML content of the temporary element
    tempElement.innerHTML = html;
    // Return the text content of the temporary element
    return tempElement.textContent || tempElement.innerText || "";
  }

  const TableStruct = ({ data }) => {
    // console.log("🚀 ~ TableStruct ~ data:", data);
    const [tempData, setTempData] = useState({ ...data });

    const likeClick = () => {
      if (tempData.wishlist.length > 0) {
        setTempData({ ...data, wishlist: [] });
        let scholarshipId = data.id;
        fetch_({
          url: `scholarships/remove-wishlist`,
          method: "PUT",
          data: { scholarshipId: scholarshipId },
        })
          .then(({ data }) => {})
          .catch((error) => {});
      } else {
        let scholarshipId = data.id;
        setTempData({ ...data, wishlist: [{ scholarshipId: scholarshipId }] });
        fetch_({
          url: `scholarships/add-wishlist`,
          method: "PUT",
          data: { scholarshipId: scholarshipId },
        })
          .then(({ data }) => {})
          .catch((error) => {});
      }
      // window.location.reload();
    };
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "white",
          padding: "16px",
          gap: "20px",
          alignItems: "center",
          width: "max-content",
          marginBottom: "10px",
          width: "98%",
          flexWrap: maximizeView
            ? "nowrap"
            : dashboardView
            ? "wrap"
            : mobileView
            ? "wrap"
            : "nowrap",
          borderRadius: "8px",
          justifyContent: "space-between",
        }}
      >
        <div
          className="b1"
          style={{
            width: dashboardView ? "100%" : mobileView ? "100%" : "70%",
          }}
        >
          <div>
            <p
              style={{
                color: "black",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              {data?.title}{" "}
            </p>
          </div>
          <div>
            <p
              style={{
                color: "black",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              {" "}
              {extractTextFromHTML(data?.description)?.slice(0, 150)}...{" "}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: dashboardView ? "column" : "row",
            }}
          >
            <div>
              <CalendarOutlined /> End Date : {data?.recurrenceConfig?.deadline}
            </div>
            <div>
              <span
                style={{
                  marginLeft: dashboardView ? "0" : "20px",
                }}
              >
                <img
                  src="/applicantImg/start2.png"
                  style={{
                    width: "16px",
                  }}
                />
              </span>{" "}
              Sponsored by
              {" " + data?.user?.firstName}
            </div>
          </div>
        </div>
        <div
          className="b2"
          style={{
            width: dashboardView ? "max-content" : "15%",
          }}
        >
          {amountView(data)}
        </div>
        <div
          style={{
            padding: "4px 7px 5px 7px",
          }}
          onClick={likeClick}
        >
          {tempData?.wishlist?.length > 0 ? (
            <HeartFilled style={{ color: "#8D96FF" }} />
          ) : (
            <HeartOutlined style={{}} />
          )}
        </div>
        <div
          className="b3"
          style={{
            width: dashboardView ? "50%" : mobileView ? "auto" : "15%",
          }}
        >
          <p
            style={{
              padding: "8px",
              backgroundColor: "#8D96FF",
              color: "black",
              borderRadius: "32px",
              textAlign: "center",
              fontWeight: "500",
              cursor: "pointer",
              // width: "max-content",
              color: "white",
              fontSize: "12px",
            }}
            onClick={() => {
              if (isActiveSubscription === true) {
                // viewDetail();
                viewDetail(data);
              } else {
                togglePopup();
              }
            }}
          >
            View Details
          </p>
        </div>
        {isOpen &&
          (phoneView ? (
            <div
              className="popup-overlay"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "24px",
                  padding: "0px 20px",
                }}
              >
                Manav get a membership for free 7 days to activate automatic
                application to your 234 scholarship matches. Lets us do the hard
                work for you !
              </p>

              <SubscriptionMobileDesign
                toggleMobilePopup={togglePopup}
                redirect={true}
              />
            </div>
          ) : (
            <div className="popup-overlay">
              <div className="popup-content">
                <div
                  onClick={togglePopup}
                  style={{
                    color: "rgb(113, 122, 245)",
                    fontSize: "24px",
                    cursor: "pointer",
                    float: "right",
                    lineHeight: "normal",
                  }}
                >
                  {" "}
                  <CloseCircleFilled />
                </div>
                <Subscription
                  modalStyle={{
                    border: "none",
                    boxShadow: "none",
                    margin: "0px",
                  }}
                  redirect={true}
                />
              </div>
            </div>
          ))}
      </div>
    );
  };

  if (type == "scholarshipList") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {scholarshipData.length > 0 &&
          scholarshipData.map((data) => {
            // console.log("🚀 ~ scholarshipData.length>0&&scholarshipData.map ~ data:", data);
            return <TableStruct data={data} />;
          })}
      </div>
    );
  }

  if (type == "userDetails") {
    return (
      <>
        <ChatUserDetails
          setChatBtn={setChatBtn}
          setProfileUpdate={setProfileUpdate}
          applyScholarship={applyScholarship}
        />
      </>
    );
  }

  return (
    <div>
      <h1>table data</h1>
    </div>
  );
};

export default ChatStructure;
