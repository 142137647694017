import { Button, Spin, message } from "antd";
import fetch_ from "interceptors/FetchInterceptor";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./index.css";
const Index = (props) => {
  const history = useHistory();

  const [dataLoader, setDataLoader] = useState(true);

  const [features, setFeatures] = useState(
    'AI powered Application Engine. Lenny does it for you,7 Days free trial and cancel anytime,Hundreds of thousands of award money is currently available,New Scholarship opportunities added every month,Automatic re-apply to repeat scholarship,Cancel trials anytime during the trail period from "Setting" page,Superb Lenny- AI essays writing assisstance .'
  );

  const addressId = localStorage.getItem("addressId");
  async function fetchData() {
    try {
      // const data = await fetch_(`/register-applicants/form-fields`);
      // const countryObj = data.data?.filter((e) => e.key === "country");

      const profileData = await fetch_("/register-applicants/profile");
      const userInfo = profileData.data;

      // const currentSubscription = await fetch_(
      //   "/user-payments/current-subscription"
      // );

      const defaultValues = {
        // id: userInfo?.id,
        // firstName: userInfo?.firstName,
        // lastName: userInfo?.lastName,
        // email: userInfo?.email,
        // country: userInfo?.applicantFields?.filter(
        //   (e) => e.fieldId === countryObj[0]?.id
        // )[0]?.fieldValue,
        // paddleUserId: userInfo?.paddleUserId,
        // paddleAddressId: userInfo?.paddleAddressId,
        subscribedId: userInfo?.subscribedId,
      };

      return defaultValues.subscribedId;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  const getFeatures = async () => {
    try {
      const subscriptionId = await fetchData();

      if (subscriptionId) {
        const { data } = await fetch_(
          `user-payments/validate-subscription/${subscriptionId}/Paddle/${addressId}`,
          {
            method: "GET",
          }
        );

        if (
          data.subscriptionStatus === "approved" ||
          data.subscriptionStatus === "active" ||
          data.subscriptionStatus === "trialing"
        ) {
          localStorage.setItem("isActiveSubscription", true);
        } else {
          localStorage.setItem("isActiveSubscription", false);
        }

        setFeatures(data.congratulationsMessage);
        setDataLoader(false);
        // localStorage.setItem("isActiveSubscription", true);
        localStorage.removeItem("addressId");
      }
    } catch (error) {
      console.error("Error validating subscription:", error);
      setDataLoader(true);
      message.error("Error validating subscription");
      localStorage.removeItem("addressId");
      localStorage.setItem("isActiveSubscription", false);
    }
  };

  const checkSubscription = async () => {
    // console.log("checkSubscription");
    try {
      const response = await fetch_("/user-payments/current-subscription", {
        method: "GET",
      });
      const data = await response;
      // console.log(data.data);
      let status = data.data.userWithSubscription[0].is_active_subscription;
      if (status) {
        setDataLoader(false);
      } else {
        message.error("Error validating subscription");
        setTimeout(() => {
          history.push("/auth/dashboard");
        }, 1000);
      }
    } catch (error) {
      console.error("Error validating subscription:", error);
    }
  };

  useEffect(() => {
    //adding a delay to check the subscription status because after closing the checkout success popup api takes time to update in backend
    setTimeout(() => {
      checkSubscription();
    }, 2000);
  }, []);

  // useEffect(() => {
  //   window.trackdesk("lennyai", "conversion", {
  //     conversionType: "sale",
  //     amount: {
  //       value: "$10", //dummy value
  //     },
  //   });
  // }, []);

  if (dataLoader) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "black",
            fontFamily: "Poppins",
            margin: "5px",
          }}
        >
          Verifying Subscription Status for you.{" "}
        </p>
        {/* <div> */}
        <p
          style={{
            color: "black",
            fontFamily: "Poppins",
            margin: "5px",
            display: "inline",
          }}
        >
          {" "}
          Please wait...
          <Spin
            size="small"
            style={{ marginLeft: "5px" }}
            className="custom-spinner"
          />
        </p>
        {/* </div> */}
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          background: 'url("/images/grp-bg.png")',
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          height: "25vh",
          width: "100%",
          backgroundRepeatX: "repeat",
        }}
      >
        <div
          style={{
            margin: "45px",
          }}
        >
          <div
            className="header"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "black",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "32px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                margin: "5px",
              }}
            >
              Congratulations{" "}
            </p>
            <img src="/images/cone.png" alt="not loaded" />
          </div>
          <p
            style={{
              color: "black",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "normal",
            }}
          >
            Your subscription plan has been purchased successfully!
          </p>
        </div>

        <div
          style={{
            display: "flex",
            // flexWrap: "wrap",
          }}
          className="success_desc_container"
        >
          <div
            style={{
              width: "35%",
              textAlign: "end",
              order: 1,
            }}
            className="cont1"
          >
            <img src="/images/robo.png" alt="" width="211px" />
          </div>

          <div
            style={{
              width: "50%",
              order: 2,
            }}
            className="cont2"
          >
            <p
              style={{
                color: "black",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
              }}
            >
              AI powered Application Engine. Lenny does it for you
            </p>
            <ul>
              {/* {featuress.map((item, index) => {
                return (
                  <li
                    key={index}
                    style={{
                      color: "black",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "30px",
                    }}
                  >
                    {item}
                  </li>
                );
              })} */}
              {features
                ? features.split(",").map((item, index) => (
                    <li
                      key={index}
                      style={{
                        color: "black",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "30px",
                      }}
                    >
                      {item}
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            style={{
              width: "145px",
              height: "40px",
              borderRadius: "12px",
              background: "#8D96FF",
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "500",
              marginTop: "25px",
              marginBottom: "30px",
            }}
            onClick={() => {
              history.push("/auth/dashboard");
            }}
          >
            Start Applying
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Index;
