import { Button, Col, Form, Row, Space, Spin, notification } from "antd";
import useAxios from "axios-hooks";
import Flex from "components/shared-components/Flex";
import { useState } from "react";
import { ScholarshipService_ } from "services/scholarship.service";
import ApplicationStatus from "./ApplicationStatus";
import Requirement from "./Requirement";
import ScholarshipInfo from "./ScholarshipInfo";
import ThankYouModal from "./ThankYouModal";
import { createPayload, getInitialValues } from "./utils";
import HtmlPopup from "../components/HtmlPopUp";

const ScholarshipDetail = ({ history, props }) => {
  const id = history?.location?.search?.slice(1);
  const [{ data = {}, loading }] = useAxios(`/scholarships/${id}`);
  const [{ data: existingData, loading: loadingExistingData }] = useAxios(
    `/scholarships/${id}/applicants/get-applicant-details`
  );
  const [completedSteps, setCompletedSteps] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  const mobileView = window.innerWidth < 1200;

  const onFormFieldsChange = (_, values) => {
    let completed = 1;

    data?.requirements?.forEach((item, idx) => {
      if (!!values[item?.field?.name]) {
        completed = idx + 2;
      }
    });

    setCompletedSteps(completed);
  };

  const onSubmitForm = (values, e) => {
    const payload = createPayload(values, data);
    ScholarshipService_.submitIndividualScholarship(payload)
      .then((res) => {
        setModalOpen(true);
      })
      .catch((err) => {
        notification.open({
          message: "Something went wrong",
          type: "error",
        });
      });
  };

  const onSubmitDraft = () => {
    const values = form.getFieldsValue();

    let payload = createPayload(values, data);
    payload = { ...payload, status: "Draft" };
    ScholarshipService_.submitIndividualScholarship(payload)
      .then((res) => {
        notification.open({
          message: "Saved draft",
          type: "success",
        });
      })
      .catch((err) => {
        notification.open({
          message: "Something went wrong",
          type: "error",
        });
      });
  };

  const onCloseModal = () => {
    setModalOpen(false);
    history.goBack();
  };

  if (loading)
    return (
      <div
        style={{
          position: "absolute",
          left: mobileView ? window.innerWidth / 2 : "720px",
          top: "241px",
        }}
      >
        <Spin size={"large"} />
      </div>
    );

  return (
    <div>
      <Row style={{ minHeight: "calc(100vh - 90px)" }}>
        <Col
          style={{
            width: mobileView ? "100%" : "78%",
            padding: "2% 3% 2% 3%",
            background: "#ffffff",
            position: "relative",
          }}
        >
          <a
            onClick={() => {
              props.setSideBars(false);
              history.goBack();
            }}
            style={{ color: "#1D252D" }}
          >
            <img
              src="/applicantImg/back.png"
              style={{ height: "18px", marginRight: "4px" }}
            />
            Back to Scholarships
          </a>
          {mobileView && (
            <ApplicationStatus
              requirements={data?.requirements}
              completedSteps={completedSteps}
            />
          )}
          <ScholarshipInfo scholarship={data} />
          <div>
            <Form
              name="scholarship-details-form"
              onFinish={onSubmitForm}
              onValuesChange={onFormFieldsChange}
              form={form}
              initialValues={getInitialValues(existingData?.applicantFields)}
            >
              {data?.requirements && data?.requirements.length > 0 ? (
                <Space
                  direction="vertical"
                  size="large"
                  style={{ display: "flex", marginBottom: "70px" }}
                >
                  <h3 style={{ marginBottom: "0px", fontWeight: "600" }}>
                    Requirements
                  </h3>
                  {data.requirements.map((item) => (
                    <Requirement
                      key={item.id}
                      details={item}
                      formRef={form}
                      scholarshipId={data.id}
                    />
                  ))}
                </Space>
              ) : null}
              {/* <Space direction="vertical">
                <h3 style={{ marginBottom: "0px", fontWeight: "700" }}>
                  Terms and Conditions
                </h3>
                <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "10px" }}>
                  <HtmlPopup
                    buttonLabel="Terms of use"
                    htmlContent={data.terms}
                  />
                  <HtmlPopup
                    buttonLabel="Privacy Policy"
                    htmlContent={data.privacy}
                  />
                  <HtmlPopup
                    buttonLabel="Affidavit"
                    htmlContent={data.affidavit}
                  />
                </div>
              </Space> */}

              <Form.Item
                className=" fixed-bottom"
                style={{
                  padding: "15px 20px",
                  width: mobileView ? "" : "77.5%",
                  background: "#fff",
                  margin: 0,
                  borderTop: "1px solid rgba(29, 37, 45, 0.1)",
                  position: "fixed",
                  zIndex: 1,
                  float: "right",
                }}
              >
                {existingData?.status !== "Received" && (
                  <Flex>
                    {data?.requirements && data?.requirements.length > 0 ? (
                      <Button
                        htmlType="button"
                        name="submit-draft"
                        style={{
                          marginRight: "10px",
                          width: "185px",
                          float: "right",
                          borderColor: "#40A9FF",
                          color: "rgb(141, 150, 255)",
                          borderRadius: "5px",
                          borderColor: "rgb(141, 150, 255)",
                        }}
                        onClick={onSubmitDraft}
                      >
                        Save draft
                      </Button>
                    ) : null}
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        width: "185px",
                        float: "right",
                        borderRadius: "5px",
                        backgroundColor: "rgb(141, 150, 255)",
                        border: "none",
                      }}
                    >
                      Submit application
                    </Button>
                  </Flex>
                )}
              </Form.Item>
            </Form>
            <ThankYouModal open={modalOpen} handleOnCloseModal={onCloseModal} />
          </div>
        </Col>
        {!mobileView && (
          <Col
            style={{ paddingTop: "10px", paddingLeft: "20px", width: "22%" }}
          >
            <ApplicationStatus
              requirements={data?.requirements}
              completedSteps={completedSteps}
              applicationstatus={existingData?.status}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ScholarshipDetail;
