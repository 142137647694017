import {
  Alert,
  Button,
  Col,
  Form,
  Radio,
  Row,
  Select,
  Spin,
  Text,
  Input,
  Typography,
  Checkbox,
} from "antd";
import useAxios from "axios-hooks";
import { motion } from "framer-motion";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SelectDatepicker } from "react-select-datepicker";
import { showAuthMessage, showLoading } from "redux/actions/Auth";
import { UserAuthService } from "services/user-auth.service";
import useAnalyticsEventTracker from "../../../../useAnalyticsEventTracker";
import "../register.scss";
import rules from "../rules.js";

const EducationDetail = () => {
  const [form1] = Form.useForm();
  const history = useHistory();
  const { Text } = Typography;
  const gaEventTracker = useAnalyticsEventTracker("Profile");

  const [alertShow, setAlertShow] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [alertType, setAlertType] = useState(true);
  //const formValues = JSON.parse(localStorage.getItem('register3'));
  const [collegeNameValue, setCollegeNameValue] = useState();
  const [schoolNameValue, setSchoolNameValue] = useState();
  const [collegeNameShow, setCollegeNameShow] = useState(true);
  const [schoolNameShow, setSchoolNameShow] = useState(true);
  const [dob, setDob] = useState();
  const [collegeEnrolledDate, setCollegeEnrolledDate] = useState(undefined);
  const [highSchoolGradDate, setHighSchoolGradDate] = useState(undefined);
  const [collegeGraduationDate, setCollegeGraduationDate] = useState(undefined);
  const [{ data: userInfo, loading: profileLoading }] = useAxios(
    { url: `register-applicants/profile` },
    { useCache: false }
  );
  const [{ data = [], loading }] = useAxios(`/register-applicants/form-fields`);

  const mobileView = window.innerWidth < 1200;
  // const userInfoString = localStorage.getItem("userInfo");
  // let userInfo = {};
  // if (userInfoString) {
  //   try {
  //     userInfo = JSON.parse(userInfoString);
  //   } catch (err) {
  //     console.error("Error parsing userInfo:", err);
  //   }
  // }

  const onDateChange = useCallback((dob) => {
    setDob(dob);
  }, []);

  const onCollegeEnrolledDateChange = useCallback((collegeEnrolledDate) => {
    setCollegeEnrolledDate(collegeEnrolledDate);
  }, []);

  const onHighSchoolGradDateChange = useCallback((highSchoolGradDate) => {
    setHighSchoolGradDate(highSchoolGradDate);
  }, []);

  const onCollegeGraduationDateChange = useCallback((collegeGraduationDate) => {
    setCollegeGraduationDate(collegeGraduationDate);
  }, []);

  const citizenship = data?.filter((e) => e.key === "citizenship")[0]?.options;
  const ethnicity = data?.filter((e) => e.key === "ethnicity")[0]?.options;
  const schoolLevel = data?.filter((e) => e.key === "schoolLevel")[0]?.options;
  const militaryAffiliation = data?.filter(
    (e) => e.key === "militaryAffiliation"
  )[0]?.options;
  const gpa = data?.filter((e) => e.key === "gpa")[0]?.options;
  const highSchoolName = data?.filter((e) => e.key === "highSchoolName")[0]
    ?.options;
  const collegeName = data?.filter((e) => e.key === "collegeName")[0]?.options;
  const fieldOfStudy = data?.filter((e) => e.key === "fieldOfStudy")[0]
    ?.options;
  const degreeType = data?.filter((e) => e.key === "degreeType")[0]?.options;
  const careerGoal = data?.filter((e) => e.key === "careerGoal")[0]?.options;
  const citizenshipId = data?.filter((e) => e.key === "citizenship");
  const ethnicityId = data?.filter((e) => e.key === "ethnicity");
  const highSchoolNameId = data?.filter((e) => e.key === "highSchoolName");
  const militaryAffiliationId = data?.filter(
    (e) => e.key === "militaryAffiliation"
  );
  const gpaId = data?.filter((e) => e.key === "gpa");
  const degreeTypeId = data?.filter((e) => e.key === "degreeType");
  const careerGoalId = data?.filter((e) => e.key === "careerGoal");
  const highSchoolGradDateId = data?.filter(
    (e) => e.key === "highSchoolGraduationDate"
  );
  const enrolledInCollegeId = data?.filter(
    (e) => e.key === "enrolledInCollege"
  );
  const collegeEnrolledDateId = data?.filter((e) => e.key === "enrollmentDate");
  const collegeNameId = data?.filter((e) => e.key === "collegeName");
  //const genderId = data?.filter((e) => e.key === "gender");
  const fieldOfStudyId = data?.filter((e) => e.key === "fieldOfStudy");
  const currentSchoolLevelId = data?.filter((e) => e.key === "schoolLevel");
  const collegeGraduationDateId = data?.filter(
    (e) => e.key === "collegeGraduationDate"
  );
  const interestedInOnlineId = data?.filter(
    (e) => e.key === "isInterestedInOnlineStudy"
  );
  const interestedCollegesId = data?.filter(
    (e) => e.key === "interestedColleges"
  );

  const [markingSystem, setMarkingSystem] = useState("GPA"); // Default marking system
  const [userInput, setUserInput] = useState("");
  const [equivalentGPA, setEquivalentGPA] = useState(null);
  const [userGPA, setUserGPA] = useState(null);

  const [enrolledInChanged, setEnrolledInChanged] = useState("");
  const handleMarkingSystemChange = (value) => {
    setMarkingSystem(value);
    form1.setFieldsValue({
      selectedGPA: null,
      userinput: "",
      gpa: null,
    });
  };
  const handleUserInputChange = (e) => {
    const input = e.target.value;
    setUserInput(input);

    // Automatically calculate and set equivalent GPA
    if (markingSystem === "Percentage") {
      setUserGPA(null);
      setEquivalentGPA(percentageToGPA(input));
    } else if (markingSystem === "10CGPA") {
      setUserGPA(null);
      setEquivalentGPA(cgpaToGPA(input));
    } else {
      setEquivalentGPA(null);
    }
  };

  function percentageToGPA(percentage) {
    // console.log("here percentage");
    if (percentage >= 85 && percentage <= 100) {
      // console.log("GPA is ", "4.0");
      return "4.0";
    } else if (percentage >= 80 && percentage < 85) {
      return "3.7";
    } else if (percentage >= 75 && percentage < 80) {
      return "3.3";
    } else if (percentage >= 70 && percentage < 75) {
      return "3.0";
    } else if (percentage >= 65 && percentage < 70) {
      return "2.7";
    } else if (percentage >= 60 && percentage < 65) {
      return "2.3";
    } else if (percentage >= 55 && percentage < 60) {
      return "2.0";
    } else {
      return "0.0";
    }
  }

  function cgpaToGPA(cgpa) {
    if (cgpa >= 8.5) {
      return "4.0";
    } else if (cgpa >= 8.0 && cgpa < 8.5) {
      return "3.7";
    } else if (cgpa >= 7.5 && cgpa < 8.0) {
      return "3.3";
    } else if (cgpa >= 7.0 && cgpa < 7.5) {
      return "3.0";
    } else if (cgpa >= 6.5 && cgpa < 7.0) {
      return "2.7";
    } else if (cgpa >= 6.0 && cgpa < 6.5) {
      return "2.3";
    } else if (cgpa >= 5.5 && cgpa < 6.0) {
      return "2.0";
    } else {
      return "0.0";
    }
  }

  const renderGPAOptions = () => {
    // console.log(
    //   "Rendering GPA Options. Marking System:",
    //   markingSystem,
    //   "Equivalent GPA:",
    //   equivalentGPA
    // );
    if (markingSystem === "GPA") {
      return (
        <Form.Item name="gpa" rules={rules.gpa} style={{ color: "#7D7B7D" }}>
          <Select size="large" placeholder="Select GPA">
            {!loading &&
              gpa &&
              Object.keys(gpa).map((option) => (
                <Select.Option value={option} key={option}>
                  {gpa[option]}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      );
    } else {
      return (
        <Form.Item name="selectedGPA" rules={rules.markingSystem}>
          <Select size="large" placeholder="Select GPA">
            {!loading &&
              gpa &&
              Object.keys(gpa).map((option) => (
                <Select.Option value={option} key={option}>
                  {gpa[option]}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      );
    }
  };

  useEffect(() => {
    if (
      userInfo?.applicantFields?.filter(
        (e) => e.fieldId === collegeEnrolledDateId[0]?.id
      )[0]?.fieldValue
    ) {
      collegeGraduationDate === null &&
        setCollegeGraduationDate(
          new Date(
            userInfo?.applicantFields?.filter(
              (e) => e.fieldId === collegeGraduationDateId[0]?.id
            )[0]?.fieldValue
          )
        );
      highSchoolGradDate === null &&
        setHighSchoolGradDate(
          new Date(
            userInfo.applicantFields?.filter(
              (e) => e.fieldId === highSchoolGradDateId[0]?.id
            )[0]?.fieldValue
          )
        );
      collegeEnrolledDate === null &&
        setCollegeEnrolledDate(
          new Date(
            userInfo?.applicantFields?.filter(
              (e) => e.fieldId === collegeEnrolledDateId[0]?.id
            )[0]?.fieldValue
          )
        );
      enrolledInChanged === "" &&
        setEnrolledInChanged(
          userInfo?.applicantFields?.filter(
            (e) => e.fieldId === enrolledInCollegeId[0]?.id
          )[0]?.fieldValue
        );
    }
  });

  useEffect(() => {
    // Manually set the value of the selectedGPA field when equivalentGPA changes
    form1.setFieldsValue({
      selectedGPA: equivalentGPA,
      userinput: userInput,
      //collegeEnrolledDate:collegeEnrolledDate,
    });
  }, [equivalentGPA, form1]);

  const dateRule = {
    dob: [
      {
        required: true,
        message: "Please select your date of birth!",
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (dob === null && getFieldValue("dob") > 0) {
            return Promise.reject("Please select your date of birth!");
          }
          return Promise.resolve();
        },
      }),
    ],
    enrolledInCollege: [
      ({ getFieldValue }) => ({
        validator(rule, value) {},
      }),
    ],
  };

  const updateInfo = () => {
    setDisableButton(true);
    let label = {
      screenName: "Profile Educational Detail",
    };
    gaEventTracker("User Update clicked", JSON.stringify(label));
    form1
      .validateFields()
      .then((values) => {
        let tmpApplicantData = [];
        const gpa__A = values.gpa ? values.gpa : values.selectedGPA;

        userInfo?.applicantFields.map((e) => {
          if (e.field.key === "ethnicity") {
            tmpApplicantData = [
              ...tmpApplicantData,
              { fieldId: e.fieldId, fieldValue: e.fieldValue },
            ];
          } else if (e.field.key === "citizenship") {
            tmpApplicantData = [
              ...tmpApplicantData,
              { fieldId: e.fieldId, fieldValue: e.fieldValue },
            ];
          } else if (e.field.key === "gender") {
            tmpApplicantData = [
              ...tmpApplicantData,
              { fieldId: e.fieldId, fieldValue: e.fieldValue },
            ];
          } else if (e.field.key === "country") {
            // console.log("country here ");
            tmpApplicantData = [
              ...tmpApplicantData,
              { fieldId: e.fieldId, fieldValue: e.fieldValue },
            ];
          }
        });
        // let postData = {
        //   firstName: userInfo?.firstName,
        //   lastName: userInfo?.lastName,
        //   email: userInfo?.email,
        //   // phone: userInfo?.phone,
        //   dob: userInfo?.dob,
        //   applicantType: userInfo?.applicantType,
        //   applicantFields: [
        //     // { fieldId: citizenshipId[0].id, fieldValue: values.citizenship },
        //     //{ fieldId: ethnicityId[0].id, fieldValue: values.ethnicity },
        //     ...tmpApplicantData,
        //     {
        //       fieldId: highSchoolNameId[0].id,
        //       fieldValue: values.highSchoolName,
        //     },
        //     {
        //       fieldId: highSchoolGradDateId[0].id,
        //       fieldValue: moment(highSchoolGradDate).format("YYYY-MM-DD"),
        //     },
        //     {
        //       fieldId: enrolledInCollegeId[0].id,
        //       fieldValue: enrolledInChanged,
        //     },
        //     {
        //       fieldId: collegeEnrolledDateId[0].id,
        //       fieldValue: moment(collegeEnrolledDate).format("YYYY-MM-DD"),
        //     },
        //     {
        //       fieldId: collegeGraduationDateId[0].id,
        //       fieldValue: moment(collegeGraduationDate).format("YYYY-MM-DD"),
        //     },
        //     { fieldId: degreeTypeId[0].id, fieldValue: values.degreeType },
        //     {
        //       fieldId: gpaId[0].id,
        //       fieldValue: values.gpa ? values.gpa : values.selectedGPA,
        //     },
        //     { fieldId: careerGoalId[0].id, fieldValue: values.careerGoal },
        //     {
        //       fieldId: interestedInOnlineId[0].id,
        //       fieldValue: values.interestedInOnline,
        //     },
        //     {
        //       fieldId: militaryAffiliationId[0].id,
        //       fieldValue: values.militaryAffiliation,
        //     },
        //     // { fieldId: genderId[0].id, fieldValue: values.gender + "" },
        //     { fieldId: fieldOfStudyId[0].id, fieldValue: values.fieldOfStudy },
        //     {
        //       fieldId: currentSchoolLevelId[0].id,
        //       fieldValue: values.currentSchoolLevel,
        //     },
        //     // { fieldId: addressId[0].id, fieldValue: values.address },
        //     // { fieldId: zipCodeId[0].id, fieldValue: values.zipCode },
        //     // { fieldId: cityId[0].id, fieldValue: values.city },
        //     // { fieldId: stateId[0].id, fieldValue: values.state },
        //   ],
        // };
        let postData = {
          firstName: userInfo?.firstName ?? "",
          lastName: userInfo?.lastName ?? "",
          email: userInfo?.email ?? "",
          // phone: userInfo?.phone ?? "",
          dob: userInfo?.dob ?? "",
          applicantType: userInfo?.applicantType ?? "STUDENT",
          applicantFields: [
            // { fieldId: citizenshipId[0]?.id ?? "", fieldValue: values.citizenship ?? "" },
            //{ fieldId: ethnicityId[0]?.id ?? "", fieldValue: values.ethnicity ?? "" },
            ...tmpApplicantData,
            {
              fieldId: highSchoolNameId[0]?.id ?? "",
              fieldValue: values.highSchoolName ?? "",
            },
            {
              fieldId: highSchoolGradDateId[0]?.id ?? "",
              fieldValue: moment(highSchoolGradDate).format("YYYY-MM-DD") ?? "",
            },
            {
              fieldId: enrolledInCollegeId[0]?.id ?? "",
              fieldValue: enrolledInChanged ?? "",
            },
            {
              fieldId: collegeEnrolledDateId[0]?.id ?? "",
              fieldValue:
                moment(collegeEnrolledDate).format("YYYY-MM-DD") ?? "",
            },
            {
              fieldId: collegeGraduationDateId[0]?.id ?? "",
              fieldValue:
                moment(collegeGraduationDate).format("YYYY-MM-DD") ?? "",
            },
            {
              fieldId: degreeTypeId[0]?.id ?? "",
              fieldValue: values.degreeType ?? "",
            },
            {
              fieldId: gpaId[0]?.id ?? "",
              fieldValue: values.gpa ?? values.selectedGPA ?? "",
            },
            {
              fieldId: careerGoalId[0]?.id ?? "",
              fieldValue: values.careerGoal ?? "",
            },
            {
              fieldId: interestedInOnlineId[0]?.id ?? "",
              fieldValue: values.interestedInOnline ?? "",
            },
            {
              fieldId: militaryAffiliationId[0]?.id ?? "",
              fieldValue: values.militaryAffiliation ?? "",
            },
            // { fieldId: genderId[0]?.id ?? "", fieldValue: values.gender + "" ?? "" },
            {
              fieldId: fieldOfStudyId[0]?.id ?? "",
              fieldValue: values.fieldOfStudy ?? "",
            },
            {
              fieldId: currentSchoolLevelId[0]?.id ?? "",
              fieldValue: values.currentSchoolLevel ?? "",
            },
            // { fieldId: addressId[0]?.id ?? "", fieldValue: values.address ?? "" },
            // { fieldId: zipCodeId[0]?.id ?? "", fieldValue: values.zipCode ?? "" },
            // { fieldId: cityId[0]?.id ?? "", fieldValue: values.city ?? "" },
            // { fieldId: stateId[0]?.id ?? "", fieldValue: values.state ?? "" },
          ],
        };

        if (enrolledInChanged == "1") {
          postData = {
            ...postData,
            applicantFields: [
              ...postData.applicantFields,
              {
                fieldId: collegeNameId[0].id,
                fieldValue: values.collegeName,
              },
            ],
          };
        } else {
          postData = {
            ...postData,
            applicantFields: [
              ...postData.applicantFields,
              {
                fieldId: interestedCollegesId[0].id,
                fieldValue: values.collegeName1,
              },
              {
                fieldId: interestedCollegesId[0].id,
                fieldValue: values.collegeName2,
              },
            ],
          };
        }

        UserAuthService.updateUserApplicant(userInfo?.id, postData)
          .then((data) => {
            if (data.statusCode == 200) {
              localStorage.setItem(
                "userInfo",
                typeof data.data.applicant === "string"
                  ? data.data.applicant
                  : JSON.stringify(data.data.applicant)
              );
            }
            // window.location.reload();
            setAlertShow(true);
            setAlertType(true);
            setAlertMessage("User updated successfully");
            showAuthMessage("Your account has been successfully registered.");
          })
          .catch((info) => {
            setAlertShow(true);
            if (
              info.description ===
              'Request validation of body failed, because: "dob" must be a string'
            ) {
              setAlertMessage("Please enter valid dob in Basic tab ");
            } else {
              setAlertMessage(info.description || "Server Error");
            }

            setAlertType(false);
            // console.log("Validate Failed:", info);
          })
          .finally(() => {
            showLoading(false);
            setDisableButton(false);
          });
      })
      .catch((info) => {
        setDisableButton(false);
      });
  };

  const handleSearch = (newValue) => {
    setCollegeNameValue(newValue);
    setCollegeNameShow(true);
  };

  const handleChange = (newValue) => {
    setCollegeNameShow(false);
    setCollegeNameValue(newValue);
  };

  const handleSchoolSearch = (newValue) => {
    setSchoolNameValue(newValue);
    setSchoolNameShow(true);
  };

  const handleSchoolChange = (newValue) => {
    setSchoolNameShow(false);
    setSchoolNameValue(newValue);
  };

  const registerStyle = {
    r3Column1: {
      width: mobileView ? window.innerWidth : "45%",
      paddingLeft: mobileView ? "" : "60px",
      marginTop: "15px",
    },
    label: {
      marginLeft: "4px",
      fontSize: "14px",
      fontFamily: "Roboto",
      lineHeight: "1",
      fontWeight: "500",
      // color: '#7D7B7D'
    },
  };

  const onEnrolledInChanged = (e) => {
    setEnrolledInChanged(e);
  };

  const defaultValues = {
    highSchoolGradDate: highSchoolGradDate,
    collegeEnrolledDate: collegeEnrolledDate,
    collegeGraduationDate: collegeGraduationDate,
    interestedInOnline:
      userInfo?.applicantFields?.filter(
        (e) => e.fieldId === interestedInOnlineId[0]?.id
      )[0]?.fieldValue * 1,
    highSchoolName: userInfo?.applicantFields?.filter(
      (e) => e.fieldId === highSchoolNameId[0]?.id
    )[0]?.fieldValue,
    currentSchoolLevel: userInfo?.applicantFields?.filter(
      (e) => e.fieldId === currentSchoolLevelId[0]?.id
    )[0]?.fieldValue,
    fieldOfStudy: userInfo?.applicantFields?.filter(
      (e) => e.fieldId === fieldOfStudyId[0]?.id
    )[0]?.fieldValue,
    degreeType: userInfo?.applicantFields?.filter(
      (e) => e.fieldId === degreeTypeId[0]?.id
    )[0]?.fieldValue,
    gpa: userInfo?.applicantFields?.filter((e) => e.fieldId === gpaId[0]?.id)[0]
      ?.fieldValue,
    careerGoal: userInfo?.applicantFields?.filter(
      (e) => e.fieldId === careerGoalId[0]?.id
    )[0]?.fieldValue,
    militaryAffiliation: userInfo?.applicantFields?.filter(
      (e) => e.fieldId === militaryAffiliationId[0]?.id
    )[0]?.fieldValue,
    enrolledInCollege: userInfo?.applicantFields?.filter(
      (e) => e.fieldId === enrolledInCollegeId[0]?.id
    )[0]?.fieldValue,
    collegeName: userInfo?.applicantFields?.filter(
      (e) => e.fieldId === collegeNameId[0]?.id
    )[0]?.fieldValue,
    collegeName1: userInfo?.applicantFields?.filter(
      (e) => e.fieldId === interestedCollegesId[0]?.id
    )[0]?.fieldValue,
    collegeName2: userInfo?.applicantFields?.filter(
      (e) => e.fieldId === interestedCollegesId[0]?.id
    )[1]?.fieldValue,
    //collegeName3: userInfo?.applicantFields?.filter((e) => e.fieldId === interestedCollegesId[0]?.id)[2]?.fieldValue
  };

  const EnrolledInRender = (props) => {
    return (
      <>
        <Form.Item name={props.name} rules={rules.collegeName}>
          <Select
            size="large"
            style={{ color: "#7D7B7D" }}
            showSearch
            value={collegeNameValue}
            autoClearSearchValue={false}
            onSearch={handleSearch}
            onChange={handleChange}
            listItemHeight={10}
            mode="single"
            placeholder={"College Name"}
          >
            {!loading &&
              collegeName &&
              Object.keys(collegeName).map((option) => (
                <Select.Option value={collegeName[option]} key={option}>
                  {collegeName[option]}
                </Select.Option>
              ))}
            {collegeNameValue && collegeNameShow && (
              <Select.Option value={collegeNameValue}>
                {collegeNameValue}
              </Select.Option>
            )}
          </Select>
        </Form.Item>
      </>
    );
  };

  // useEffect(() => {
  //   form1.setFieldsValue(defaultValues);
  // }, [form1, defaultValues])

  if (loading || profileLoading)
    return (
      <div
        style={{
          position: "absolute",
          left: mobileView ? window.innerWidth / 2 : "520px",
          top: "241px",
        }}
      >
        <Spin size={"large"} />
      </div>
    );

  return (
    <div>
      {
        <div>
          <motion.div
            initial={{ opacity: 0, marginBottom: 0 }}
            animate={{
              opacity: alertShow ? 1 : 0,
              marginBottom: alertShow ? 20 : 0,
            }}
            style={{ padding: mobileView ? "0" : "0 25%" }}
          >
            <Alert
              type={alertType ? "success" : "error"}
              showIcon
              message={alertMessage}
            ></Alert>
          </motion.div>
        </div>
      }
      <Form
        form={form1}
        style={{ margin: "0 30px" }}
        layout="vertical"
        initialValues={{ ...defaultValues }}
        name="register-form-step3"
        onFinish={updateInfo}
      >
        <Row>
          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}>Current School Level</h2>
            <Form.Item
              name="currentSchoolLevel"
              rules={rules.currentSchoolLevel}
            >
              <Select
                size="large"
                style={{ color: "#7D7B7D" }}
                mode="single"
                placeholder={"Current school level"}
              >
                {!loading &&
                  schoolLevel &&
                  Object.keys(schoolLevel).map((option) => (
                    <Select.Option value={option} key={option}>
                      {schoolLevel[option]}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}>High School Name</h2>
            <Form.Item name="highSchoolName" rules={rules.highSchoolName}>
              <Select
                size="large"
                style={{ color: "#7D7B7D" }}
                mode="single"
                placeholder={"High School Name"}
                showSearch
                value={schoolNameValue}
                autoClearSearchValue={false}
                onSearch={handleSchoolSearch}
                onChange={handleSchoolChange}
                listItemHeight={10}
              >
                {!loading &&
                  highSchoolName &&
                  Object.keys(highSchoolName).map((option) => (
                    <Select.Option value={highSchoolName[option]} key={option}>
                      {highSchoolName[option]}
                    </Select.Option>
                  ))}
                {schoolNameValue && schoolNameShow && (
                  <Select.Option value={schoolNameValue}>
                    {schoolNameValue}
                  </Select.Option>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}>High School Graduation Date</h2>
            <Form.Item
              name="highSchoolGradDate"
              rules={dateRule.highSchoolGradDate}
              style={{ color: "#7D7B7D" }}
            >
              <SelectDatepicker
                selectedDate={highSchoolGradDate}
                maxDate={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() + 50)
                  )
                }
                order="month/year"
                labels={{
                  dayPlaceholder: "Day",
                  monthPlaceholder: "Month",
                  yearPlaceholder: "year",
                }}
                hideLabels={true}
                onDateChange={onHighSchoolGradDateChange}
              />
            </Form.Item>
          </Col>

          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}> Enrolled in college</h2>
            <Form.Item name="enrolledInCollege" rules={rules.enrolledInCollege}>
              <Radio.Group
                onChange={(e) => onEnrolledInChanged(e.target.value)}
              >
                <Radio value={"1"}>Yes</Radio>
                <Radio value={"0"}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}>College Enrolled Date</h2>
            <Form.Item
              name="collegeEnrolledDate"
              rules={dateRule.collegeEnrolledDate}
              style={{ color: "#7D7B7D" }}
            >
              <SelectDatepicker
                selectedDate={collegeEnrolledDate}
                maxDate={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() + 50)
                  )
                }
                labels={{
                  dayPlaceholder: "Day",
                  monthPlaceholder: "Month",
                  yearPlaceholder: "year",
                }}
                order="month/year"
                hideLabels={true}
                onDateChange={onCollegeEnrolledDateChange}
              />
            </Form.Item>
          </Col>

          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}>
              {enrolledInChanged == "1"
                ? "College Name"
                : "Potenital College Name"}
            </h2>
            {enrolledInChanged == "1"
              ? EnrolledInRender({
                  name: "collegeName",
                })
              : EnrolledInRender({
                  name: "collegeName1",
                })}
            {enrolledInChanged == "0" &&
              EnrolledInRender({
                name: "collegeName2",
              })}
          </Col>
        </Row>

        <Row>
          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}>
              Expected College Graduation Date
            </h2>
            <Form.Item
              name="collegeGraduationDate"
              rules={dateRule.collegeGraduationDate}
              style={{ color: "#7D7B7D" }}
            >
              <SelectDatepicker
                selectedDate={collegeGraduationDate}
                maxDate={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() + 50)
                  )
                }
                labels={{
                  dayPlaceholder: "Day",
                  monthPlaceholder: "Month",
                  yearPlaceholder: "year",
                }}
                order="month/year"
                hideLabels={true}
                onDateChange={onCollegeGraduationDateChange}
              />
            </Form.Item>
          </Col>

          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}>Field of Study</h2>

            <Form.Item name="fieldOfStudy" rules={rules.fieldOfStudy}>
              <Select
                size="large"
                style={{ color: "#7D7B7D" }}
                mode="single"
                placeholder={"Field of Study"}
              >
                {!loading &&
                  fieldOfStudy &&
                  Object.keys(fieldOfStudy).map((option) => (
                    <Select.Option value={option} key={option}>
                      {fieldOfStudy[option]}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}>Degree Type</h2>
            <Form.Item name="degreeType" rules={rules.degreeType}>
              <Select
                size="large"
                mode="single"
                placeholder={"Degree Type"}
                style={{ color: "#7D7B7D" }}
              >
                {!loading &&
                  degreeType &&
                  Object.keys(degreeType).map((option) => (
                    <Select.Option value={option} key={option}>
                      {degreeType[option]}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col style={registerStyle.r3Column1}>
            <Text
              style={{
                marginLeft: "4px",
                fontSize: "16px",
                fontWeight: "500",
                color: "black",
              }}
            >
              Marking System
            </Text>
            <div id="select-form-container">
              <Form.Item name="markingSystem">
                <Select
                  size="large"
                  mode="single"
                  placeholder="GPA"
                  defaultValue={markingSystem}
                  onChange={handleMarkingSystemChange}
                  style={{ color: "#7D7B7D" }}
                >
                  <Select.Option value="Percentage">Percentage</Select.Option>
                  <Select.Option value="10CGPA">10CGPA</Select.Option>
                  <Select.Option value="GPA">GPA</Select.Option>
                </Select>
              </Form.Item>
              {markingSystem !== "GPA" && (
                <Form.Item
                  name="userinput"
                  rules={rules.gpaInput}
                  style={{ color: "#7D7B7D" }}
                >
                  <Input
                    placeholder={`Enter ${
                      markingSystem === "GPA" ? "GPA" : markingSystem
                    }`}
                    onChange={handleUserInputChange}
                    value={userInput}
                  />
                </Form.Item>
              )}
              {renderGPAOptions()}
            </div>
          </Col>
          {/* <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}>GPA</h2>
            <Form.Item name="gpa" rules={rules.gpa}>
              <Select size="large" style={{}} mode="single" placeholder={"GPA"}>
                {!loading &&
                  gpa &&
                  Object.keys(gpa).map((option) => (
                    <Select.Option value={option} key={option}>
                      {gpa[option]}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col> */}
        </Row>

        <Row>
          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}>Career Goal</h2>
            <Form.Item name="careerGoal" rules={rules.careerGoal}>
              <Select
                size="large"
                mode="single"
                placeholder={"Career Goal"}
                style={{ color: "#7D7B7D" }}
              >
                {!loading &&
                  careerGoal &&
                  Object.keys(careerGoal).map((option) => (
                    <Select.Option value={option} key={option}>
                      {careerGoal[option]}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col style={registerStyle.r3Column1}>
            <h2 style={registerStyle.label}>Military Affiliation</h2>
            <Form.Item
              name="militaryAffiliation"
              rules={rules.militaryAffiliation}
            >
              <Select
                size="large"
                style={{ color: "#7D7B7D" }}
                mode="single"
                placeholder={"Military Affilation"}
              >
                {!loading &&
                  militaryAffiliation &&
                  Object.keys(militaryAffiliation).map((option) => (
                    <Select.Option value={option} key={option}>
                      {militaryAffiliation[option]}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col style={{ ...registerStyle.r3Column1 }}>
            <h2 style={registerStyle.label}>Interested in online study</h2>
            <Form.Item name="interestedInOnline">
              <Radio.Group>
                <Radio value={1}>Yes</Radio>
                <Radio value={2}>No</Radio>
                <Radio value={3}>May be</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col style={registerStyle.r3Column1} />
        </Row>
        <Row>
          <Col
            style={{
              ...registerStyle.r3Column1,
              width: window.innerWidth < 550 ? "100%" : "85%",
              border: "1px solid rgba(0, 0, 0, 0.25)",
              margin: window.innerWidth < 550 ? "" : "0 50px",
              padding: "25px",
              borderRadius: "7px",
            }}
          >
            <h2 style={{ ...registerStyle.label, marginTop: "2px" }}>
              Manage your data
            </h2>
            <Form.Item name="checkboxGroup">
              <div>
                <Checkbox value="agree as a resident of colarado">
                  I am a resident of Colorado, Connecticut, Virginia, or Utah
                </Checkbox>
              </div>
              <br />
              <div>
                <Checkbox
                  defaultChecked
                  disabled
                  value="agreed for use of information"
                >
                  I give consent for{" "}
                  <a
                    href="https://lenny.ai"
                    style={{ color: "rgb(141, 150, 255)" }}
                  >
                    {" "}
                    Lenny.ai
                  </a>{" "}
                  to collect, use, and disclose my ethnicity and citizenship
                  information to provide services to me as disclosed in its
                  Privacy Policy. I may withdraw this consent at any time.
                </Checkbox>
              </div>
              <br />
              <div>
                <Checkbox
                  defaultChecked
                  disabled
                  value="agreed for sell ethnicity"
                >
                  I give consent for{" "}
                  <a
                    href="https://lenny.ai"
                    style={{ color: "rgb(141, 150, 255)" }}
                  >
                    {" "}
                    Lenny.ai
                  </a>{" "}
                  to sell my ethnicity and citizenship information as disclosed
                  in its Privacy Policy. I may withdraw this consent at any
                  time.
                </Checkbox>
              </div>
            </Form.Item>
          </Col>
          <Col style={registerStyle.r3Column1} />
        </Row>

        <Form.Item style={{ textAlignLast: "center", color: "#7D7B7D" }}>
          <Button
            disabled={disableButton}
            type="primary"
            htmlType="submit"
            style={{
              width: "160px",
              backgroundColor: "#8D96FF",
              color: "#FFFFFF",
              border: "none",
              borderRadius: "6px",
              margin: "15px 0",
            }}
          >
            <span>Save Changes</span>
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EducationDetail;
