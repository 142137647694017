import { Grid } from "antd";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import utils from "utils";

const { useBreakpoint } = Grid;

export const MobileLogo = (props) => {
  const isMobile = utils.getBreakPoint(useBreakpoint()).includes("lg");
  const checkToken = localStorage.getItem("token");
  const history = useHistory();
  if (isMobile) {
    return <div />;
  }
  return (
    <div
      style={{
        marginRight: "20px",
        marginLeft: "10px",
        cursor: "pointer",
      }}
      onClick={() => {
        if (checkToken) {
          history.push("/auth/dashboard");
        } else {
          history.push("/");
        }
      }}
    >
      <img
        src="/new/oldLogo1.png"
        // src="/new/oldLogo.webp"

        alt="Lenny.AI"
        style={{ width: "80px" }}
      />
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType };
};

export default connect(mapStateToProps)(MobileLogo);
