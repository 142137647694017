import { Button, Col, Drawer, Row, Space, Upload } from "antd";
import { API_UPLOAD_URL } from "configs/AppConfig";
import React, { useState } from "react";
import UploadEssay from "../UploadEssay";
import useAnalyticsEventTracker from "useAnalyticsEventTracker";
import { UploadOutlined } from "@ant-design/icons";

const TextEditor = ({
  onChange,
  value,
  error,
  details,
  scholarshipId,
  ...moreProps
}) => {
  const [open, setOpen] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker("Scholarship Detail");
  const { field, title, description, optional } = details;

  const completionStatus =
    field.key === "essay"
      ? value?.text
        ? "Edit Essay"
        : "Effortless Essay Completion"
      : value?.text
      ? "Edit Answer"
      : "Effortless Question Completion";
  const uploadStatus =
    field.key === "essay"
      ? value?.url
        ? "Edit uploaded Essay"
        : "Upload an Essay"
      : value?.url
      ? "Edit uploaded Answer"
      : "Upload Answer";

  const onFileUploadClick = (e) => {
    let label = {
      screenName: "Scholarship Detail",
      scholarshipId,
    };
    gaEventTracker("Upload file clicked", JSON.stringify(label));
    onChange(e);
  };

  const showDrawer = () => {
    let label = {
      screenName: "Scholarship Detail",
      scholarshipId,
    };
    gaEventTracker("Write Lenny Easy clicked", JSON.stringify(label));

    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const uploadProps = !!value?.text
    ? {
        itemRender: () => null,
      }
    : {};

  return (
    <div>
      <div className="container">
        <Row>
          <Col>
            <Button
              type="primary"
              onClick={showDrawer}
              style={{
                backgroundColor: "rgb(141, 150, 255)",
                border: "none",
              }}
            >
              <Row style={{ justifyContent: "center" }}>
                <Col style={{ alignSelf: "center" }}>
                  <img
                    src="/applicantImg/write.png"
                    style={{ height: "18px", marginRight: "6px" }}
                  />
                </Col>
                <Col
                  style={{
                    textAlign: "left",
                    lineHeight: 0,
                    alignSelf: "center",
                  }}
                >
                  <h2
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: 0.5,
                      color: "#ffffff",
                    }}
                  >
                    {completionStatus}
                  </h2>
                  <span style={{ fontSize: "8px" }}>Lenny Assisted</span>
                </Col>
              </Row>
            </Button>
          </Col>
          <Col>
            <span
              style={{
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              or
            </span>
            <Upload
              action={API_UPLOAD_URL}
              accept=".doc,.docx,.pdf"
              maxCount={1}
              onChange={onFileUploadClick}
              onRemove={() => onChange(null)}
              showUploadList={{
                showRemoveIcon: false,
              }}
              {...moreProps}
              {...uploadProps}
            >
              <Button
                style={{
                  borderColor: "rgb(141, 150, 255)",
                }}
              >
                {/* <img
                  src="/applicantImg/upload.png"
                  style={{ height: "18px", marginRight: "4px" }}
                /> */}
                <UploadOutlined
                  style={{
                    color: "rgb(141, 150, 255)",
                  }}
                />
                <span style={{ color: "rgb(141, 150, 255)" }}>
                  {uploadStatus}
                </span>
              </Button>
            </Upload>
          </Col>
        </Row>
      </div>
      <Drawer
        placement="bottom"
        closable={false}
        onClose={onClose}
        visible={open}
        height="100vh"
      >
        <UploadEssay
          details={details}
          onSave={(val) => {
            onChange({ essay: val });
            onClose();
          }}
          onGoBack={onClose}
          existingValue={value?.text || (!value?.url ? value : "")}
          drawerOpen={open}
        />
      </Drawer>
    </div>
  );
};

export default TextEditor;
