import { Button, Col, Divider, Row, Space, Typography } from "antd";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { useState } from "react";
import fetch_ from "../../../interceptors/FetchInterceptor";
import useAnalyticsEventTracker from "../../../useAnalyticsEventTracker";
import Utils from "utils/common";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import Subscription from "components/layout-components/Subscription";
import SubscriptionMobileDesign from "components/layout-components/SubscriptionMobileDesign";

export const List = ({ data, type, isLastElement, history, props }) => {
  // console.log("🚀 ~ List ~ data:", data);
  const { Text } = Typography;
  const mobileView = window.innerWidth < 481;
  const phoneView = window.innerWidth < 962;

  const storedValue = localStorage.getItem("isActiveSubscription");

  // Now, storedValue is a string representation of the boolean
  // You can directly use it as a boolean without parsing
  const isActiveSubscription = storedValue === "true";
  // console.log(props.setSideBars())
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    if (isActiveSubscription) {
      history.push("/auth/dashboard/plan");
    } else {
      // setIsOpen(!isOpen);
      if (phoneView) {
        setIsOpen(!isOpen);
      } else {
        history.push("/auth/dashboard/subscription-plan");
      }
    }
  };
  const [tempData, setTempData] = useState({ ...data });
  const gaEventTracker = useAnalyticsEventTracker("Dashboard");

  const easyClick = () => {
    return;
    if (type === "recommendation") {
      let label = {
        section: "Recommendation",
      };
      gaEventTracker(" Easy Apply clicked", JSON.stringify(label));
      history.push("/auth/dashboard/recommendation");
    } else {
      let label = {
        section: "Easy Apply With Cv",
      };
      gaEventTracker(" Easy Apply clicked", JSON.stringify(label));
      history.push("/auth/dashboard/easyApplyWithCv");
    }

    return;
  };

  const viewDetail = () => {
    let label = {
      scholarshipId: data.scholarshipId || data.id,
      section: type,
    };
    gaEventTracker("Scholarship clicked", JSON.stringify(label));

    history.push({
      pathname: "/auth/scholarshipDetail",
      search: data.scholarshipId || data.id,
    });
    props.setSideBars(true);
    //window.location.reload();
  };

  const likeClick = () => {
    let label = {
      scholarshipId: data.scholarshipId || data.id,
      section: type,
      wishlist: tempData.wishlist.length > 0,
    };
    gaEventTracker("Wishlist clicked", JSON.stringify(label));

    if (tempData.wishlist.length > 0) {
      setTempData({ ...data, wishlist: [] });
      let scholarshipId = data.id;
      fetch_({
        url: `scholarships/remove-wishlist`,
        method: "PUT",
        data: { scholarshipId: scholarshipId },
      })
        .then(({ data }) => {})
        .catch((error) => {});
    } else {
      let scholarshipId = data.id;
      setTempData({ ...data, wishlist: [{ scholarshipId: scholarshipId }] });
      fetch_({
        url: `scholarships/add-wishlist`,
        method: "PUT",
        data: { scholarshipId: scholarshipId },
      })
        .then(({ data }) => {})
        .catch((error) => {});
    }
    // window.location.reload();
  };

  return (
    <div
      style={{
        width: "auto",
      }}
    >
      <Row
        style={{
          padding: "15px 0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Col
          style={{
            width: mobileView ? window.innerWidth : "50%",
            paddingRight: "10px",
          }}
        >
          <p>
            <Text
              style={{
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "16px",
                textAlign: "left",
                color: "#1D252D",
              }}
            >
              {data?.title || "No Name"}
            </Text>
          </p>
          <span style={{ display: "flex", alignItems: "center" }}>
            {Utils.formatAsUSD(
              (data?.amounts &&
                data.amounts.reduce((acc, curr) => acc + curr, 0)) ||
                data?.awards
            )}
            <img
              src="/applicantImg/watch.png"
              style={{ height: "12px", marginRight: "4px", marginLeft: "4px" }}
            />
            Deadline {data?.recurrenceConfig?.deadline}
          </span>
        </Col>
        <Row>
          <Space size={"middle"}>
            <Col
              style={{
                marginTop: mobileView && "10px",
              }}
            >
              <div
                style={{
                  //   border: "1px solid",
                  padding: "4px 7px 5px 7px",
                  //   width: "fit-content",
                  //   // background:
                  //   //   tempData?.wishlist?.length > 0 ? "#1778F22B" : "black",
                  //   // borderRadius: "5px",
                  //   // borderColor:
                  //   //   tempData?.wishlist?.length > 0 ? "#1778F2" : "#66666673",
                }}
                onClick={likeClick}
              >
                {tempData?.wishlist?.length > 0 ? (
                  <HeartFilled style={{ color: "#8D96FF", width: "24px" }} />
                ) : (
                  <HeartOutlined style={{ width: "24px", height: "24px" }} />
                )}
              </div>
            </Col>

            <Col
              style={{
                marginTop: mobileView && "10px",
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  if (isActiveSubscription === true) {
                    viewDetail();
                  } else {
                    togglePopup();
                  }
                }}
                style={{
                  background: "#8D96FF",
                  borderRadius: "8px",
                  borderColor: "#8D96FF",
                  height: "32px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0",
                }}
              >
                {/* {type === "easyApply" && (
                  <img
                    src="/applicantImg/tick.png"
                    style={{ height: "10px", marginRight: "4px" }}
                  />
                )} */}
                <span style={{ color: "#FFF" }}>
                  {/* {type !== "easyApply" ? "View Details" : "Easy Apply"} */}
                  View Details
                </span>
              </Button>
            </Col>
          </Space>
        </Row>
      </Row>

      {isOpen &&
        (phoneView ? (
          <div
            className="popup-overlay"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "24px",
                padding: "0px 20px",
              }}
            >
              Manav get a membership for free 7 days to activate automatic
              application to your 234 scholarship matches. Lets us do the hard
              work for you !
            </p>

            <SubscriptionMobileDesign
              toggleMobilePopup={togglePopup}
              redirect={true}
            />
          </div>
        ) : (
          <div className="popup-overlay">
            <div className="popup-content">
              <div
                onClick={togglePopup}
                style={{
                  color: "rgb(113, 122, 245)",
                  fontSize: "24px",
                  cursor: "pointer",
                  float: "right",
                  lineHeight: "normal",
                }}
              >
                {" "}
                <CloseCircleFilled />
              </div>
              <Subscription
                modalStyle={{
                  border: "none",
                  boxShadow: "none",
                  margin: "0px",
                }}
                redirect={true}
              />
            </div>
          </div>
        ))}
      {!isLastElement && <Divider style={{ margin: "0" }} />}
    </div>
  );
};
