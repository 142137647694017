import { Col, Row } from "antd";
import React from "react";
import DOMPurify from "dompurify";

const ScholarshipInfo = ({ scholarship }) => {
  const mobileView = window.innerWidth < 1200;
  const view = ({ icon, text1, text2, number }) => {
    return (
      <Col
        style={
          number !== "4"
            ? {
                width: mobileView ? "100%" : "25%",
                borderRightWidth: !mobileView && "1px",
                borderRightStyle: !mobileView && "double",
                borderRightColor: !mobileView && "rgba(29, 37, 45, 0.13)",
                marginLeft: number !== "1" && !mobileView && "20px",
                display: mobileView && "inline-flex",
                marginBottom: mobileView && "10px",
              }
            : {
                marginLeft: !mobileView && "20px",
                display: mobileView && "inline-flex",
                width: mobileView && "100%",
              }
        }
      >
        <img src={icon} style={{ height: "40px", marginRight: "14px" }} />
        <div style={{ width: "100%" }}>
          <h2 style={{ fontSize: "16px", marginBottom: "0px" }}>{text1}</h2>
          <span style={{ fontSize: "12px" }}>{text2}</span>
        </div>
      </Col>
    );
  };

  const buttonViews = () => {
    return (
      <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
        <Col
          style={{
            width: mobileView ? "100%" : "47%",
            background: "#EFFCEF",
            borderRadius: "12px",
          }}
        >
          <Row style={{ padding: "15px" }}>
            <Col style={{ alignSelf: "center" }}>
              <img
                src="/applicantImg/contact.png"
                style={{ height: "60px", marginRight: "4px" }}
              />
            </Col>
            <Col
              style={{
                textAlign: "left",
                paddingLeft: "5px",
                alignSelf: "center",
              }}
            >
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                BeArt-Presets
              </span>
              <br />
              <span style={{ fontSize: "14px" }}>Scholarship Provider</span>
            </Col>
          </Row>
        </Col>
        <Col style={{ width: "1%" }} />
        <Col
          style={{
            width: mobileView ? "100%" : "48%",
            marginTop: mobileView && "10px",
            background: "#FFEFE2",
            borderRadius: "12px",
          }}
        >
          <Row style={{ padding: "15px" }}>
            <Col style={{ alignSelf: "center" }}>
              <img
                src="/applicantImg/money.png"
                style={{ height: "60px", marginRight: "4px" }}
              />
            </Col>
            <Col
              style={{
                textAlign: "left",
                paddingLeft: "5px",
                alignSelf: "center",
              }}
            >
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                Funds are paid to college
              </span>
              <br />
              <span style={{ fontSize: "14px" }}>Winner payment</span>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  function extractTextFromHTML(html) {
    // Create a temporary element
    const tempElement = document.createElement("div");
    // Set the HTML content of the temporary element
    tempElement.innerHTML = html;
    // Return the text content of the temporary element
    return tempElement.textContent || tempElement.innerText || "";
  }

  return (
    <>
      <h2
        style={{
          font: "Inter",
          fontWeight: "700",
          fontSize: "22px",
          marginTop: "10px",
        }}
      >
        {scholarship.title || "No Name"}
      </h2>
      <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
        {view({
          icon: "/applicantImg/date.png",
          text1: scholarship?.recurrenceConfig?.deadline || "DD / MM / YYYY",
          text2: "Scholarship Deadline",
          number: "1",
        })}
        {view({
          icon: "/applicantImg/icon2.png",
          text1:
            (scholarship?.amounts &&
              scholarship.amounts.reduce((acc, curr) => acc + curr, 0)) ||
            scholarship?.awards,
          text2: "Budget",
          number: "2",
        })}
        {/* {view({
          icon: "/applicantImg/phone.png",
          text1: scholarship?.ccWinnerEmail?.length,
          text2: "Estimated Participants",
          number: "3",
        })} */}
        {view({
          icon: "/applicantImg/file.png",
          text1: scholarship?.requirements?.length,
          text2: "Requirement",
          number: "4",
        })}
      </Row>
      <h3
        style={{
          marginBottom: "0px",
          fontWeight: "600",
          fontFamily: "Poppins",
        }}
      >
        Description
      </h3>
      <p style={{ color: "black", fontWeight: "400" }}>
        {extractTextFromHTML(DOMPurify.sanitize(scholarship?.description))}
      </p>
      {/* <p
        style={{ color: "black", fontWeight: "400" }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(scholarship?.description),
        }}
      ></p> */}
      {false && buttonViews()}
    </>
  );
};

export default ScholarshipInfo;
