/* eslint-disable import/no-anonymous-default-export */
const mobileView = window.innerWidth < 481;
const tabViewLandscape = window.innerWidth < 1181;

export default {
  button: {
    background: "#F1F6FE",
    borderRadius: "6px",
    borderColor: "#F1F6FE",
    height: "32px",
    width: "123px",
    marginTop: "15px",
    paddingTop: "5px",
  },

  videoBorder: {
    padding: "25px",
    borderRadius: "10px",
    borderWidth: "1px",
    borderStyle: "double",
    width: tabViewLandscape ? "-webkit-fill-available" : "32%",
    borderColor: "rgba(29, 37, 45, 0.13)",
    marginTop: mobileView && "10px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  easyApplyScholarship: {
    width: mobileView ? "-webkit-fill-available" : "-webkit-fill-available", //49.5%
    // width: mobileView ? "-webkit-fill-available" : "99.5%", //49.5%
    background: "#F0F1FF",
    borderColor: "rgba(29, 37, 45, 0.13)",
    padding: "15px",
    borderRadius: "10px",
    marginTop: "10px",
    // height: "400px",
    // overflowY: "scroll",
    // overflowX: "hidden",
  },

  featureText: {
    background: "#FDF7DE",
    padding: "10px 0 10px 25px",
    borderRadius: "10px",
    paddingRight: "10px",
    width: tabViewLandscape ? "-webkit-fill-available" : "50%",
    marginBottom: tabViewLandscape ? "10px" : "0px",
    // width: tabViewLandscape ? "-webkit-fill-available" : "34%",
  },

  featureText2: {
    background: "#DEFDE1",
    padding: "10px 0 10px 25px",
    borderRadius: "10px",
    paddingRight: "10px",
    width: tabViewLandscape ? "-webkit-fill-available" : "48%",
    // width: tabViewLandscape ? "-webkit-fill-available" : "32%",
  },

  featureText3: {
    background: "#DEF4FD",
    padding: "10px 0 10px 25px",
    borderRadius: "10px",
    paddingRight: "10px",
    width: tabViewLandscape ? "-webkit-fill-available" : "32%",
  },

  chatMiniView: {
    width: window.innerWidth < 1181 ? "-webkit-fill-available" : "30%",
  },
  scrollbar: {
    height: tabViewLandscape ? "800px" : "415px",
    width: "100%",
    // width: tabViewLandscape ? "100%" : "70%",
    marginBottom: tabViewLandscape ? "10px" : "0px",
  },
};
