import { message } from "antd";
import fetch_ from "interceptors/FetchInterceptor";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ApplyScholarshipPopup = ({ toggleScholarshipPopup }) => {
  const history = useHistory();
  const mobileView = window.innerWidth < 750;
  const scholarshipId = "4473f7dd-aa2a-4040-af39-221756b619b0";
  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }
  const applyToLennyScholarship = () => [
    fetch_({
      url: `/register-applicants/apply-scholarship/${scholarshipId}`,
      method: "POST",
    })
      .then(({ data }) => {
        console.log("🚀 ~ .then ~ data:", data);
        if (data?.msg === "Successfully applied for scholarship") {
          userInfo.isScholarshipApplied = true;
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          //show a message
          // message.success("Successfully applied for Lenny's Scholarship!!!");
          toggleScholarshipPopup();
        } else {
          toggleScholarshipPopup();
          message.error(
            data?.msg || "Failed to apply for scholarship. Please try again.))"
          );
          if (data?.msg === "Already applied for scholarship") {
            userInfo.isScholarshipApplied = true;
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
          }
          if (data?.msg === "Please update your profile") {
            history.push("/auth/userdetails");
          }
        }
      })
      .catch((error) => {
        console.log("🚀 ~ ApplyScholarshipPopup ~ error:", error);
      }),
  ];
  return (
    <div>
      <div
        style={{
          backgroundImage: "url('/images/grp-bg.png')",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            marginTop: "25px",
          }}
        >
          Congratulations{" "}
          <span>
            <img src="/images/cone.png" alt="" />
          </span>{" "}
        </h1>
        <div
          style={{
            width: mobileView ? "200px" : "240px",
            margin: "auto",
          }}
        >
          <img
            src="/images/robo.png"
            alt="robo"
            style={{
              width: "100%",
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontWeight: "400",
            color: "black",
            fontSize: mobileView ? "20px" : "25px",
            margin: "0",
            textAlign: "center",
          }}
        >
          You have successfully entered the
        </p>
        <p
          style={{
            fontWeight: "700",
            color: "#8D96FF",
            fontSize: mobileView ? "22px" : "28px",
            margin: "0 0 5px 0",
            textAlign: "center",
          }}
        >
          Lenny's The Future is Now Scholarship Sweepstakes
        </p>
        <button
          style={{
            borderRadius: "8px",
            width: "max-content",
            padding: "0",
            backgroundColor: "#8D96FF",
            color: "white",
            fontSize: mobileView ? "13px" : "17px",
            fontWeight: "500",
            padding: "13px 18px",
            lineHeight: "normal",
            marginBottom: "15px",
            border: "none",
            cursor: "pointer",
          }}
          onClick={applyToLennyScholarship}
        >
          APPLY TO MORE SCHOLARSHIPS NOW
        </button>
      </div>
    </div>
  );
};

export default ApplyScholarshipPopup;
