import { Button, Form, Input, Modal, Typography, message } from "antd";
import React, { useState } from "react";
import { Switch } from "antd";
import {
  RightOutlined,
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
// import Subscription from "components/layout-components/Subscription";
import "./style.css";
import { useHistory } from "react-router-dom";
import useAnalyticsEventTracker from "useAnalyticsEventTracker";
import { UserAuthService } from "services/user-auth.service";
import fetch_ from "interceptors/FetchInterceptor";

const { Title, Text, Paragraph, Link } = Typography;

const Support = ({ props }) => {
  const history = useHistory();
  // const mobileView = window.innerWidth < 1200;
  // const [form] = Form.useForm();

  const [checked, setChecked] = useState(false);
  const storedValue = localStorage.getItem("isActiveSubscription");

  // Now, storedValue is a string representation of the boolean
  // You can directly use it as a boolean without parsing
  const isActiveSubscription = storedValue === "true";

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    console.log("cancelled");
    setIsModalVisible(false);
  };

  const handleSubmit = () => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      message.error("Please fill in all fields");
    } else if (newPassword !== confirmPassword) {
      message.error("New password and confirm password do not match!");
    } else {
      // setIsModalVisible(false);
      setLoading(true);
      const user = JSON.parse(localStorage.getItem("userInfo"));
      fetch_({
        url: `/register-applicants/${user.id}/update-password`,
        method: "PUT",
        data: {
          lastPwd: oldPassword,
          password: newPassword,
        },
      })
        .then((data) => {
          // console.log(data.statusCode);
          if (data.statusCode == 200) {
            setLoading(false);
            message.success("Password changed successfully");
            setTimeout(() => {
              setIsModalVisible(false);
            }, 500); // Close the modal after 500ms
          } else {
            message.error("Old Password Not Matched");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error setting password", error);
        });

      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }
  };

  const onChange = (checked) => {
    // console.log(`switch to ${checked}`);
    setChecked(checked);
  };

  const gaEventTracker = useAnalyticsEventTracker("Setting");

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-around",
      }}
    >
      <p
        style={{
          color: "black",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontFamily: "Poppins",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
        }}
      >
        {" "}
        <ArrowLeftOutlined
          style={{
            fontSize: "17px",
            marginRight: "15px",
          }}
          onClick={() => {
            history.push("/auth/dashboard");
          }}
        />
        Settings
      </p>
      <div
        style={{
          padding: "20px",
          width: "588px",
          height: "80px",
          flexShrink: "0",
          borderRadius: "8px",
          background: "#F1F6FE",
          display: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // width: '50%'
          }}
        >
          <Text
            style={{
              color: "black",
              fontFamily: "DM Sans",
              fontSize: "14px",

              fontWeight: "500",
              lineHeight: "normal",
            }}
          >
            Email Notification
          </Text>
          <div style={{ width: "20px" }}>
            <Switch checked={checked} onChange={onChange} size="small" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // width: '50%'
          }}
        >
          <Text
            style={{
              color: "black",
              fontFamily: "DM Sans",
              fontSize: "14px",

              fontWeight: "500",
              lineHeight: "normal",
            }}
          >
            Push Notification
          </Text>
          <div style={{ width: "20px" }}>
            <Switch checked={checked} onChange={onChange} size="small" />
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "8px",
          width: "50%",
          flexShrink: "0",
          borderRadius: "8px",
          background: "rgb(241, 246, 254)",
          margin: "10px 0",
        }}
        className="password_width"
      >
        <div
          style={{
            backgroundColor: "rgb(241, 246, 254)",
            display: "flex",
            justifyContent: "space-between",
            color: "black",
            fontFamily: "DM Sans",
            fontSize: "14px",
            paddingLeft: "10px",
            cursor: "pointer",
          }}
          className=""
          onClick={() => {
            // console.log('button clicked')
            setIsModalVisible(true);
          }}
        >
          Change Password
          <div>
            <RightOutlined />
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "8px",
          width: "50%",
          flexShrink: "0",
          borderRadius: "8px",
          background: "rgb(241, 246, 254)",
          margin: "5px 0",
        }}
        className="password_width"
      >
        <div
          style={{
            backgroundColor: "rgb(241, 246, 254)",
            display: "flex",
            justifyContent: "space-between",
            color: "black",
            fontFamily: "DM Sans",
            fontSize: "14px",
            paddingLeft: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            let label = {
              buttonTitle: isActiveSubscription
                ? "View Subscription Plan"
                : "Buy Subscription Plan",
            };
            gaEventTracker(
              "Subscription Button clicked ",
              JSON.stringify(label)
            );

            const destination = isActiveSubscription
              ? "/auth/dashboard/plan"
              : "/auth/dashboard/subscription-plan";
            history.push(destination);
            //history.push("/auth/dashboard/plan");
          }}
        >
          {isActiveSubscription
            ? "View Subscription Plan"
            : "Buy Subscription Plan"}{" "}
          <div>
            <RightOutlined />
          </div>
        </div>
      </div>

      <Modal
        title={
          <span
            style={{
              fontFamily: "DM Sans",
              fontSize: "24px",
              fontWeight: "400",
            }}
          >
            Change Password
          </span>
        }
        visible={isModalVisible}
        onCancel={handleClose}
        width={415}
        footer={null}
        closeIcon={<CloseOutlined />}
        closable={true}
        style={{ borderRadius: "25px" }}
        className="custom-modal"
      >
        <label
          style={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "27px",
            letterSpacing: "0.25px",
          }}
        >
          Enter Old Password
        </label>
        <Input.Password
          placeholder="•••••"
          value={oldPassword}
          required
          onChange={(e) => setOldPassword(e.target.value)}
          className="inputBg"
        />

        <label
          tyle={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "27px",
            letterSpacing: "0.25px",
          }}
        >
          New Password
        </label>
        <Input.Password
          placeholder="•••••"
          value={newPassword}
          required
          onChange={(e) => setNewPassword(e.target.value)}
          className="inputBg"
        />

        <label
          tyle={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "27px",
            letterSpacing: "0.25px",
          }}
        >
          Confirm Password
        </label>
        <Input.Password
          placeholder="•••••"
          value={confirmPassword}
          required
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="inputBg"
        />

        <Button
          onClick={handleSubmit}
          style={{
            borderRadius: "8px",
            background: "rgb(145, 154, 253)",
            color: "#FFF",
            fontFamily: "DM Sans",
            fontSize: "16px",
            lineHeight: "normal",
            width: "35%",
            margin: "0 auto",
            display: "block",
          }}
          loading={loading}
        >
          Submit
        </Button>
      </Modal>
    </div>
  );
};

export default Support;
