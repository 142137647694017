import React from "react";
import "./style.css";
import Footer from "../footer";

const Index = () => {
  return (
    <div>
      <div
        className="pricing_container"
        style={{
          background:
            "linear-gradient(180deg, #DFE2FF 0%, #FFF 60.3%), url(/img/pricing/bg.png)",
        }}
      >
        <div className="box1">
          <img
            src="/img/aboutus/robo.png"
            alt="robo"
            style={{
              width: "363px",
            }}
          />
        </div>
        <div
          className="box2 headd"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "65px",
            paddingRight: "100px",
          }}
        >
          <h2>We are lenny.AI</h2>
          <p>
            Lenny.AI is on a Mission to Apply All Students Everywhere to the
            perfect Opportunities and Financial Aid. Lenny uses AI technology
            and Machine Learning to find, filter, match and apply students to
            the perfect matches automatically.And because Lenny is so smart, you
            can actually have useful and meaningful conversations with it, while
            Lenny wll do all the heavy lifting and annoying parts off your
            shoulder and run it on auto-pilot.
            <br />
            Lenny also helps Brands, Companies, Funds, and Individuals to
            create, launch, manage, and distribute awards to their desired
            target audience. <br /> <br />
            Lenny is the brainchild of EduTech and FinTech veteran Ken
            Sandorffy, who previously founded ScholarshipOwl, and later managed
            VentureKite ( part of College Savings Holdings).
          </p>
        </div>
      </div>
      <div className="reviewImage">
        <div>
          <img
            src="/img/aboutus/review.png"
            alt=""
            style={{
              width: "100%",
            }}
          />
        </div>
      </div>
      <h4
        style={{
          color: "black",
          textAlign: "center",
          fontFamily: "Poppins",
          fontSize: "32px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px",
        }}
      >
        Our Team
      </h4>
      <div className="aboutUs">
        <div className="aboutBox">
          <div
            className="about_innerBox"
            style={{
              backgroundImage: 'url("/img/aboutus/full.png")',
              backgroundSize: "cover", // Ensures the background image covers the entire div
              borderRadius: "52px",
              width: "75%",
              margin: "auto",
              overflow: "hidden", // Ensures the content doesn't overflow
            }}
          >
            <div className="ceoImgCont">
              <div className="ceoImg">
                <div>
                  <img src="/img/aboutus/kenny.png" alt="" />
                </div>
                <div className="str">
                  <a href="https://www.linkedin.com/in/kenny-sandorffy-1a387132/">
                    {" "}
                    <h3
                      style={{
                        color: "#FFF",
                        fontFamily: "Poppins",
                        fontSize: "28px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                        textDecorationLine: "underline",
                        margin: "0px",
                      }}
                    >
                      Ken Sandorffy
                    </h3>
                  </a>
                  <p
                    style={{
                      color: "#FFF",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                    }}
                  >
                    CEO, Founder
                  </p>
                </div>
              </div>
              <div
                className="imgDesc"
                style={{
                  padding: "10px",
                }}
              >
                <p>
                  General Manager of VentureKite. Founder of ScholarshipOwl.
                  Marketing Director of AvaTrade. Built and managed Products,
                  High Performance Teams, and Marketing Budgets in varying
                  Market Conditions with a focus on Growth and ROI.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="aboutBox">
          <div
            className="about_innerBox"
            style={{
              backgroundImage: 'url("/img/aboutus/full.png")',
              backgroundSize: "cover", // Ensures the background image covers the entire div
              borderRadius: "52px",
              // border: '2px solid red',
              width: "75%",
              margin: "auto",
              overflow: "hidden", // Ensures the content doesn't overflow
            }}
          >
            <div className="ceoImgCont">
              <div className="ceoImg">
                <div>
                  <img src="/img/aboutus/sonia.png" alt="" />
                </div>
                <div className="str">
                  <a href="https://www.linkedin.com/in/sonia-yadav-55109913/">
                    {" "}
                    <h3
                      style={{
                        color: "#FFF",
                        fontFamily: "Poppins",
                        fontSize: "28px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                        textDecorationLine: "underline",
                        margin: "0px",
                      }}
                    >
                      Sonia Yadav
                    </h3>
                  </a>
                  <p
                    style={{
                      color: "#FFF",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                    }}
                  >
                    CTO, VP R&D
                  </p>
                </div>
              </div>
              <div
                className="imgDesc"
                style={{
                  padding: "10px",
                }}
              >
                <p>
                  A proud tech enthusiast with over 11+ years of extensive
                  experience in creating remarkable digital products like web
                  and mobile apps and leading world-class teams. With a global
                  presence in South East Asia, the USA, and India, Sonia brings
                  a wealth of expertise to the table.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="testimonial"
        style={{
          marginTop: "10px",
        }}
      >
        <h3
          style={{
            color: "black",
            fontFamily: "Poppins",
            fontSize: "27px",
            fontStyle: "normal",
            lineHeight: "24px",
            textAlign: "center",
          }}
        >
          This is what students say about Lenny.AI
        </h3>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <img src="/img/aboutus/test1.png" alt="" width="350px" />
          </div>
          <div>
            <img
              src="/img/aboutus/test2.png"
              alt=""
              width="450px"
              className="test2"
            />
          </div>
          <div>
            <img src="/img/aboutus/test3.jpg" alt="" width="350px" />
          </div>
        </div>
      </div>
      <div
        className="remoteDiv"
        style={{
          background:
            'url("/img/aboutus/layers.png"), linear-gradient(to right, #a0a6ff, white)',
          backgroundRepeat: "no-repeat",
          display: "flex",
          flexWrap: "wrap",
          marginTop: "10px",
        }}
      >
        <div
          className="remoteBox"
          style={{
            textAlign: "center",
          }}
        >
          <img
            src="/img/aboutus/dots.png"
            alt=""
            style={{
              position: "relative",
              left: "-158px",
              top: "-188px",
            }}
            className="dots"
          />
          {/* <img src="/img/aboutus/layers.png" alt="" /> */}
          <img
            src="/img/aboutus/robo.png"
            style={{
              width: "305px",
              position: "relative",
              top: "-45px",
            }}
            alt=""
          />
        </div>
        <div
          className="remoteBox"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            paddingRight: "125px",
          }}
        >
          <p
            style={{
              color: "black",
              fontFamily: "Poppins",
              fontSize: "32px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "48px",
            }}
          >
            Remote first culture
          </p>
          <p
            style={{
              color: "black",
              fontFamily: "Poppins",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "32px",
            }}
          >
            The headquarters of Lenny.AI are in the United States, but over the
            course of 5 years, we realized that to find the best talent, you
            need to forget about borders. We now have over 30 members in our
            team, all working remotely - in 4 continents and 10 countries.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Index;
