import React, { useRef } from "react";
import Navbar from "./Navbar";
import Scrollbars from "react-custom-scrollbars-2";
import ChatBot from "components/layout-components/ChatBot";

const Index = () => {
  const mobileView = window.innerWidth < 915;
  const chatnav1Ref = useRef(null);

  return (
    <div>
      <Navbar />
      <div className="chatNavContainer">
        <div
          className="chatNav1"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: mobileView ? "0 10px" : "0 40px",
          }}
          ref={chatnav1Ref}
        >
          <h2
            style={{
              fontSize: mobileView ? "20px" : "40px",
              marginTop: "25px",
              fontWeight: "500",
              lineHeight: "normal",
              color: "black",
            }}
          >
            Congratulations!!!
          </h2>

          <p
            style={{
              color: "black",
              // color: "#373636",
              fontSize: mobileView ? "14px" : "20px",
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            You have the opportunity to apply for numerous scholarships, worth
            of millions awaiting you!
          </p>

          <div
            style={{
              backgroundImage: 'url("/new/chatNavbg.png")',
              backgroundSize: "cover",
              width: "100%",
              // display: "flex",
              alignItems: "center",
              justifyContent: "center",
              display: mobileView ? "none" : "flex",
            }}
          >
            <img
              src="/new/chatNavRobo.png"
              alt=""
              style={{
                width: "50%",
                // height: "485px",
                // margin: '130px 0 0 0'
              }}
            />
          </div>
        </div>
        <div
          style={{
            flexGrow: "1",
            flexShrink: "1",
            // flexBasis: '50%'
            flexBasis: mobileView ? "100%" : "50%",
            // height: 'calc(100vh - 61px)'
          }}
        >
          <ChatBot chatnav1Ref={chatnav1Ref} />
        </div>
      </div>
    </div>
  );
};

export default Index;
