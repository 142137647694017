import React, { useState, useEffect } from "react";

function MonthYearPicker({ minYear, maxYear, onDateChange, initialValue }) {
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  useEffect(() => {
    if (initialValue) {
      const date = new Date(initialValue);
      setMonth(date.getMonth() + 1); // +1 because getMonth() returns month index (0-11)
      setYear(date.getFullYear());
    }
  }, [initialValue]);

  useEffect(() => {
    if (month && year) {
      const selectedDate = new Date(
        `${year}-${month.toString().padStart(2, "0")}-01`
      );
      onDateChange(selectedDate);
    }
  }, [month, year, onDateChange]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = Array.from(
    { length: maxYear - minYear + 1 },
    (_, index) => minYear + index
  );

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  return (
    <div style={{ display: "flex" }}>
      <select
        value={month}
        onChange={handleMonthChange}
        aria-label="Select Month"
        style={{ marginRight: "10px" }}
      >
        <option value="">Select Month</option>
        {months.map((m, index) => (
          <option key={index} value={index + 1}>
            {m}
          </option>
        ))}
      </select>

      <select
        value={year}
        onChange={handleYearChange}
        aria-label="Select Year"
        style={{ marginRight: "10px" }}
      >
        <option value="">Select Year</option>
        {years.map((y) => (
          <option key={y} value={y}>
            {y}
          </option>
        ))}
      </select>
    </div>
  );
}

export default MonthYearPicker;
