import { Modal, Typography } from "antd";
import React from "react";

const ThankYouModal = ({ open, handleOnCloseModal }) => {
  return (
    <Modal visible={open} onCancel={handleOnCloseModal} footer={null}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          className="img-fluid"
          src="/img/others/img-22.png"
          alt=""
          height="140"
          width="140"
        />
        <Typography.Title level={2} className=".my-4">
          Thank You!
        </Typography.Title>
        <Typography.Paragraph level={1}>
          Thanks for submitting the application!
        </Typography.Paragraph>
      </div>
    </Modal>
  );
};

export default ThankYouModal;
