import { Alert, Button, Col, Form, Row, Input } from "antd";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import country_splitter from "country-code-splitter";
import { motion } from "framer-motion";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { showAuthMessage, showLoading } from "redux/actions/Auth";
import { UserAuthService } from "services/user-auth.service";
import en from "world_countries_lists/data/countries/en/world.json";
import useAnalyticsEventTracker from "../../../../useAnalyticsEventTracker";
import "../register.scss";
import rules from "../rules.js";

const ContactDetail = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const userInfoString = localStorage.getItem("userInfo");
  const gaEventTracker = useAnalyticsEventTracker("Profile");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  const [alertShow, setAlertShow] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [alertType, setAlertType] = useState(true);
  //const formValues = JSON.parse(localStorage.getItem('register3'));
  const mobileView = window.innerWidth < 1200;

  const getFlag = (short) => {
    const data = require(`world_countries_lists/data/flags/24x24/${short.toLowerCase()}.png`);
    if (typeof data === "string") {
      return data;
    }
    // for CRA
    return data.default;
  };

  const onSignUp = () => {
    setDisableButton(true);
    let label = {
      screenName: "Profile Contact Detail",
    };
    gaEventTracker("User Update clicked", JSON.stringify(label));
    form
      .validateFields()
      .then((values) => {
        let postData = {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          email: values.email,
          dob: userInfo.dob,
          applicantType: userInfo.applicantType,
          applicantFields: userInfo.applicantFields.map((e) => {
            return {
              fieldId: e.fieldId,
              fieldValue: e.fieldValue,
            };
          }),
        };
        if (values.contactNo.code && values.contactNo.phone) {
          postData.phone = "+" + values.contactNo.code + values.contactNo.phone;
        }

        UserAuthService.updateUserApplicant(userInfo.id, postData)
          .then((data) => {
            if (data.statusCode == 200) {
              localStorage.setItem(
                "userInfo",
                typeof data.data.applicant === "string"
                  ? data.data.applicant
                  : JSON.stringify(data.data.applicant)
              );
            }
            setAlertShow(true);
            setAlertType(true);
            setAlertMessage("User updated successfully");
            showAuthMessage("Your account has been successfully registered.");
          })
          .catch((info) => {
            setAlertShow(true);
            setAlertMessage(info.description || "Server Error");
            setAlertType(false);
          })
          .finally(() => {
            showLoading(false);
            setDisableButton(false);
          });
      })
      .catch((info) => {
        setDisableButton(false);
      });
  };

  const registerStyle = {
    r3Column1: {
      width: mobileView ? window.innerWidth : "40%",
      paddingLeft: "60px",
    },
  };

  const defaultValues = {
    email: userInfo.email,
    contactNo: userInfo.phone
      ? {
          code: country_splitter?.getNumber(userInfo?.phone)?.code
            ? country_splitter?.getNumber(userInfo?.phone)?.code?.slice(1) * 1
            : 1,
          phone: country_splitter?.getNumber(userInfo?.phone)?.number,
        }
      : {
          code: "",
          phone: "",
        },
  };

  return (
    <div>
      {
        <div
          style={{
            width: mobileView ? "100%" : "50%",
            padding: mobileView ? "0 5%" : "",
          }}
        >
          <motion.div
            initial={{ opacity: 0, marginBottom: 0 }}
            animate={{
              opacity: alertShow ? 1 : 0,
              marginBottom: alertShow ? 20 : 0,
            }}
            style={{ padding: mobileView ? "0" : "0 25%" }}
          >
            <Alert
              type={alertType ? "success" : "error"}
              showIcon
              message={alertMessage}
            ></Alert>
          </motion.div>
        </div>
      }
      <Form
        form={form}
        style={{ color: "#7D7B7D", paddingLeft: "25px" }}
        layout="vertical"
        initialValues={defaultValues}
        name="register-form-step3"
        onFinish={onSignUp}
      >
        <Row>
          <Col
            style={{
              width: mobileView ? "100%" : "40%",
              padding: mobileView ? "0 5%" : "",
            }}
          >
            <ConfigProvider
              locale={en}
              areaMapper={(area) => {
                return {
                  ...area,
                  emoji: (
                    <img
                      alt="flag"
                      style={{
                        width: 18,
                        height: 18,
                        verticalAlign: "sub",
                      }}
                      src={getFlag(area.short)}
                    />
                  ),
                };
              }}
            >
              <Form.Item
                name="contactNo"
                label="Phone number"
                rules={rules.contactNo}
                hasFeedback
              >
                <CountryPhoneInput />
              </Form.Item>
            </ConfigProvider>
          </Col>
          <Col
            style={{
              width: "2%",
            }}
          />
          <Col
            style={{
              width: mobileView ? "100%" : "40%",
              padding: mobileView ? "0 5%" : "",
            }}
          >
            <Form.Item
              name="email"
              label="Email"
              rules={rules.email}
              hasFeedback
            >
              <Input
                style={{ borderRadius: "0px", height: "40px" }}
                placeholder=" Email"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ textAlignLast: "center" }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "160px",
              backgroundColor: "#8D96FF",
              color: "#FFFFFF",
              border: "none",
              borderRadius: "6px",
              margin: "10px 0",
            }}
            disabled={disableButton}
            block
          >
            <span>Save Changes</span>
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ContactDetail;
