import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Spin,
  Typography,
  Divider,
} from "antd";
import Utils from "utils/common";
import { UserAuthService } from "services/user-auth.service";
import { useHistory } from "react-router-dom";
import useAxios from "axios-hooks";
import registerStyle from "./registerStyle.js";
import { motion } from "framer-motion";
import { showLoading } from "redux/actions/Auth";
import rules from "./rules.js";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import "antd/dist/antd.css";
import "antd-country-phone-input/dist/index.css";
import useAnalyticsEventTracker from "../../../useAnalyticsEventTracker";
import "./register.scss";

const RegisterStep1 = () => {
  const { Text } = Typography;
  const mobileView = window.innerWidth < 961;
  const [form] = Form.useForm();
  const history = useHistory();
  const [checkBox1, setCheckBox1] = useState("");
  const [country, setCountry] = useState("");
  const [checkBoxTerm, setcheckBoxTerm] = useState("");
  const formValues = localStorage.getItem("register1")
    ? JSON.parse(localStorage.getItem("register1"))
    : {};
  const [alertShow, setAlertShow] = useState();
  const [, setDisableButton] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [alertType, setAlertType] = useState(true);
  const originaleligibiltyValues = localStorage.getItem("register2")
    ? JSON.parse(localStorage.getItem("register2"))
    : {};
  const fieldMappings = {
    "b9a172af-372b-4208-9036-470f5ff39b9b": "currentSchoolLevel",
    "6a9b2a3d-30f8-4980-af37-0cec301577e9": "fieldOfStudy",
    "8cba9cc2-3813-11ee-9f7a-16dca43b6f0b": "dob",
  };

  // Create updated eligibilityValues object
  const eligibilityValues = {};
  for (const fieldId in fieldMappings) {
    const originalField = fieldMappings[fieldId];
    eligibilityValues[`field.${fieldId}`] =
      originaleligibiltyValues[originalField];
  }

  //const eligibilityFiltersQueryParam = eligibilityValues;

  //  const [{ data: stats = { counts: 0, totalValue: 0 } }] = useAxios(
  //   `scholarships/eligible-scholarships/stats?eligiblityFilters=${eligibilityFiltersQueryParam}`,
  //   {
  //     headers: {
  //       accept: "*/*"
  //     }
  //   }
  // );

  const [stats, setStatsData] = useState({
    counts: 0,
    totalValue: 0,
  });

  useEffect(() => {
    getEligibilityStats();
  }, []);

  const getEligibilityStats = () => {
    const filter = {
      eligiblityFilters: eligibilityValues,
    };
    // console.log("filter", filter);
    return UserAuthService.getEligibilityStats(filter)
      .then((response) => {
        // console.log("fields data ", response);
        setStatsData(response.data);
      })
      .finally();
  };
  const gaEventTracker = useAnalyticsEventTracker("Signup Detail");
  const registerConversion = () => {
    if (window.trackdesk) {
      window.trackdesk("lennyai", "conversion", {
        conversionType: "lead",
        amount: {
          value: "0",
        },
        customParams: {
          advS1: "Account created",
        },
      });
    }
  };
  const onSignUp = () => {
    let label = {
      screenName: "signup ",
    };
    gaEventTracker("Account clicked", JSON.stringify(label));

    if (checkBoxTerm && checkBox1) {
      setDisableButton(true);

      form
        .validateFields()
        .then((values) => {
          let postData = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: "+" + values.contactNo.code + values.contactNo.phone,
            password: values.password,
            applicantType: "STUDENT",
            isProfileUpdate: "false",
            isScholarshipApplied: "false",
          };
          localStorage.setItem("register1", JSON.stringify(values));
          window.fbq("track", "Lead", {
            value: 0.05,
            currency: "$",
          });
          UserAuthService.register(postData)
            .then((data) => {
              // console.log("🚀 ~ .then ~ data:", data.data.clientIp);
              localStorage.setItem("clientIp", data?.data?.clientIp);
              if (data.statusCode === 201) {
                registerConversion();
                localStorage.setItem(
                  "refresh_token",
                  typeof data.data.refreshToken === "string"
                    ? data.data.refreshToken
                    : JSON.stringify(data.data.refreshToken)
                );
                localStorage.setItem(
                  "token",
                  typeof data.data.accessToken === "string"
                    ? data.data.accessToken
                    : JSON.stringify(data.data.accessToken)
                );
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify(data.data.user)
                );
                localStorage.setItem("shortCountry", country);
                console.log("here");
                history.push({
                  pathname: "/auth/userdetails",
                  params: {
                    ...history.location.params,
                    ...values,
                    password: "",
                    confirm: "",
                  },
                });
              }
            })
            .catch((info) => {
              setAlertShow(true);
              setAlertMessage(info.description || "Server Error");
              setAlertType(false);
            })
            .finally(() => {
              showLoading(false);
              setDisableButton(false);
            });
        })
        .catch((info) => {
          setDisableButton(false);
          console.log("Validate Failed:", info);
        });
    } else {
      checkBox1 === "" && setCheckBox1(false);
      checkBoxTerm === "" && setcheckBoxTerm(false);
    }
  };

  const onCheckBox1 = (e) => {
    setCheckBox1(e.target.checked);
  };

  const onCheckBoxTerm = (e) => {
    setcheckBoxTerm(e.target.checked);
  };

  function getIp(callback) {
    fetch("https://ipinfo.io/json?token=d580b9741ba3c2")
      .then((resp) => {
        return resp.json();
      })
      .catch((err) => {
        return {
          country: "us",
        };
      })
      .then((resp) => callback(resp.country));
  }

  getIp((country) => {
    setCountry(country);
  });

  const getFlag = (short) => {
    const data = require(`world_countries_lists/data/flags/24x24/${short.toLowerCase()}.png`);
    if (typeof data === "string") {
      return data;
    }
    // for CRA
    return data.default;
  };

  const showText1 = () => {
    return (
      <Text
        style={{
          ...registerStyle.headingText,
          padding: mobileView ? "0 5%" : "0 15%",
        }}
      >
        You have the opportunity to apply for numerous scholarships, worth of
        millions awaiting you!
      </Text>
    );
  };

  const showText = () => {
    // console.log("stats value ", stats.counts);
    const formattedValue = Utils.formatAsUSD(stats.totalValue);
    return (
      <>
        <Text
          style={{
            ...registerStyle.headingText,
            padding: mobileView ? "0 5%" : "0 15%",
          }}
        >
          You are elligible to apply for up to
        </Text>
        <Text
          style={{
            ...registerStyle.headingText,
            padding: mobileView ? "0 5%" : "0 15%",
            fontWeight: "600",
          }}
        >
          {stats?.counts} scholarships, with a combined total value of{" "}
          {formattedValue}
          <Text
            style={{
              ...registerStyle.headingText2,
              padding: mobileView ? "0 1%" : "0 1%",
            }}
          ></Text>
        </Text>
      </>
    );
  };

  if (country === "")
    return (
      <div
        style={{
          position: "absolute",
          left: mobileView ? window.innerWidth / 2 : "720px",
          top: window.innerHeight / 2,
        }}
      >
        <Spin size={"large"} />
      </div>
    );

  return (
    <div style={{ background: "#ffffff" }}>
      <Row
        style={{
          flexDirection: mobileView && "column",
          height: !mobileView && window.innerHeight,
        }}
      >
        <Col style={mobileView ? { background: "#ffffff" } : { width: "40%" }}>
          <Text
            style={{ ...registerStyle.congratulations, marginBottom: "20px" }}
          >
            Congratulations!!!
          </Text>
          {stats?.counts > 0 ? showText() : showText1()}
          {!mobileView && (
            <img
              src="/applicantImg/signup2_register.png"
              style={{ width: "-webkit-fill-available", margin: "50px" }}
            />
          )}
        </Col>
        <Col
          style={{
            marginTop: "25px",
            padding: mobileView ? "0 5%" : "0 0 0 30px",
            width: mobileView ? "" : "60%",
            background: "#F0F1FF",
          }}
        >
          {
            <motion.div
              initial={{ opacity: 0, marginBottom: 0 }}
              animate={{
                opacity: alertShow ? 1 : 0,
                marginBottom: alertShow ? 20 : 0,
              }}
            >
              <Alert
                type={alertType ? "success" : "error"}
                showIcon
                message={alertMessage}
              ></Alert>
            </motion.div>
          }
          <Text
            style={{ fontSize: "20px", fontWeight: "600", lineHeight: "24px" }}
          >
            Sign up details{" "}
          </Text>
          <Form
            form={form}
            layout="vertical"
            name="register-form-step1"
            initialValues={
              formValues && {
                ...formValues,
              }
            }
            onFinish={onSignUp}
          >
            <Row
              style={
                mobileView
                  ? { flexDirection: "column", marginTop: "25px" }
                  : { marginTop: "25px" }
              }
            >
              <Col style={registerStyle.column1st}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={rules.firstName}
                  hasFeedback
                >
                  <Input
                    style={{ borderRadius: "8px", height: "50px" }}
                    placeholder=" First Name"
                  />
                </Form.Item>
              </Col>
              <Col style={registerStyle.column2nd}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={rules.lastName}
                  hasFeedback
                >
                  <Input
                    style={{ borderRadius: "8px", height: "50px" }}
                    placeholder=" Last Name"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={mobileView && { flexDirection: "column" }}>
              <Col style={registerStyle.column1st}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={rules.email}
                  hasFeedback
                >
                  <Input
                    style={{ borderRadius: "8px", height: "50px" }}
                    placeholder=" Email"
                  />
                </Form.Item>
              </Col>
              <Col style={registerStyle.column2nd}>
                <ConfigProvider
                  locale={en}
                  areaMapper={(area) => {
                    return {
                      ...area,
                      emoji: (
                        <img
                          alt="flag"
                          style={{
                            width: 18,
                            height: 18,
                            verticalAlign: "sub",
                          }}
                          src={getFlag(area.short)}
                        />
                      ),
                    };
                  }}
                >
                  <Form.Item
                    name="contactNo"
                    label="Phone number"
                    initialValue={{
                      short: country,
                    }}
                    rules={rules.contactNo}
                    hasFeedback
                  >
                    <CountryPhoneInput
                      style={{ borderRadius: "8px", height: "50px" }}
                    />
                  </Form.Item>
                </ConfigProvider>
              </Col>
            </Row>
            <Row style={mobileView && { flexDirection: "column" }}>
              <Col style={registerStyle.column1st}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={rules.password}
                  hasFeedback
                >
                  <Input.Password
                    placeholder=" Password"
                    style={{ borderRadius: "8px", height: "50px" }}
                  />
                </Form.Item>
              </Col>
              <Col style={registerStyle.column2nd}>
                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  rules={rules.confirm}
                  hasFeedback
                >
                  <Input.Password
                    placeholder="Confirm Password"
                    style={{ borderRadius: "8px", height: "50px" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row style={mobileView && { flexDirection: "column" }}>
              <Col style={registerStyle.column1st}>
                {checkBoxTerm === false && (
                  <div style={{ color: "#ff6b72" }}>Please Select!</div>
                )}
                <p style={{ color: "black", fontWeight: "400" }}>
                  <Checkbox
                    style={{ marginRight: "10px" }}
                    onChange={onCheckBoxTerm}
                  />
                  By clicking, I provide my electronic signature and consent for
                  calls/texts regarding offers at the phone number.
                </p>
              </Col>
              <Col style={registerStyle.column2nd}>
                <span>
                  {checkBox1 === false && (
                    <div style={{ color: "#ff6b72" }}>Please Select!</div>
                  )}
                  <Checkbox
                    style={{ marginRight: "10px" }}
                    onChange={onCheckBox1}
                  />
                  I agree with the
                  <a
                    onClick={() => {
                      history.push("/terms");
                    }}
                  >
                    {" "}
                    Terms of Use, Privacy, Policy
                  </a>
                  and the
                  <a
                    onClick={() => {
                      history.push("/privacy");
                    }}
                  >
                    {" "}
                    offical rules of scholarships
                  </a>
                </span>
              </Col>
            </Row>

            {mobileView && <Divider style={{ margin: "24px 0 0" }} />}

            <Form.Item style={{ textAlignLast: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={registerStyle.buttonStyle}
                background="#8D96FF"
                //onClick={registerConversion}
              >
                <span style={registerStyle.buttonText}>Continue</span>
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default RegisterStep1;
