import { Button, Modal, Spin, message } from "antd";
import React, { useState, useEffect } from "react";
import fetch_ from "../../../interceptors/FetchInterceptor";
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import "./style.css";
import { useHistory } from "react-router-dom";
import useAnalyticsEventTracker from "useAnalyticsEventTracker";

const MySubscriptionPlan = ({ props }) => {
  const history = useHistory();

  const [dataLoader, setDataLoader] = useState(true);
  const [isCancelVisible, setIsCancelVisible] = useState(true);

  const [loading, setLoading] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);

  const [isActiveSubscription, setIsActiveSubscription] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [currentPlanPrice, setCurrentPlanPrice] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [planDesc, setPlanDesc] = useState(null);
  const [status, setStatus] = useState();
  const [paymentGateway, setPaymentGateway] = useState();

  const gaEventTracker = useAnalyticsEventTracker("User Plan");

  const token = localStorage.getItem("token");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    cancelSubscription();
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const checkSubscription = async () => {
    try {
      const response = await fetch_("/user-payments/current-subscription", {
        method: "GET",
      });
      const data = await response;

      setSubscription(data.data.userWithSubscription[0]);
      setIsActiveSubscription(
        data.data.userWithSubscription[0].is_active_subscription
      );
      setSubscriptionId(data.data.userWithSubscription[0].subscribed_id);
      setCurrentPlanPrice(data.data.userWithSubscription[0].price);
      setPlanDesc(data.data.userWithSubscription[0].description);
      setPaymentGateway(data.data.userWithSubscription[0].payment_gateway);

      if (
        data.data.userWithSubscription[0].status === "canceled" ||
        data.data.userWithSubscription[0].status === "CLOSE"
        // data.data[0].status === "trialing"
      ) {
        setIsCancelVisible(true);
      } else {
        setIsCancelVisible(false);
      }

      localStorage.setItem(
        "isActiveSubscription",
        data.data.userWithSubscription[0].is_active_subscription
      );
    } catch (error) {
      console.error("Error validating subscription:", error);
      // localStorage.setItem("isActiveSubscription", true);
    } finally {
      setDataLoader(false);
    }
  };

  useEffect(() => {
    setDataLoader(true);
    checkSubscription();
  }, []);

  const cancelSubscription = async () => {
    if (isActiveSubscription) {
      setLoading(true);
      try {
        await fetch_(
          `/user-payments/cancel-subscription/${subscriptionId}/${paymentGateway}`,
          {
            method: "POST",
          }
        );
        await checkSubscription();
        // localStorage.setItem("isActiveSubscription", false);
        setIsActiveSubscription(false);
        setLoading(false);
        setIsCancelModal(true);
        localStorage.removeItem("subscriptionId");
      } catch (error) {
        console.error("Error cancelling subscription:", error);
        setLoading(false);
      }
    } else {
      Modal.info({
        title: "You are not subscribed",
        okButtonProps: { style: { display: "none" } },
        cancelButtonProps: { style: { display: "none" } },
        closable: true,
      });
    }
  };

  return (
    <div
      style={{
        padding: "20px",
        color: "black",
        fontFamily: "Poppins",
      }}
    >
      <p
        style={{
          color: "black",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontSize: "20px",
        }}
      >
        {" "}
        <ArrowLeftOutlined
          style={{
            fontSize: "17px",
            marginRight: "15px",
          }}
          onClick={() => {
            history.push("/auth/dashboard");
          }}
        />
        My Subscription plan
      </p>
      {dataLoader ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            style={{
              color: "white",
              fontFamily: "Poppins",
              margin: "5px",
            }}
          >
            Verifying Subscription for you.{" "}
          </p>
          {/* <div> */}
          <p
            style={{
              color: "white",
              fontFamily: "Poppins",
              margin: "5px",
              display: "inline",
            }}
          >
            {" "}
            Please wait...
            <Spin
              size="large"
              style={{ marginLeft: "5px" }}
              className="custom-spinner"
            />
          </p>
          {/* </div> */}
        </div>
      ) : (
        <div style={{ marginLeft: "30px" }} className="setMargin">
          <p
            style={{
              fontSize: "42px",
              color: "black",
              margin: "0px",
            }}
          >
            <span>$</span>{" "}
            <span
              style={{
                fontSize: "70px",
                lineHeight: "normal",
              }}
            >
              {currentPlanPrice
                ? currentPlanPrice.substring(1)
                : currentPlanPrice}
            </span>{" "}
            <span style={{ fontSize: "18px" }}>/month</span>
          </p>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "normal",
            }}
            className="para_style"
          >
            {subscription.title}
            {isCancelVisible && (
              <span style={{ display: "block", marginTop: "10px" }}>
                Your subscription is cancelled and you will not be charged
                further . Avail all benefits till{" "}
                {new Date(subscription?.next_billing_date).toLocaleDateString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    timeZone: "UTC",
                  }
                )}
                . In case you change your mind and if you want to continue
                enjoying all of Lenny’s benefits contact us or add a
                re-subscribe any time before your subscription period ends.
                {/* Subscription is cancelled but avail its benefits till{" "} */}
                .
              </span>
            )}
          </p>

          {planDesc
            ? planDesc.split(",").map((item, index) => (
                <p key={index} className="para_style">
                  <CheckCircleOutlined style={{ color: "green" }} /> {item}
                </p>
              ))
            : null}
          <div
            style={{
              display: "flex",
              gap: "12px",
              width: "40%",
            }}
            className="setWidth"
          >
            <Button
              style={{
                borderRadius: "12px",
                background: "#8D96FF",
                color: "#FFF",
                fontWeight: "500",
                display: isCancelVisible ? "none" : "block",
              }}
              loading={loading}
              // disabled={!isActiveSubscription}
              onClick={() => {
                let label = {
                  planName: "current plan",
                };
                gaEventTracker("Cancel clicked ", JSON.stringify(label));
                showModal();
              }}
              visible={false}
              // onClick={onDeleteStudent}
            >
              Cancel Subscription
            </Button>
            <Button
              style={{
                color: "rgb(0, 0, 0)",
                fontWeight: "500",
                border: "1px solid black",
                borderRadius: "12px",
              }}
              onClick={() => {
                history.push("/auth/dashboard/subscription-plan");
              }}
            >
              View all plans
            </Button>
          </div>
        </div>
      )}

      <Modal
        title="Confirm Subscription Cancellation"
        visible={isModalVisible}
        onCancel={handleOk}
        onOk={handleCancel}
        width={500}
        cancelButtonProps={{ style: { marginRight: "10px" } }}
        okButtonProps={{ style: { width: "100%" } }}
        closeIcon={<CloseOutlined />}
        closable={false}
      >
        <p>Are you sure you want to cancel your subscription?</p>
      </Modal>

      <Modal
        title="Subscription Cancelled Successfully"
        visible={isCancelModal}
        onCancel={() => {
          localStorage.setItem("isActiveSubscription", false);
          setIsCancelModal(false);
        }}
        onOk={() => {
          history.push("/auth/dashboard");
        }}
        closable={false}
        width={500}
        cancelButtonProps={{ style: { marginRight: "10px" } }}
        okButtonProps={{ style: { width: "100%" } }}
        bodyStyle={{ padding: 0 }}
      ></Modal>
    </div>
  );
};

export default MySubscriptionPlan;
