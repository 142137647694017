import React, { useState } from "react";
import { Button, Drawer } from "antd";
import "./style.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LeftMenu from "../navbar/LeftMenu";
import { RightMenu } from "../navbar/RightMenu";

const Navbar = () => {
  const mobileView = window.innerWidth < 961;

  const history = useHistory();
  const checkToken = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  return (
    <div>
      <div
        className="chatNav"
        style={{
          borderBottom: "none",
          background: "transparent",
          display: "flex",
          position: "fixed",
          top: "0",
          width: "100%",
        }}
      >
        <div
          style={{
            // width: `${getLogoWidthGutter(props, isMobile)}`,
            marginRight: "20px",
            cursor: "pointer",
            width: "20%",
          }}
          onClick={() => {
            if (checkToken) {
              history.push("/auth/dashboard");
            } else {
              history.push("/");
            }
          }}
        >
          <img
            src="/new/oldLogo1.png"
            // src="/new/oldLogo.webp"
            alt="Lenny.AI"
            style={{ width: mobileView ? "150px" : "200px" }}
          />
        </div>
        <div
          style={{
            width: "60%",
            display: mobileView ? "none" : "block",
          }}
        >
          <LeftMenu />
        </div>
        <div
          style={{
            width: "20%",
            display: mobileView ? "none" : "block",
          }}
        >
          <RightMenu />
        </div>
      </div>
      {mobileView && (
        <Button
          className="barsMenu"
          style={{
            width: "auto",
            background: "transparent",
            border: "none",
            position: "relative",
            // top: "-55px",
          }}
          type="primary"
          onClick={showDrawer}
        >
          <span className="barsBtn"></span>
        </Button>
      )}
      <Drawer
        style={{
          zIndex: "1002",
        }}
        title="Lenny"
        // placement="right"
        // closable={true}
        onClose={onClose}
        visible={visible}
      >
        <LeftMenu />
        <RightMenu />
      </Drawer>
    </div>
  );
};

export default Navbar;
