import React, { useEffect } from "react";
import Navbar from "./Navbar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NavAlert from "components/layout-components/NavAlert";

const Index = () => {
  const history = useHistory();
  const mView = window.innerWidth < 650;

  const registerConversion = (msg) => {
    if (window.trackdesk) {
      window.trackdesk("lennyai", "conversion", {
        conversionType: "lead",
        amount: {
          value: "0",
        },
        customParams: {
          advS1: msg,
        },
      });
    }
  };

  useEffect(() => {
    registerConversion("Lead : Chatbot-landingpage Loaded");
  }, []);

  return (
    <div
      style={{
        height: "70vh",
        // background: "linear-gradient(180deg, #C7CBFF 0%, #FFFFFF 60.3%)",
        backgroundImage: "url('/applicantImg/back.webp')",
        backgroundSize: "cover",
      }}
    >
      <Navbar />
      <NavAlert top={70} />
      <div style={{}}>
        <div
          className="chatbotImg"
          style={{
            width: mView ? "335px" : "425px",
            margin: " auto",
          }}
        >
          <img
            src="/applicantImg/assLogo.webp"
            alt="chatbot"
            style={{
              width: "100%",
              marginTop: "125px",
            }}
          />
        </div>
        <div
          style={{
            margin: "auto",
            width: mView ? "85%" : "60%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontWeight: "600",
              textAlign: "center",
              fontSize: mView ? "23px" : "30px",
              color: "black",
              lineHeight: "35px",
              margin: "0 0 10px 0",
            }}
          >
            Unlock Your Future: Find and Apply for Scholarships with Ease Using
            Lenny!
          </p>
          <p
            style={{
              fontWeight: "400",
              fontStyle: "italic",
              fontSize: mView ? "26px" : "35px",
              textAlign: "center",
              fontFamily: "Bad Script",
              lineHeight: "35px",
            }}
          >
            Simplify your scholarship search and application process with our
            friendly AI assistant, Lenny.
          </p>

          <button
            style={{
              backgroundColor: "rgb(141, 150, 255)",
              color: "white",
              border: "none",
              borderRadius: "12px",
              width: mView ? "200px" : "400px",
              padding: mView ? "6px 10px" : "12px 20px",
              fontSize: "22px",
              fontWeight: "500",
              fontFamily: "Inter",
              textAlign: "center",
              marginBottom: "30px",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push("/chatbot");
              registerConversion(
                "Chatbot-landingpage Start Chat Button Clicked"
              );
            }}
          >
            Start Chat
          </button>
        </div>
      </div>
    </div>
  );
};

export default Index;
