import React, { useRef, useState } from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { Button } from "antd";
import { LoadingOutlined, CloseCircleFilled } from "@ant-design/icons";
import { Spin } from "antd";
import { useHistory } from "react-router-dom";
import fetch_ from "interceptors/FetchInterceptor";
import Subscription from "./Subscription";
import SubscriptionMobileDesign from "./SubscriptionMobileDesign";

const ButtonWrapper = ({ productId }) => {
  const tabView = window.innerWidth < 961;

  const [{ isPending }] = usePayPalScriptReducer();
  const [isOpen, setIsOpen] = useState(false);

  const [showSubscriptionPop, setShowSubscriptionPop] = useState(false);

  const history = useHistory();

  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }
  const userId = userInfo.id;

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const toggleSubPopUp = () => {
    setShowSubscriptionPop(!showSubscriptionPop);
  };

  return (
    <div>
      {isPending ? (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 24,
                textAlign: "center",
              }}
              spin
            />
          }
        /> // This will display while the script is loading
      ) : (
        <PayPalButtons
          createSubscription={(data, actions) => {
            return actions.subscription.create({
              plan_id: `${productId}`, // Replace with your subscription plan ID
              custom_id: `${new Date().getTime()}__${userId}`,
            });
          }}
          onApprove={(data, actions) => {
            // Handle successful subscription approval here
            // console.log("Subscription approved:", data.subscriptionID);
            // console.log(data);
            if (data.subscriptionID) {
              let subsId = data.subscriptionID;
              // Make API call to validate subscription
              fetch_(
                `user-payments/validate-subscription/${data.subscriptionID}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    // Add any other headers as needed
                  },
                }
              )
                .then(({ data }) => {
                  if (
                    data.subscriptionStatus === "APPROVED" ||
                    data.subscriptionStatus === "ACTIVE"
                  ) {
                    localStorage.setItem("isActiveSubscription", true);
                    localStorage.setItem("subscriptionId", subsId);
                    // console.log(data.userPayment.subscriptionId)
                    history.push("/auth/dashboard/success");
                  } else {
                    localStorage.setItem("isActiveSubscription", false);
                    localStorage.setItem("subscriptionId", subsId);
                    // console.log(data.userPayment.subscriptionId)
                    togglePopup();
                  }
                })
                .catch((error) => {
                  console.error("Error validating subscription:", error);
                  // Handle error if needed
                  localStorage.setItem("isActiveSubscription", false);
                  togglePopup();
                });
            } else {
              localStorage.setItem("isActiveSubscription", false);
              togglePopup();
            }
          }}
        />
      )}

      {isOpen && (
        <div className="popup-overlay">
          <div
            className="popup-content"
            style={{
              minWidth: "30%",
              padding: "35px",
            }}
          >
            <div
              onClick={togglePopup}
              style={{
                // position: "absolute",
                // top: "235px",
                // right: "560px",
                // cursor: "pointer",
                float: "right",
                lineHeight: "normal",
                color: "#8D96FF",
              }}
            >
              {" "}
              <CloseCircleFilled />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  color: "black",
                  // textAlign: 'center',
                  fontFamily: "Poppins",
                  fontSize: "24px",
                  fontWeight: "500",
                }}
              >
                Payment failed!
              </p>
              <p
                style={{
                  color: "black",
                  // textAlign: 'center',
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Something went wrong please try again
              </p>
              <Button
                style={{
                  width: "145px",
                  height: "40px",
                  flexShrink: "0",
                  borderRadius: "12px",
                  background: "#8D96FF",
                  color: "#FFF",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
                onClick={() => {
                  // history.push("/auth/dashboard");
                  toggleSubPopUp();
                }}
              >
                Try again
              </Button>
            </div>
          </div>
        </div>
      )}
      {showSubscriptionPop &&
        (tabView ? (
          <div
            className="popup-overlay"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "24px",
                padding: "0px 20px",
              }}
            >
              Manav get a membership for free 7 days to activate automatic
              application to your 234 scholarship matches. Lets us do the hard
              work for you !
            </p>

            <SubscriptionMobileDesign
              toggleMobilePopup={toggleSubPopUp}
              redirect={true}
            />
          </div>
        ) : (
          <div className="popup-overlay">
            <div className="popup-content">
              <div
                onClick={toggleSubPopUp}
                style={{
                  color: "rgb(113, 122, 245)",
                  fontSize: "24px",
                  cursor: "pointer",
                  float: "right",
                  lineHeight: "normal",
                }}
              >
                {" "}
                <CloseCircleFilled />
              </div>
              <Subscription
                modalStyle={{
                  border: "none",
                  boxShadow: "none",
                  margin: "0px",
                }}
                redirect={true}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

const PayPalSubscriptionButton = ({ productId }) => {
  const clientId =
    "AV5jlNswiI4arHluDy3pSNbpWKKEtzs07Ym3BGpN6UUEkETpLr8sZ-mYObLr98tMVQsdegjZj_IyIR-c";

  return (
    <PayPalScriptProvider
      options={{ "client-id": clientId, vault: true, intent: "subscription" }}
    >
      <ButtonWrapper productId={productId} />
    </PayPalScriptProvider>
  );
};
export default PayPalSubscriptionButton;
