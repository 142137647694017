import { Steps } from "antd";
import React from "react";

const ApplicationStatus = ({
  requirements = [],
  completedSteps,
  applicationstatus,
}) => {
  const { Step } = Steps;
  const mobileView = window.innerWidth < 1200;

  return (
    <>
      <h3> Application Status </h3>
      <Steps
        current={completedSteps}
        size="small"
        direction={mobileView ? "horizontal" : "vertical"}
        labelPlacement={!mobileView ? "horizontal" : "vertical"}
        style={{
          color: "rgb(141, 150, 255)",
        }}
      >
        <Step
          title={applicationstatus === "Received" ? "APPLIED" : "ELIGIBLE"}
        />
        {requirements.map((item) => (
          <Step
            title={item.title}
            style={{
              color: "rgb(141, 150, 255)",
            }}
          />
        ))}
      </Steps>
    </>
  );
};

export default ApplicationStatus;
