import { Row, Col, Button, Spin, Typography } from "antd";
import Utils from "utils/common";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import useAxios from "axios-hooks";
import React, { useState, useEffect } from "react";
import fetch_ from "../../../interceptors/FetchInterceptor";
import { List } from "./List.js";
import dashboardStyle from "./dashboardStyle.js";
import useAnalyticsEventTracker from "../../../useAnalyticsEventTracker";
import Subscription from "components/layout-components/Subscription.js";
import "components/layout-components/css/Popus.css";
import SubscriptionMobileDesign from "components/layout-components/SubscriptionMobileDesign.js";
import Scrollbars from "react-custom-scrollbars-2";
import AssistanceChatBot from "../assistance/AssistanceChatBot";
import { useAssitanceChatBotContext } from "context/ChatbotContext";
import ApplyScholarshipPopup from "components/layout-components/ApplyScholarshipPopup";

const Dashboard = ({ props }) => {
  const {
    setChatTypeFn,
    startChat,
    setStartChat,
    hideIcons,
    setHideIcons,
    enableChat,
    setEnableChat,
    maximizeView,
    setMaximizeView,
  } = useAssitanceChatBotContext();

  const history = useHistory();

  const [] = useState(false);
  // const [chatType, setChatType] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const mobileView = window.innerWidth < 481;
  const mView = window.innerWidth < 1205;
  const tabView = window.innerWidth < 961;
  const [isActiveSubscription, setIsActiveSubscription] = useState("false");

  const togglePopup = () => {
    if (isActiveSubscription) {
      history.push("/auth/dashboard/plan");
    } else {
      // setIsOpen(!isOpen);
      if (tabView) {
        setIsOpen(!isOpen);
      } else {
        history.push("/auth/dashboard/subscription-plan");
      }
    }
  };

  const { Text } = Typography;
  const gaEventTracker = useAnalyticsEventTracker("Dashboard");
  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  // const storedValue = localStorage.getItem("isActiveSubscription");

  // Now, storedValue is a string representation of the boolean
  // You can directly use it as a boolean without parsing
  // const isActiveSubscription = storedValue === "true";
  const [isScholarshipPop, setIsScholarshipPop] = useState(false);
  const isScholarshipApplied = userInfo?.isScholarshipApplied;
  const toggleScholarshipPopup = () => {
    setIsScholarshipPop(!isScholarshipPop);
  };

  const checkSubscription = async () => {
    try {
      const response = await fetch_("/user-payments/current-subscription", {
        method: "GET",
      });
      const data = await response;
      // console.log(data.data.userWithSubscription[0].is_active_subscription);
      setIsActiveSubscription(
        data.data.userWithSubscription[0].is_active_subscription
      );
      // setSubscriptionId(data.data[0].subscribed_id);
      // setCurrentPlanPrice(data.data[0].price);
      // setPlanDesc(data.data[0].description);

      localStorage.setItem(
        "isActiveSubscription",
        data.data.userWithSubscription[0].is_active_subscription
      );
    } catch (error) {
      console.error("Error validating subscription:", error);
      // Handle error if needed
      localStorage.setItem("isActiveSubscription", false);
    } finally {
      // setDataLoader(false);
    }
  };

  const setFormData = async () => {
    try {
      const data = await fetch_(`/register-applicants/form-fields`);

      const countryObj = data.data?.filter((e) => e.key === "country");
      localStorage.setItem("countryObj", JSON.stringify(countryObj));

      const zipCodeId = data.data?.filter((e) => e.key === "zipCode");
      localStorage.setItem("zipCodeId", JSON.stringify(zipCodeId));

      const profileData = await fetch_("/register-applicants/profile");
      localStorage.setItem("profileData", JSON.stringify(profileData.data));
    } catch (error) {
      console.log("Error while fetching fprom fields", error);
    }
  };
  useEffect(() => {
    checkSubscription();
    if (!isScholarshipApplied) {
      toggleScholarshipPopup();
    }
  }, []);

  useEffect(() => {
    let url = new URL(window.location.href);

    let params = new URLSearchParams(url.search);

    let linkId = params.get("linkId");
    let sourceId = params.get("sourceId");

    if (linkId !== null && sourceId !== null) {
      localStorage.setItem("linkId", linkId);
      localStorage.setItem("sourceId", sourceId);
      // console.log(`Link ID: ${linkId}`);
      // console.log(`Source ID: ${sourceId}`);
    }
  }, []);

  const [{ data: recommendedData, loading }] = useAxios(
    {
      url: `scholarships/mine-eligible-scholarships?withoutRequirement\=${false}\&limit\=${2}\&order\=DESC\&orderBy\=createdAt`,
    },
    { useCache: false }
  );

  const [{ data: easyApplyData }] = useAxios(
    {
      url: `scholarships/mine-eligible-scholarships?withoutRequirement\=${true}\&limit\=${2}\&order\=DESC\&orderBy\=createdAt`,
    },
    { useCache: false }
  );

  const [{ data: applyWithCvData }] = useAxios(
    {
      url: `scholarships/mine-eligible-scholarships?offset\=${0}\&limit\=${2}\&order\=DESC\&orderBy\=createdAt\&withoutRequirement\=${false}\&requirementFields\=9991e67e-418d-448a-ae41-143e4a6c33a7`,
    },
    { useCache: false }
  );

  const [{ data: stats }] = useAxios(
    { url: `scholarships/stats` },
    { useCache: false }
  );

  useEffect(() => {
    if (props?.setSideBars) {
      props?.setSideBars(false);
    }
  });

  const AssitanceChatMiniView = () => {
    const mobileView = window.innerWidth < 915;

    const [essayBtnStat, setEssayBtnStat] = useState(false);

    const btnStyle = {
      fontWeight: "400",
      color: "black",
      border: "2px solid rgb(141, 150, 255)",

      padding: "10px",
      borderRadius: "12px",
      cursor: "pointer",
      fontSize: "14px",
      padding: "6px 15px",
      textAlign: "center",
    };

    const writeEssay = () => {
      setChatTypeFn("recommedation");
      // setChatType("writeEssay");
      setStartChat(true);
      setHideIcons(true);
      setEnableChat(true);
    };

    const applyScholarship = () => {
      // setChatType("applyScholarship");
      setChatTypeFn("applyScholarship");

      setStartChat(true);
      setHideIcons(true);
      setEnableChat(true);
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgb(240, 241, 255)",
          borderRadius: "10px",
          marginLeft: "10px",
          padding: "16px",
          height: "100%",
        }}
      >
        <h3
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "black",
            margin: "0",
          }}
        >
          Welcome to Lenny.AI 👋
        </h3>
        <div
          style={{
            width: "240px",
            margin: "auto",
            display: essayBtnStat ? "none" : "flex",
          }}
        >
          <img
            src="/new/chatrobo1.png"
            style={{
              width: "100%",
            }}
            alt=""
          />
        </div>
        <div
          style={{
            display: essayBtnStat ? "none" : "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            // flexWrap: "wrap",
          }}
        >
          <p
            style={{
              ...btnStyle,
              margin: "4px 5px 0 0",
            }}
            onClick={writeEssay}
          >
            Best Recommended Scholarships
          </p>
          <p
            style={{
              ...btnStyle,
              // marginRight: "10px",
              margin: "0",
              marginTop: "4px",
            }}
            onClick={applyScholarship}
          >
            Easy Apply Scholarship
          </p>
        </div>
      </div>
    );
  };

  const imgView = (props) => {
    return (
      <Col
        style={{
          background: props?.circleColor,
          width: mobileView ? "100%" : "auto",
        }}
      >
        <img
          src={`/applicantImg/${props?.icon}`}
          style={{ width: "51px", float: !mobileView && "right" }}
        />
      </Col>
    );
  };

  const box = (props) => {
    return (
      <Row
        style={{
          padding: "9px",
          background: props?.background,
          marginRight: props.marginRight,
          borderRadius: "12px",
          width: mobileView ? "100%" : "95%",
          overflow: "hidden",
          height: window.innerWidth < 500 && "158px",
          display: "flex",
          alignItems: "center",
          justifyContent: mobileView ? "flex-start" : "space-evenly",
          height: "100%",
        }}
      >
        {/* {mobileView && imgView(props)} */}
        <Col style={{ width: !mobileView && "auto" }}>
          <Text
            style={{
              fontSize: !tabView ? "14px" : "12px",
              fontWeight: "600",
              lineHeight: "20px",
              marginTop: "6px",
            }}
          >
            {props?.text}
          </Text>
          <div
            style={{
              color: "rgba(0,0,0,0.85)",
              fontSize: "22px",
              fontWeight: "600",
            }}
          >
            {props?.subText}
          </div>
        </Col>
        {!mobileView && imgView(props)}
      </Row>
    );
  };

  const featureText = ({ text }) => {
    return (
      <div style={{ marginTop: "8px" }}>
        <CheckCircleFilled style={{ color: "#30CB86" }} />
        <span style={{ marginLeft: "4px" }}>{text}</span>
      </div>
    );
  };

  const videoView = () => {
    return (
      <Row style={{ width: "max-content" }}>
        <Col style={{}}>
          <video height="80" controls>
            <source
              src={
                "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4"
              }
            />
          </video>
        </Col>
        <Col style={{ paddingLeft: "10px" }}>
          <h4>The End Of libra... </h4>
          <span>25-Jan-2020</span>
        </Col>
      </Row>
    );
  };

  const submitData = () => {
    let selectedId = [];
    easyApplyData.forEach((e) => {
      selectedId = [...selectedId, e.id];
    });
    fetch_({
      url: `scholarships/submit-scholarships`,
      method: "POST",
      data: { scholarshipId: selectedId },
    })
      .then(({ data }) => {})
      .catch((error) => {});
  };

  if (loading)
    return (
      <div
        style={{
          position: "absolute",
          left: mobileView ? window.innerWidth / 2 : "520px",
          top: "241px",
        }}
      >
        <Spin size={"large"} />
      </div>
    );

  const CompView = () => {
    return (
      <>
        <Row style={{ marginTop: "0px", marginBottom: "10px" }}>
          <Col style={dashboardStyle.featureText}>
            {/* <img src="/applicantImg/cap.png" style={{ width: "85%" }} /> */}
            <h2 style={{ width: "74%", fontWeight: "600" }}>
              Try Easy Apply Scholarships for FREE
            </h2>
            {featureText({ text: "Save time" })}
            {featureText({ text: "Apply instantly" })}
            {featureText({ text: "Easier for us to find your profile" })}
            {featureText({ text: "No essay writing required" })}
            <Button
              type="primary"
              style={{
                marginTop: "25px",
                display: "flex",
                width: "auto",
                backgroundColor: "#8D96FF",
                borderRadius: "0.625rem",
                borderColor: "#8D96FF",
                textAlign: "center",
              }}
              onClick={() => {
                if (isActiveSubscription === true) {
                  history.push("/auth/dashboard/easy-apply");
                } else {
                  togglePopup();
                }
              }}
            >
              {/* <img
              src="/applicantImg/whiteTick.png"
              style={{ height: "100%", marginRight: "4px" }}
            /> */}
              <span style={{}}>Explore Easy Apply</span>
            </Button>
          </Col>
          <Col style={{ width: "1%" }} />
          <Col style={dashboardStyle.featureText2}>
            {/* <img
                src="/applicantImg/sleep.png"
                style={{ width: "50%", paddingTop: "10px" }}
              /> */}
            <h2 style={{ width: "74%", fontWeight: "600" }}>
              Lenny AI <br /> Worry no more
            </h2>
            {featureText({ text: "24*7 Companion and Supporter" })}
            {featureText({ text: "Professional Career Mentor and Guide" })}
            {featureText({ text: "Comprehensive College Finder" })}
            {featureText({ text: "Many more" })}
            <Button
              type="primary"
              style={{
                marginTop: "25px",
                display: "flex",
                width: "auto",
                backgroundColor: "#8D96FF",
                borderRadius: "0.625rem",
                borderColor: "#8D96FF",
              }}
              onClick={() => {
                if (isActiveSubscription === true) {
                  history.push("/auth/dashboard/assistance");
                } else {
                  togglePopup();
                }
              }}
            >
              <span style={{}}>Explore Lenny.AI</span>
            </Button>
          </Col>
          {false && (
            <Col style={dashboardStyle.videoBorder}>
              <h3 style={{ fontSize: "18px", Weight: "1000" }}>
                {" "}
                Title needed here{" "}
              </h3>

              <video
                height="auto"
                width="auto"
                controls
                style={{ borderRadius: "5px" }}
              >
                <source
                  src={
                    "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4"
                  }
                />
              </video>
              <Button type="primary" style={dashboardStyle.button}>
                <span style={{ color: "#1778F2" }}>Explore</span>
              </Button>
            </Col>
          )}

          {false && (
            <Col
              style={{
                ...dashboardStyle.videoBorder,
                // overflowY: "scroll",
                overflowX: "hidden",
                height: "395px",
              }}
            >
              <h3 style={{ fontSize: "18px", Weight: "1000" }}>
                {" "}
                Title needed here{" "}
              </h3>
              {[1, 2, 3]?.map((el) => {
                return videoView();
              })}
              <Button type="primary" style={dashboardStyle.button}>
                <span style={{ color: "#1778F2" }}>Explore</span>
              </Button>
            </Col>
          )}
          {/* <Col style={dashboardStyle.featureText3}>
          <img
            src="/applicantImg/cheque.png"
            style={{ width: "30%", paddingTop: "10px" }}
          />
          <h2 style={{ width: "74%" }}>
            Apply with Lenny's Essay AI Magic
          </h2>
          {featureText({ text: "Write the essay for you" })}
          {featureText({ text: "Helps you in faster apply" })}
          {featureText({ text: "Increase chances of winning" })}
          {featureText({ text: "Many more" })}
        </Col> */}
        </Row>

        <Row>
          {recommendedData?.length > 0 && (
            <Col
              style={{
                ...dashboardStyle.easyApplyScholarship,
                borderWidth: "1px",
                borderStyle: "double",
                background: "#F0F1FF",
              }}
            >
              <Row
                style={{
                  alignItems: "baseline",
                  justifyContent: "space-between",
                }}
              >
                <Col style={{ width: mobileView ? "50%" : "84%" }}>
                  <h3 style={{ fontSize: "18px" }}>Recommended Scholarships</h3>
                </Col>
                <Col>
                  <a
                    onClick={() => {
                      let label = {
                        section: "recommendation",
                      };
                      gaEventTracker(
                        " View all clicked",
                        JSON.stringify(label)
                      );
                      // history.push("/auth/dashboard/recommendation");

                      if (isActiveSubscription === true) {
                        history.push("/auth/dashboard/recommendation");
                      } else {
                        togglePopup();
                      }
                    }}
                    style={{ color: "#8D96FF" }}
                  >
                    View All
                  </a>
                </Col>
              </Row>
              {recommendedData?.map((el, index) => {
                const isLastElement = index === recommendedData.length - 1;
                return (
                  <div style={{ marginTop: "2px" }}>
                    <div className="d-flex flex-column h-100">
                      <List
                        data={el}
                        type="recommendation"
                        history={history}
                        props={props}
                        isLastElement={isLastElement}
                      />
                    </div>
                  </div>
                );
              })}
            </Col>
          )}
          <Col style={{ width: "1%" }} />
          {easyApplyData?.length > 0 && (
            <Col
              style={{
                ...dashboardStyle.easyApplyScholarship,
                borderWidth: "1px",
                borderStyle: "double",
                background: "#F0F1FF",
              }}
            >
              <Row
                style={{
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <Col style={{ width: "50%" }}>
                  <h3 style={{ fontSize: "18px", Weight: "1000" }}>
                    Easy Apply Scholarship
                  </h3>
                </Col>

                <Col>
                  <Button
                    type="primary"
                    style={{
                      height: "32px",
                      paddingTop: "5px",
                      marginRight: "auto",
                      borderRadius: "8px",
                      borderColor: "#8D96FF",
                      backgroundColor: "#F0F1FF",
                      color: "black",
                    }}
                    onClick={() => {
                      let label = {
                        section: "Easy Apply",
                      };
                      gaEventTracker(
                        " Easy apply all clicked",
                        JSON.stringify(label)
                      );
                      // history.push("/auth/dashboard/easy-apply");

                      if (isActiveSubscription === true) {
                        history.push("/auth/dashboard/easy-apply");
                      } else {
                        togglePopup();
                      }
                    }}
                  >
                    <span>Easy Apply All</span>
                  </Button>
                </Col>
              </Row>

              {easyApplyData?.map((el, index) => {
                const isLastElement = index === easyApplyData.length - 1;
                return (
                  <div style={{ marginTop: "2px" }}>
                    <div className="d-flex flex-column h-100">
                      <List
                        data={el}
                        type="easyApply"
                        history={history}
                        props={props}
                        isLastElement={isLastElement}
                      />
                    </div>
                  </div>
                );
              })}
            </Col>
          )}
        </Row>

        {applyWithCvData?.length > 0 && (
          <Row style={{ marginTop: "10px" }}>
            <Col
              style={{
                ...dashboardStyle.easyApplyScholarship,
                borderWidth: "1px",
                borderStyle: "double",
                background: "#F0F1FF",
              }}
            >
              <Row
                style={{
                  alignItems: "baseline",
                  justifyContent: "space-between",
                }}
              >
                <Col style={{ width: mobileView ? "50%" : "85%" }}>
                  <h3 style={{ fontSize: "18px" }}>Apply with CV only</h3>
                </Col>
                <Col>
                  <a
                    onClick={() => {
                      let label = {
                        section: "Easy Apply With Cv",
                      };
                      gaEventTracker(
                        " View all clicked",
                        JSON.stringify(label)
                      );
                      if (isActiveSubscription === true) {
                        history.push("/auth/dashboard/easyApplyWithCv");
                      } else {
                        togglePopup();
                      }
                    }}
                    style={{ color: "#1778F2" }}
                  >
                    View All
                  </a>
                </Col>
              </Row>
              {applyWithCvData?.map((el, index) => {
                const isLastElement = index === applyWithCvData?.length - 1;
                return (
                  <div style={{ marginTop: "2px" }}>
                    <div className="d-flex flex-column h-100">
                      <List
                        data={el}
                        type="applyWithCv"
                        history={history}
                        props={props}
                        isLastElement={isLastElement}
                      />
                    </div>
                  </div>
                );
              })}
            </Col>
            <Col style={{ width: "1%" }} />
          </Row>
        )}
      </>
    );
  };

  return (
    <div
      style={{
        background: "#ffffff",
        padding: mView ? "20px" : "0 0 0 10px",
        width: "-webkit-fill-available",
      }}
    >
      <h2
        style={{
          fontSize: "22px",
          fontWeight: "600",
        }}
      >
        Welcome back, {userInfo.firstName}!
      </h2>
      {isOpen &&
        (tabView ? (
          <div
            className="popup-overlay"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "24px",
                padding: "0px 20px",
              }}
            >
              Manav get a membership for free 7 days to activate automatic
              application to your 234 scholarship matches. Lets us do the hard
              work for you !
            </p>

            <SubscriptionMobileDesign
              toggleMobilePopup={togglePopup}
              redirect={true}
            />
          </div>
        ) : (
          <div className="popup-overlay">
            <div className="popup-content">
              <div
                onClick={togglePopup}
                style={{
                  color: "rgb(113, 122, 245)",
                  fontSize: "24px",
                  cursor: "pointer",
                  float: "right",
                  lineHeight: "normal",
                }}
              >
                {" "}
                <CloseCircleFilled />
              </div>
              <Subscription
                modalStyle={{
                  border: "none",
                  boxShadow: "none",
                  margin: "0px",
                }}
                redirect={true}
              />
            </div>
          </div>
        ))}

      <Row>
        <Col
          style={
            mobileView
              ? {
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }
              : { width: "25%" }
          }
        >
          <a
            onClick={() => {
              history.push("/auth/dashboard/myScholarship");
            }}
            style={{
              width: "100%",
            }}
          >
            {box({
              text: "Applied Scholarship",
              subText: stats?.appliedScholarship || 0,
              icon: "book.png",
              background: "#d9f6df",
              // marginRight: "10px",
            })}
          </a>
        </Col>
        <Col
          style={
            mobileView
              ? {
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }
              : { width: "25%" }
          }
        >
          <a
            onClick={() => {
              history.push("/auth/dashboard/recommendation");
            }}
            // style={{
            //   width: "100%"
            // }}
          >
            {box({
              text: "Your Recommendation",
              subText: stats?.yourRecommendation || 0,
              icon: "medal.png",
              background: "#dddef6",
              marginRight: !mobileView && "10px",
            })}
          </a>
        </Col>
        <Col
          style={
            mobileView
              ? { width: "50%", display: "flex", justifyContent: "center" }
              : { width: "25%" }
          }
        >
          {box({
            text: "Scholarship Amount",
            subText: stats?.scholarshipAmount
              ? Utils.formatAsUSD(stats.scholarshipAmount)
              : Utils.formatAsUSD(0),
            icon: "hand.png",
            background: "#d2eaf9",
            marginRight: "10px",
          })}
        </Col>
        <Col
          style={
            mobileView
              ? { width: "50%", display: "flex", justifyContent: "center" }
              : { width: "25%" }
          }
        >
          {box({
            text: `Expiring Soon`,
            subText: stats?.expiringSoon || 0,
            icon: "time.png",
            background: "#fff5e2",
          })}
        </Col>
      </Row>

      <h3
        style={{
          marginTop: "24px",
          fontWeight: "600",
        }}
      >
        What's New{" "}
        <span>
          <img
            src="/applicantImg/fire.png"
            style={{
              width: "25px",
            }}
          />
        </span>
      </h3>
      <Row
        style={
          {
            // overflow: "hidden",
          }
        }
      >
        <Col
          style={{
            width: mView ? "100%" : "70%",
            marginBottom: "10px",
          }}
        >
          {!mView ? (
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={dashboardStyle.scrollbar}
            >
              <CompView />
            </Scrollbars>
          ) : (
            <>
              <CompView />
            </>
          )}
        </Col>
        <Col
          style={{
            width: maximizeView ? "-webkit-fill-available" : "30%",
            // width: mobileView && window.innerWidth,

            // width: window.innerWidth < 1181 ? "-webkit-fill-available" : "30%",
            height: maximizeView ? "100%" : "auto",
            position: maximizeView ? "absolute" : "sticky",
            // position: "relative",
            top: maximizeView ? "0" : "0",
            // top: startChat ? "-55px" : "0",
            display: mView ? "none" : "block",
            paddingLeft: maximizeView ? "0px" : "5px",
          }}
        >
          {startChat ? (
            <>
              {/* {setChatTypeFn(chatType)} */}
              <AssistanceChatBot dashbordHide={true} />
              {/* <AssistanceChatBot dashbordHide={true} chatType={chatType} /> */}
            </>
          ) : (
            <AssitanceChatMiniView />
          )}
        </Col>
      </Row>
      {isScholarshipPop && (
        <>
          <div className="popup-overlay">
            <div
              className="popup-content"
              style={{
                minWidth: "60%",
                minHeight: "80%",
                padding: "10px",
              }}
            >
              <div
                onClick={toggleScholarshipPopup}
                style={{
                  color: "rgb(113, 122, 245)",
                  fontSize: "24px",
                  cursor: "pointer",
                  float: "right",
                  lineHeight: "normal",
                  marginRight: "10px",
                  marginTop: "10px",
                  display: "none",
                }}
              >
                {" "}
                <CloseCircleFilled />
              </div>
              <ApplyScholarshipPopup
                toggleScholarshipPopup={toggleScholarshipPopup}
              />
            </div>
          </div>
        </>
      )}
      <div
        style={{
          position: "sticky",
          bottom: "55px",
          cursor: "pointer",
          right: "20px",
          float: "right",
          display: mView ? "block" : "none",
        }}
        onClick={() => {
          history.push("/auth/dashboard/assistanceChat");
        }}
      >
        <img
          src="/applicantImg/robo2.png"
          style={{
            width: "60px",
          }}
          alt=""
        />
      </div>
    </div>
  );
};

export default Dashboard;
