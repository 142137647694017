import React, { useEffect, useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useAssitanceChatBotContext } from "context/ChatbotContext";

const NavAlert = ({ top }) => {
  // const [alertDisplay, setAlertDisplay] = useState("block");
  const { hideNavAlert, setHideNavAlert } = useAssitanceChatBotContext();
  // useEffect(() => {
  //   setTimeout(() => {
  //     setAlertDisplay("none");
  //   }, 10000);
  // }, []);
  return (
    <div
      style={{
        backgroundColor: "rgb(255, 142, 97)",
        margin: "0px",
        padding: "7px 0px",
        position: "fixed",
        width: "100%",
        zIndex: "1000",
        display: hideNavAlert ? "none" : "block",
        top: top,
      }}
    >
      <p
        style={{
          textAlign: "center",
          color: "black",
          margin: "0",
          fontSize: "16px",
          fontWeight: "500",
          color: "white",
        }}
      >
        By completing the Free Registration You will automatically be entered
        into our Scholarship Sweepstakes<sup>*</sup>
        <span
          style={{
            float: "right",
            marginRight: "13px",
            cursor: "pointer",
          }}
          onClick={() => {
            setHideNavAlert(() => {
              return true;
            });
          }}
        >
          <CloseCircleOutlined />
        </span>
      </p>
    </div>
  );
};

export default NavAlert;
